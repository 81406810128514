import React, { useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { countryCodeList } from "../helpers/country_code";
import { postOpenInquiry } from "helpers/jiraya_helper";
import { toast } from "react-toastify";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  FormGroup,
  Label,
  ModalFooter,
} from "reactstrap";
import inquiry from "assets/images/illustrations/inquiry.svg";
import PhoneInput from "react-phone-number-input";
import useSWR from "swr";
import { GET_OPEN_USER_LOCATION } from "helpers/jiraya_url";
import { getOpenUserLocation } from "helpers/jiraya_helper";
import classNames from "classnames";
import swal from "@sweetalert/with-react";
import { useTranslation } from "react-i18next";

const InquiryModal = ({
  className,
  isOpen,
  setShowInquiryModal,
  branding,
  eventId,
}) => {
  const { t } = useTranslation();
  const options = [
    { value: "wedding", label: "Wedding" },
    { value: "pre_wedding", label: "Pre Wedding" },
    { value: "engagement", label: "Engagement" },
    { value: "birthday", label: "Birthday" },
    // { value: "munj", label: "Munj" },
    { value: "baby_shower", label: "Baby Shower" },
    { value: "corporate_event", label: "Corporate Event" },
    { value: "other", label: "Other" },
  ];
  const formatOptionLabel = ({ value, label }) => (
    <div className="d-flex">
      {/* <i className="mdi mdi-bookmark-multiple me-2"></i> */}
      <div>{label}</div>
    </div>
  );

  const [invalidNumber, setInvalidNumber] = useState(false);

  const [contactFormData, setContactFormData] = useState({
    event_type: options[0].value,
    event_date: new Date().toISOString().split("T")[0],
    event_venue: "",
    event_details: "",
    name: "",
    email_id: "",
    mobile_number: "",
  });

  const handleOnContactChange = (e) => {
    const form = { ...contactFormData, [e.target.name]: e.target.value };
    setContactFormData(form);
  };
  const handleOnChangeMobileNumner = (mobileNumber) => {
    const form = { ...contactFormData, mobile_number: mobileNumber };
    setContactFormData(form);
  };

  const handleOnSelectionChange = (e) => {
    const form = { ...contactFormData, event_type: e.target.value };
    setContactFormData(form);
  };

  const handleSubmit = async () => {
    try {
      const response = await postOpenInquiry({
        event_id: eventId,
        email_id: contactFormData.email_id,
        mobile_number: contactFormData.mobile_number,
        name: contactFormData.name,
        event_type: contactFormData.event_type,
        date: contactFormData.event_date,
        event_venue: contactFormData.event_venue,
        event_details: contactFormData.event_details,
      });
      toast(response.message);
      if (response.ok) {
        swal(
          <Card>
            <div className="card-header">
              <h4>
                {" "}
                <i className="mdi mdi-check-circle text-success" /> Success
              </h4>
            </div>
            <CardBody>
              Thankyou for submitting the Inquiry. We will contact you soon{" "}
              {":)"},
            </CardBody>
          </Card>
        );
      }
    } catch (err) {
      console.log(err);
      toast("Failed to comment !! try after some time");
    }
  };

  const { data: countryData } = useSWR(
    GET_OPEN_USER_LOCATION,
    getOpenUserLocation
  );

  return (
    <div>
      <Modal
        isOpen={isOpen}
        size="xl"
        centered
        zIndex={1000}
        backdrop
        modalClassName={classNames("inquiry-modal gallery-modal")}
        className={className}
        scrollable={true}
      >
        <i
          onClick={() => setShowInquiryModal(false)}
          className="mdi mdi-close me-2 cross-close"
        ></i>
        <AvForm
          className="needs-validation d-flex flex-column gap-2 px-4"
          onValidSubmit={handleSubmit}
          model={contactFormData}
        >
          <ModalHeader className="px-3 px-md-5">
            {t("Please fill following Details")}
          </ModalHeader>
          <ModalBody
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
          >
            <div>
              <Row className="w-100 gx-0 ">
                <Col md="6" className="p-0">
                  {/* <Container fluid> */}
                  <Row className="mb-2">
                    <Label htmlFor="contact_name">
                      <b>{t("Name")}</b>
                    </Label>
                    <AvField
                      name="name"
                      placeholder="John Wick"
                      type="text"
                      errorMessage={t("Enter Name")}
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="name"
                      value={contactFormData.name}
                      onChange={handleOnContactChange}
                    />
                  </Row>
                  <Row className="mb-2">
                    <Label htmlFor="email_id">
                      <b>{t("Email Id")}</b>
                    </Label>
                    <AvField
                      name="email_id"
                      placeholder="johnwick@awesome.com"
                      type="email"
                      errorMessage={t("Enter Email ID")}
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="email_id"
                      value={contactFormData.email_id}
                      onChange={handleOnContactChange}
                    />
                  </Row>

                  <Row className="mb-2">
                    <Label htmlFor="contact_number">
                      <b>{t("Mobile Number")}</b>
                    </Label>
                    <PhoneInput
                      placeholder={t("Enter Mobile Number")}
                      value={contactFormData.contact_number}
                      onChange={handleOnChangeMobileNumner}
                      defaultCountry={countryData?.data?.country}
                      numberInputProps={{
                        className: classNames(
                          "form-control"
                          //  {
                          //   "is-invalid": errorMessages.mobileNumber,
                          // }
                        ),
                      }}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Col md={6}>
                      <FormGroup className="form-group mt-2">
                        <Label htmlFor="event_type">
                          <b>{t("What's the event?")}</b>
                        </Label>

                        <select
                          onChange={handleOnSelectionChange}
                          className="form-select w-100"
                          aria-label=".form-select-sm example"
                        >
                          {options.map((item) => (
                            <option key={item.value} value={item.value}>
                              {t(item.label)}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="form-group mt-2">
                        <Label htmlFor="event_date">
                          <b>{t("When is the event?")}</b>
                        </Label>
                        <AvField
                          name="event_date"
                          placeholder="Event Date"
                          type="date"
                          className="form-control"
                          id="date"
                          value={contactFormData.event_date}
                          validate={{ required: { value: true } }}
                          onChange={handleOnContactChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <FormGroup className="form-group">
                      <Label htmlFor="event_date" className="text-primary`">
                        <b>{t("Tell me more about this event")}</b>
                      </Label>
                      <AvField
                        name="event_details"
                        placeholder={t("Event Details [Optional]")}
                        type="textarea"
                        errorMessage="Event Details"
                        className="form-control"
                        validate={{ required: { value: false } }}
                        id="details"
                        value={contactFormData.event_details}
                        onChange={handleOnContactChange}
                        style={{ height: "100px" }}
                      />
                    </FormGroup>
                  </Row>
                </Col>
                <Col md="6" className="p-0">
                  <Row className="justify-content-center inquiry-illustration p-0 m-0">
                    <img
                      src={inquiry}
                      style={{ height: "320px", width: "auto" }}
                    />
                  </Row>
                  <Row className="justify-content-center p-0 mt-4">
                    {branding && (
                      <>
                        <div className="text-center contact-us">
                          {t("Or Contact us")}
                        </div>
                        <div className="d-flex flex-row justify-content-center align-items-center gap-3 gap-sm-5 my-2">
                          {branding.img_url && (
                            <img
                              className="contact-us-logo"
                              src={branding.img_url}
                            ></img>
                          )}
                          <div className="d-flex flex-column gap-1 gap-sm-3">
                            {branding.contact_number && (
                              <div className="d-flex flex-row align-items-center gap-2">
                                <i className="mdi mdi-cellphone text-center contact-icon"></i>
                                <div>{branding.contact_number}</div>
                              </div>
                            )}
                            {branding.email_id && (
                              <div className="d-flex flex-row align-items-center gap-2">
                                <i className="mdi mdi-email text-center contact-icon"></i>
                                <div>{branding.email_id}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex flex-row justify-content-end w-100">
              <button
                className="btn btn-gallery-primary my-2 mr-5 px-5"
                type="submit"
              >
                {t("Submit Inquiry")}
              </button>
            </div>
          </ModalFooter>
        </AvForm>
      </Modal>
    </div>
  );
};

export default InquiryModal;

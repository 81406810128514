const getEventCustomisations = (eventId, userId) => {
  var consentPolicy;
  var promotionalContactConsent;
  var basicConsent = true;
  var inquiry = true;
  var registrationForm = { email: true, mobile: true };
  var showCameraHelpVideo = true;

  switch (Number(eventId)) {
    case 44836:
      consentPolicy = {
        privacy: "https://fotoowl.ai/privacypolicy",
        consent: "https://fotoowl.ai/consent",
      };
      promotionalContactConsent = false;
      inquiry = false;
      break;
    case 38769:
      consentPolicy = {
        privacy: "https://fotoowl.ai/privacypolicy",
        consent: "https://fotoowl.ai/consent",
      };
      promotionalContactConsent = true;
      inquiry = false;
      break;
    case 37931:
    case 37930:
    case 37928:
    case 37000:
      consentPolicy = {
        privacy: "https://fotoowl.ai/privacypolicy",
        consent: "https://fotoowl.ai/consent",
      };
      promotionalContactConsent = false;
      inquiry = false;
      break;

    case 9984:
      consentPolicy = {
        privacy: "https://fotoowl.ai/privacypolicy",
        consent: "https://fotoowl.ai/consent",
      };
      promotionalContactConsent = false;
      basicConsent = false;
      inquiry = false;
      break;

    case 7363:
      consentPolicy = {
        privacy: "https://evisit.co.in/privacy-policy/",
        consent: "https://evisit.co.in/consent/",
      };
      promotionalContactConsent = false;
      break;
    case 7517:
      consentPolicy = {
        privacy: "https://fotoowl.ai/privacypolicy",
        consent: "https://fotoowl.ai/consent",
      };
      promotionalContactConsent = false;
      break;

    default:
      switch (userId) {
        case "dbxKgqONTQNFTz6hr4K4nnqRlXG2":
          consentPolicy = {
            privacy: "https://www.turbo360.au/privacy-policy/",
            consent: "https://www.turbo360.au/privacy-policy/",
          };
          promotionalContactConsent = false;
          break;
        case "JmKXtwTHDmOmGlBzu95OhstPTKB2":
          consentPolicy = {
            privacy: "https://wowpics.ai/privacypolicy.html",
            consent: "https://wowpics.ai/consent.html",
          };
          promotionalContactConsent = true;
          break;
        case "4oWLkAGTYKgmanDN8QJg7rqD7yj1":
          consentPolicy = {
            privacy: "https://bit.ly/4bZ4ZsD",
            consent: "https://bit.ly/4bZ4ZsD",
          };
          promotionalContactConsent = true;
          break;

        case "YBbLVx1YIidoOGl4iYLzTxOXbyJ2":
          consentPolicy = {
            privacy: "https://asprodparis.com/privacy-policy",
            consent: "https://asprodparis.com/consent",
          };
          promotionalContactConsent = true;
          break;
        case "pOODPmvHYhUQIjxEc8IvvUcxkxY2":
          consentPolicy = {
            privacy: "https://consent.aiphotoshareindia.com",
            consent: "https://consent.aiphotoshareindia.com",
          };
          promotionalContactConsent = true;
          break;
        case "CSFfB43CBJaD4lBf85XvNt8HfCQ2":
          consentPolicy = {
            privacy: "https://fotoowl.ai/privacypolicy",
            consent: "https://fotoowl.ai/consent",
          };
          registrationForm = {
            email: false,
            mobile: true,
          };
          promotionalContactConsent = true;
          break;
        case "J3o4ReNEJVaK5S1qizjIHvPdhIz2":
          consentPolicy = {
            privacy: "https://fotoowl.ai/privacypolicy",
            consent: "https://fotoowl.ai/consent",
          };
          registrationForm = {
            email: false,
            mobile: true,
          };
          promotionalContactConsent = true;
          break;

        case "uPs7G1CKSmQ7AvsVGkKizeiL3zz2":
          basicConsent = false;
          promotionalContactConsent = true;
          showCameraHelpVideo = false;
          break;

        default:
          consentPolicy = {
            privacy: "https://fotoowl.ai/privacypolicy",
            consent: "https://fotoowl.ai/consent",
          };
          promotionalContactConsent = true;
          break;
      }
      break;
  }
  return {
    consentPolicy,
    promotionalContactConsent,
    inquiry,
    basicConsent,
    registrationForm,
    showCameraHelpVideo,
  };
};

export { getEventCustomisations };

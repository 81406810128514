import { copyUrl } from "helpers/utils";
import { useEffect, useState } from "react";
import { Input, InputGroup } from "reactstrap";
import ShareOnSocial from "./Share";
import { ACTIVITY } from "../enums";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const useDownloadMessage = () => {
  const [isOpenDownloadMessage, setIsOpenDownloadModal] = useState(false);
  const handleOnOpenDownloadMessage = () => setIsOpenDownloadModal(true);
  const handleOnCloseDownloadMessage = () => setIsOpenDownloadModal(false);
  // const [downloadImageUrl, setDownloadImageUrl] = useState();
  // const [rawImageUrl, setRawImageUrl] = useState();
  const [photo, setPhoto] = useState();

  const handleSetDownloadImageUrl = (photo) => {
    // setDownloadImageUrl(thumbnail);
    // setRawImageUrl(raw);
    setPhoto(photo);
  };
  return {
    handleOnOpenDownloadMessage,
    handleOnCloseDownloadMessage,
    // downloadImageUrl,
    handleSetDownloadImageUrl,
    isOpenDownloadMessage,
    // rawImageUrl,
    photo,
  };
};
const DownloadMessage = ({
  handleOnCloseDownloadMessage,
  isOpenDownloadMessage,
  header,
  body,
  message,
  branding,
  handlActivity,
  photo,
  eventData,
}) => {
  useEffect(() => {
    if (isOpenDownloadMessage && message) {
      copyUrl("download-message");
    }
  }, [isOpenDownloadMessage && message]);

  const previewImg = photo?.thumbnail_url;
  const rawImg = photo?.path_dict?.raw_url;

  if (isOpenDownloadMessage && (header || body || message)) {
    return (
      <div className="download-message card">
        <div className="download-message-container">
          <i
            className="mdi mdi-close position-absolute end-0 top-0 fs-4 p-1"
            onClick={handleOnCloseDownloadMessage}
          />
          <img src={previewImg} className="download-message-img" />
          <div className="download-message-text card-body">
            <h5 className="card-title">{header}</h5>
            <div className="card-text mt-3">
              <div>{body}</div>
              {message && (
                <InputGroup className="mt-3">
                  <Input
                    disabled={true}
                    id="download-message"
                    value={message}
                  />

                  <CopyToClipboard
                    text={message}
                    onCopy={() => {
                      handlActivity(ACTIVITY.COPY_HASHTAG);
                    }}
                  >
                    <button
                      className="btn btn-soft-secondary waves-effect waves-light"
                      // onClick={() => {
                      //   handlActivity(ACTIVITY.COPY_HASHTAG);
                      //   copyUrl("download-message");
                      // }}
                    >
                      <i className="mdi mdi-content-copy" />
                    </button>
                  </CopyToClipboard>
                </InputGroup>
              )}
            </div>
            <div className="icon-color">
              <ShareOnSocial
                brandingData={branding}
                className={"w-100 gap-3 justify-content-center mt-2"}
                iconSize={"23px"}
                photo={photo}
                eventData={eventData}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default DownloadMessage;

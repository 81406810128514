import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { getPublicGalleryEventList } from "helpers/jiraya_helper";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import useSWRInfinite from "swr/infinite";
import useSWR from "swr";
import { GET_PUBLIC_EVENT_GALLERY } from "helpers/jiraya_url";
import { EVENT_ORDER_BY } from "../../components/Events/enums";
import PublicEventCard from "components/Public_gallery/PublicEventCard";
import PublicGalleryFooter from "components/Public_gallery/PublicgalleryFooter";
import PublicEventForm from "components/Events/Forms/publicEventForm";
import PublicGalleryNavbar from "components/Public_gallery/PublicgalleryNavbar";
import { GET_PUBLIC_EVENT_BRANDING } from "helpers/jiraya_url";
import { getPublicGalleryEventBranding } from "helpers/jiraya_helper";
import EventFilter from "components/Public_gallery/EventFilter";
import GalleryPage from "./GalleryPage2";

const ENV = process.env.REACT_APP_ENV;
const NoEvents = () => {
  return (
    <div className="ht-70 d-flex flex-column align-items-center justify-content-center">
      <div className="mt-4">
        {/* <img className="img-fluid" width={"500px"} src={eventImage} /> */}
        <lottie-player
          src="https://assets6.lottiefiles.com/packages/lf20_lnn3yenp.json"
          background="transparent"
          speed="1"
          style={{ width: "auto", maxHeight: "250px" }}
          className="animation"
          rendererSettings={{ viewBoxSize: "700 0 2400 590" }}
          loop
          autoplay
        ></lottie-player>
      </div>
      <h4 className="mt-4">No Events Available</h4>
    </div>
  );
};

const PAGE_SIZE = 18;
const Page = ({ pageData, gridStyle }) => {
  if (pageData.data.length > 0) {
    return (
      <Row>
        {pageData.data.map((item, index) => (
          <Col md={12}>
            <PublicEventCard
              event={item}
              event_access_key={item.event_access_key}
              event_ask_pin={item.event_ask_pin}
              key={item.event_id}
              gridStyle={gridStyle}
            />
          </Col>
        ))}
      </Row>
    );
  } else {
    return <NoEvents />;
  }
};
const PageSmall = ({ pageData, gridStyle }) => {
  if (pageData.data.length > 0) {
    return (
      <Row>
        {pageData.data.map((item, index) => (
          <Col md={4} key={index} className="gy-4 gx-4">
            <PublicEventCard
              event={item}
              access_key={item.event_access_key}
              event_ask_pin={item.event_ask_pin}
              event_access_level={item.event_access_level}
              key={item.event_id}
              gridStyle={gridStyle}
            />
          </Col>
        ))}
      </Row>
    );
  } else {
    return <NoEvents />;
  }
};

// console.log(url);

const PublicGallery = () => {
  const { host, pathname } = new URL(window.location);
  const [domainUrl, setDomainUrl] = useState("");

  useEffect(() => {
    let _domainUrl;
    if (host.includes("site.fotoowl.ai")) {
      _domainUrl = host + pathname;
    } else if (host.includes("localhost")) {
      _domainUrl =
        ENV === "dev"
          ? `dev-site.fotoowl.ai` + pathname
          : "site.fotoowl.ai" + pathname;
    } else {
      _domainUrl = host;
    }
    setDomainUrl(_domainUrl);
  }, [pathname]);

  const [orderBy, setOrderBy] = useState(EVENT_ORDER_BY.EVENT_TIME_DESC);
  const [searchQuery, setSearchQuery] = useState();
  const [eventSearchQuery, setEventSearchQuery] = useState("");
  const [branding, setBranding] = useState(false);
  //   useDocumentTitle("Foto Owl - My Events");
  const getKey = (pageIndex, previousPageData) => {
    if (!domainUrl) {
      return null;
    }
    if (
      previousPageData &&
      (!previousPageData.data || !previousPageData.data.length)
    ) {
      return null;
    } // reached the end
    return [
      GET_PUBLIC_EVENT_GALLERY,
      pageIndex,
      eventSearchQuery,
      orderBy,
      domainUrl,
    ]; // SWR key
  };
  const { data, size, setSize, error } = useSWRInfinite(
    getKey,
    (url, page, search_text, sort_type, domain_url) => {
      console.log(`page: ${page}`);
      return getPublicGalleryEventList({
        params: {
          domain_url,
          search_text,
          sort_type,
          page,
          page_size: 20,
        },
      });
    }
  );

  const { data: brandingData, error: brandError } = useSWR(
    domainUrl ? [GET_PUBLIC_EVENT_BRANDING] : null,
    (url) => {
      return getPublicGalleryEventBranding({
        params: {
          domain_url: domainUrl,
        },
      });
    }
  );

  const [lastPage, setLastPage] = useState();
  const currentPage = size - 1;
  const pageData = data && data[currentPage];
  const brandData = brandingData?.data;
  const loading = !pageData;
  var errorMessage = !loading && error;
  const updatePage = (i) => {
    setSize(size + i);
  };

  const gotoPage = (pageNum) => {
    console.log(`go to page ${pageNum}`);
    setSize(pageNum);
  };

  useEffect(() => {
    if (pageData && !pageData.data && currentPage === 0) {
      setLastPage(-1);
    }
  }, [pageData]);

  useEffect(() => {
    const pageData = data && data[currentPage];
    if (pageData && !pageData.data && pageData.data.length < PAGE_SIZE) {
      setLastPage(currentPage);
    }
  }, [data]);

  const [gridStyle, setGridStyle] = useState("small");
  const changeGridStyle = (s) => {
    localStorage.setItem("gridStyle", s);
    setGridStyle(s);
  };
  useEffect(() => {
    const s = localStorage.getItem("gridStyle");
    if (s) {
      setGridStyle(s);
    }
  }, []);
  const eventList = useCallback(() => {
    if (loading) {
      return (
        <div className="loader loader-full">
          <Loader />
        </div>
      );
    } else if (pageData && pageData.data.length === 0 && currentPage === 0) {
      return <NoEvents />;
    } else if (errorMessage) {
      return <div className="loader loader-full">{errorMessage}</div>;
    } else if (!pageData.data || pageData.data.length == 0) {
      // rollback to previous page
      updatePage(-1);
      // set the last page
      setLastPage(currentPage - 1);
    } else {
      if (gridStyle === "small")
        return <PageSmall pageData={pageData} gridStyle={gridStyle} />;
      else return <Page pageData={pageData} gridStyle={gridStyle} />;
    }
  }, [pageData, loading, gridStyle]);

  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpen(!open);

  // Event search funtionality chnages.

  const handleOnOrderBy = (v) => {
    console.log(v);

    setOrderBy(v); // reloadImages();
  };
  if (host.includes("kittumangayi")) {
    return <GalleryPage eventId={4060} />;
  }

  return (
    <React.Fragment>
      <div className="public-event">
        <PublicGalleryNavbar
          branding={brandData}
          eventId={pageData?.event_id}
        />
        <Container fluid>
          <div className="page-cont">
            <Row>
              <EventFilter
                toggle={toggle}
                open={open}
                setSearchQuery={setSearchQuery}
                setEventSearchQuery={setEventSearchQuery}
                handleSort={handleOnOrderBy}
                searchQuery={searchQuery}
                eventId={pageData?.event_id}
              />
            </Row>
            <Row className="d-flex justify-content-center pt-0">
              <Col md={11} xl={9}>
                {eventList()}
              </Col>
            </Row>
            {/* <Row className="d-flex justify-content-center pt-md-2 pt-0 form">
            <Col md={11} xl={9}>
              <PublicEventForm
                branding={brandData}
                event_id={pageData?.event_id}
              />
            </Col>
          </Row> */}
            {!loading && (
              <div className="d-flex justify-content-end align-items-center mb-3 me-2 gap-3">
                {currentPage > 0 && (
                  <div
                    onClick={() => updatePage(-1)}
                    className="link"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fs-3 align-middle mdi mdi-arrow-left-bold ms-1" />
                    {/* Prev Page */}
                  </div>
                )}

                {currentPage != 0 && currentPage + 1}

                {(!lastPage || currentPage < lastPage) && (
                  <div
                    onClick={() => updatePage(1)}
                    className="link"
                    style={{ cursor: "pointer" }}
                  >
                    {/* Next Page */}
                    <i className="fs-3 align-middle mdi mdi-arrow-right-bold ms-1" />
                  </div>
                )}
              </div>
            )}
          </div>
        </Container>
        <footer>
          <PublicGalleryFooter branding={brandData} />
        </footer>
      </div>
    </React.Fragment>
  );
};
export default PublicGallery;

import { useEffect, useState } from "react";
import GalleryPage from "./GalleryPage2";
import { getPortfolioEvent } from "../../helpers/jiraya_helper";
import useSWR from "swr";
import { GET_PORTFOLIO_EVENT } from "helpers/jiraya_url";
import Loader from "components/Loader";

const DefaultPage = () => {
  const [defaultEventId, setDefaultEventId] = useState();
  const [isPortfolio, setIsPortfolio] = useState(false);
  const [passKey, setPassKey] = useState();
  const { host, pathname } = new URL(window.location);
  let domain_url = "";
  if (host.includes("localhost")) {
    domain_url = "dev-site.fotoowl.ai/tusharkolhe";
  } else {
    domain_url = host + pathname;
  }
  const { data, error } = useSWR([GET_PORTFOLIO_EVENT, host], () =>
    getPortfolioEvent({ params: { domain_url } })
  );
  const isLoading = !data && !error;

  useEffect(() => {
    // const host = new URL(window.location).host;
    if (host.includes("kittumangayi")) {
      setDefaultEventId(4060);
    }
    if (data) {
      if (data?.ok && data?.data?.event_id) {
        setDefaultEventId(data.data.event_id);
        setPassKey(data.data.pass_key);
        setIsPortfolio(true);
      } else {
        console.log(error);
      }
    }
  }, [data]);
  // useEffect(() => {
  //   const host = new URL(window.location).host;

  // call api to get portfolio event id and pass key

  //   const getPortfolio = async () => {
  //     const data = await getPortfolioEvent({ domain_url: host });
  //     setPortfolioData(data);
  //     setDefaultEventId(data.event_id);
  //     setPasskey(data.pass_key);
  //   };

  // }, []);
  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return <div>{error}</div>;
  } else if (defaultEventId) {
    // console.log(eventId, passKey);
    return (
      <GalleryPage
        eventId={defaultEventId}
        passKey={passKey}
        isPortfolio={isPortfolio}
      />
    );
  } else {
    return <div>Default Page</div>;
  }
};

export default DefaultPage;

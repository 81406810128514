import classNames from "classnames";

const Error = ({ className, children }) => {
  return (
    <div className={classNames(className)}>
      <div className="d-flex h-100 justify-content-center flex-column align-items-center my-4">
        <i className="mdi mdi-alert-outline d-block display-4  text-warning" />
        <div className="text-warning">Something went wrong !!</div>
        {children}
      </div>
    </div>
  );
};

export default Error;

import { getOpenPeople } from "helpers/jiraya_helper";
import { GET_OPEN_PEOPLE } from "helpers/jiraya_url";
import { useEffect, useRef, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import useSWR from "swr";
import {
  useController,
  stopNavigationEventsPropagation,
} from "yet-another-react-lightbox";

const usePeopleTab = () => {
  const [selectedPeople, setSeletedPeople] = useState([]);
  const [filterPeople, setFilterPeople] = useState();
  const history = useHistory();
  const { search } = useLocation();
  useEffect(() => {
    const searchParam = new URLSearchParams(search);
    const people = searchParam.get("people");
    setFilterPeople(people);
  }, [search]);
  const handleSelectPeople = (people) => {
    // const i = selectedPeople.findIndex((i) => i.uuid_num === people.uuid_num);
    var _selectedPeople = [people];
    // if (i !== -1) {
    //   selectedPeople.splice(i);
    //   _selectedPeople = [...selectedPeople];
    // } else {
    //   _selectedPeople = [...selectedPeople, people];
    // }
    setSeletedPeople(_selectedPeople);
    if (_selectedPeople.length > 0) {
      const uuidNums = _selectedPeople.map((i) => i.uuid_num);
      const { pathname, search, hash } = history.location;
      const searchParam = new URLSearchParams(search);
      searchParam.delete("people");
      searchParam.append("people", uuidNums);
      history.push(`${pathname}?${searchParam.toString()}${hash}`);
      setFilterPeople(uuidNums.join(","));
    } else {
      setFilterPeople();
    }
    // handleSetIndex();
  };

  const handleRemoveSelectedPeople = (people) => {
    const i = selectedPeople.findIndex((i) => i.uuid_num === people.uuid_num);
    let nSelectedPeople;
    if (i !== -1) {
      selectedPeople.splice(i);
      nSelectedPeople = [...selectedPeople];
    } else {
      nSelectedPeople = [...selectedPeople, people];
    }
    const { pathname, search, hash } = history.location;
    const searchParam = new URLSearchParams(search);
    searchParam.delete("people");
    history.push(`${pathname}?${searchParam.toString()}${hash}`);
    setSeletedPeople(nSelectedPeople);
    if (nSelectedPeople.length === 0) setFilterPeople();
  };
  // const handleFilterPeople = () => {
  //   if (selectedPeople.length > 0) {
  //     const uuidNums = selectedPeople.map((i) => i.uuid_num);
  //     setFilterPeople(uuidNums.join(","));
  //   } else {
  //     setFilterPeople();
  //   }
  // };
  const handleRemoveAllFilter = () => {
    setSeletedPeople([]);
    setFilterPeople();
  };
  return {
    selectedPeople,
    handleSelectPeople,
    filterPeople,
    handleRemoveSelectedPeople,
  };
};
const PeopleTab = ({ eventId, photo, eventKey, handleSelectPeople }) => {
  const { id, raw_path, originalHeight, originalWidth } = photo;

  const controller = useController();
  const { data, error } = useSWR([GET_OPEN_PEOPLE, eventId, id], () =>
    getOpenPeople({
      params: {
        event_id: eventId,
        image_id: id,
        key: eventKey,
        image_raw_path: raw_path,
        image_height: originalHeight,
        image_width: originalWidth,
      },
    })
  );
  const isLoading = !(data || error);
  const peopleTabRef = useRef();
  return (
    <div
      className="gallery-people-tab"
      onPointerDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
        peopleTabRef.current?.focus();
      }}
    >
      {!isLoading &&
        data.data.map((item) => (
          <div
            key={item.uuid_num}
            className="people-icon"
            onClick={() => handleSelectPeople(item)}
          >
            <img height={50} src={item.img_url} />
          </div>
        ))}
    </div>
  );
};

export { usePeopleTab };
export default PeopleTab;

import logo from "assets/images/logo_full.png";
import logoWhite from "assets/images/logo-full-white-v2.png";
import { useMemo } from "react";

const Fotoowl = () => {
  const mode = useMemo(() => document.body.getAttribute("gallery-mode"));
  return (
    <div className="gallery-powered-by">
      <div>POWERED BY</div>
      <a href="https://fotoowl.ai" target={"_blank"}>
        <img src={mode === "dark" ? logoWhite : logo} alt="fotoowl" />
      </a>
    </div>
  );
};

export const FotoowlTop = () => {
  return (
    <div className="fotoowl-top">
      <a href="https://fotoowl.ai" target={"_blank"}>
        <img src={logo} alt="fotoowl" />
      </a>
    </div>
  );
};

export const FotoowlFloating = () => {
  return (
    <div className="fotoowl-floating">
      POWERED BY
      <a href="https://fotoowl.ai" target={"_blank"}>
        <img src={logoWhite} alt="fotoowl" />
      </a>
    </div>
  );
};

export default Fotoowl;

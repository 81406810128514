import "assets/scss/custom/gallery/cover_themes/basic.scss";
import "assets/scss/custom/gallery/cover_themes/common.scss";
import { BrandLogo, ContactNav } from "../Branding/BrandLogo";
import classNames from "classnames";
import loader from "assets/images/icons/loader.svg";
import { useMemo } from "react";
import { GALLERY_MODE } from "../enums";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FotoowlTop } from "../FotoOwl";

const BasicTheme = ({
  coverImageVersion,
  eventDetails,
  handleOnViewGallery,
  buttonTitle,
  brandingStyle,
  coverImgRef,
  coverTextRef,
  isImgLoading,
  onLoadCoverImg,
  isMobile,
  SocialMediaNav,
  galleryMode,
  isFotoOwlBranding,
  uploadButtonTitle,
  isGuestUploadAllowed,
  handleOpenGuestUpload,
}) => {
  const { branding, cover_image_info, name, date } = eventDetails;
  const { logoSize, showLogo, showSocial, showEventName } = brandingStyle.cover;
  const { mobileCover, laptopCover, cover, sixteenbySeven, fourByThree } =
    coverImageVersion;
  const coverSrc = useMemo(() => {
    if (galleryMode === GALLERY_MODE.REGISTRATION) {
      return isMobile ? fourByThree : sixteenbySeven;
    } else {
      return isMobile ? mobileCover : laptopCover;
    }
  }, [coverImageVersion, isMobile]);

  const { eventId } = useParams();
  var addonCoverStyle = {};
  var addonEventDetailStyle = {};
  var noCover = false;
  if (Number(eventId) === 9984) {
    addonCoverStyle = { height: isMobile ? "70vh" : "2vh" };
    addonEventDetailStyle = { display: "none" };
    noCover = isMobile ? false : true;
  }

  return (
    <div className="gallery-cover cover-theme-basic" style={addonCoverStyle}>
      {!noCover && (
        <div className="cover-img-container">
          {isFotoOwlBranding && <FotoowlTop />}
          {isImgLoading && (
            <img
              className="position-absolute top-50 start-50 translate-middle"
              src={loader}
            />
          )}
          <img
            ref={coverImgRef}
            className="cover-img easeload"
            src={coverSrc}
            onLoad={() => {
              onLoadCoverImg();
            }}
          />
          <div className="cover-img-overlay"></div>
        </div>
      )}
      <div ref={coverTextRef} className="cover-text easeload">
        <div className="event-details ">
          {showEventName && (
            <>
              <div className="event-name"> {name}</div>
              <time className="event-date">{date}</time>
            </>
          )}
          <div className="view-gallery-btn" onClick={handleOnViewGallery}>
            {" "}
            {buttonTitle}
          </div>
          {isGuestUploadAllowed && (
            <div className="view-gallery-btn" onClick={handleOpenGuestUpload}>
              {uploadButtonTitle}
            </div>
          )}
        </div>
        <div className="branding">
          {showLogo && (
            <>
              <BrandLogo {...branding} className={classNames(logoSize.value)} />
              <ContactNav brandingData={branding} />
            </>
          )}
          {showSocial && branding && (
            <SocialMediaNav
              brandingData={branding}
              iconSize={"15"}
              className={""}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default BasicTheme;

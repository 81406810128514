const getCloudflareUrl = ({
  img_url,
  originalHeight,
  originalWidth,
  branding,
  frame,
}) => {
  var cfParams = {
    image: img_url,
    height: Math.round(originalHeight),
    width: Math.round(originalWidth),
  };

  if (branding?.show_watermark) {
    cfParams.is_watermark = true;
    const {
      logo_raw_path: watermark_path,
      watermark_height_ratio,
      watermark_position,
      watermark_x_offset_ratio,
      watermark_y_offset_ratio,
      logo_width: watermark_width,
      logo_height: watermark_height,
      watermark_opacity,
      img_url: watermark_img,
    } = branding;

    cfParams = {
      ...cfParams,
      watermark_height_ratio,
      watermark_position: watermark_position || 0,
      watermark_x_offset_ratio: watermark_x_offset_ratio || 0,
      watermark_y_offset_ratio: watermark_y_offset_ratio || 0,
      watermark_width,
      watermark_height,
      watermark_opacity,
      watermark_img,
    };
  }
  if (frame) {
    cfParams.is_frame = true;
    var frame_img, frame_width, frame_y_offset;
    if (originalHeight > originalWidth) {
      frame_img = frame.vertical_frame_url;
      frame_width = frame.frame_vertical_width;
      frame_y_offset = frame.frame_vertical_offset;
    } else {
      frame_img = frame.horizontal_frame_url;
      frame_width = frame.frame_horizontal_width || 100;
      frame_y_offset = frame.frame_horizontal_offset;
    }
    cfParams = {
      ...cfParams,
      frame_img,
      frame_width,
      frame_y_offset,
    };
  }

  // console.log(cfParams);
  const cfWatermarkUrl = `https://watermark.fotoowl.ai/?${new URLSearchParams(
    cfParams
  ).toString()}`;
  return cfWatermarkUrl;
};

const getFrameUrl = ({
  img_url,
  originalHeight,
  originalWidth,
  branding,
  frame,
}) => {
  var frame_img;
  var image_type;
  var frame_width;
  var frame_height;
  var border;

  if (originalHeight > originalWidth) {
    const frameData = frame.vertical;
    frame_img = frameData.frame_url;
    image_type = "vertical";
    frame_width = frameData.frame_size[1];
    frame_height = frameData.frame_size[0];
    border = frameData.border;
  } else {
    const frameData = frame.horizontal;
    frame_img = frameData.frame_url;
    image_type = "horizontal";
    frame_width = frameData.frame_size[1];
    frame_height = frameData.frame_size[0];
    border = frameData.border;
  }
  const cfParams = {
    image: img_url,
    frame_img,
    frame_size: [frame_height, frame_width],
    height: originalHeight,
    width: originalWidth,
    border,
    image_type,
  };
  const cfWatermarkUrl = `https://prod-frame.fotoowl.ai/?${new URLSearchParams(
    cfParams
  ).toString()}`;
  return { src: cfWatermarkUrl, height: frame_height, width: frame_width };
};
export { getCloudflareUrl, getFrameUrl };

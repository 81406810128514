import { Modal, ModalBody, Row, Col } from "reactstrap";
import { supportedLanguages, handleChangeLanguage } from "i18n";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = ({ isOpen, toggle }) => {
  const { i18n } = useTranslation();
  const language = useMemo(() => {
    return i18n.language.split("-")[0];
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      // className="modal-fullscreen"
      size="lg"
      centered
    >
      <div className="modal-header">
        <div className="modal-title">
          <h4>
            <i className="mdi mdi-translate me-2" />
            Select Language
          </h4>
        </div>
        <button className="btn-close" type="button" onClick={toggle}></button>
      </div>
      <ModalBody>
        <Row className="justify-content-center ">
          {supportedLanguages.map((item, key) => (
            <Col
              key={key}
              onClick={() => {
                handleChangeLanguage(item.code);
                toggle();
              }}
              xs={5}
              md={3}
              className={classNames("shadow text-center border m-3 ", {
                "bg-light border-primary": item.code === language,
              })}
              style={{ cursor: "pointer" }}
            >
              <div className="p-1 py-3">
                <h5>{item.name}</h5>
              </div>
            </Col>
          ))}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default LanguageSelector;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Row } from "reactstrap";
import useSWR from "swr";
import { formatBytes } from "helpers/utils";
import { DOWNLOAD_ZIP_STATUS } from "helpers/enums";
import { useTranslation } from "react-i18next";

const DownloadJob = ({
  startDownloadJob,
  getDownloadJobStatus,
  downloadJobStatusUrl,
  isOpen,
  eventId,
  collectionId,
  requestId,
  eventKey,
}) => {
  const { t } = useTranslation();
  const state = useLocation();
  const [processId, setProcessId] = useState();

  const [downloadStatus, setDownloadStatus] = useState({
    status: -1,
    message: "",
    download_links: null,
    info: null,
  });
  // state -1 idle, 0-started 1-completed 2 failed
  const { data, error } = useSWR(
    processId ? downloadJobStatusUrl : null,
    () =>
      getDownloadJobStatus({
        params: { download_process_id: processId, key: eventKey },
      }),
    { refreshInterval: 5000 }
  );

  useEffect(() => {
    var emailId = null;
    if (isOpen) {
      emailId = localStorage.getItem("client-login");
    }

    const startDownload = async () => {
      const response = await startDownloadJob({
        params: {
          event_id: eventId,
          collection_id: collectionId,
          request_id: requestId,
          process_id: processId,
          key: eventKey,
          email_id: emailId,
        },
      });

      if (response.ok) {
        setDownloadStatus({ status: 0 });
        setProcessId(response.data.download_process_id);
      } else {
        setDownloadStatus({ status: 2 });
        toast(response.message);
      }
    };
    startDownload();
  }, []);

  useEffect(() => {
    if (data) {
      setDownloadStatus({ ...downloadStatus, ...data.data });
      if (data.data.status === 1) {
        setProcessId(null);
      }
    }
  }, [data]);

  const getPartStatus = (item, part) => {
    switch (item.status) {
      case DOWNLOAD_ZIP_STATUS.PENDING:
        return (
          <>
            <button className="btn btn-primary" disabled>
              {t("Part")} {Number(part) + 1} - {t("Waiting")}
              <i className="mdi mdi-loading mdi-spin ms-2" />
            </button>
          </>
        );

      case DOWNLOAD_ZIP_STATUS.STARTED:
        return (
          <>
            <button className="btn btn-primary" disabled>
              {t("Part")} {Number(part) + 1} - {t("Zipping")}
              <i className="mdi mdi-loading mdi-spin ms-2" />
            </button>
          </>
        );
      case DOWNLOAD_ZIP_STATUS.COMPLETED:
        return (
          <>
            <a className="btn btn-primary py-0" href={item.link}>
              {t("Part")} {Number(part) + 1} - {t("Download")}{" "}
              {formatBytes(item.size)}
              <i className="mdi mdi-download-circle ms-2 fs-3 align-middle" />
            </a>
          </>
        );
      case DOWNLOAD_ZIP_STATUS.FAILED:
        return (
          <>
            <button className="btn btn-primary" disabled>
              {t("Part")} {Number(part) + 1} - {t("Failed")}
              <i
                className="mdi mdi-progress-alert ms-2 fs-3 align-middle"
                style={{ color: "orange" }}
              />
            </button>
          </>
        );
    }
  };

  var header;
  var body;
  if (downloadStatus.status === -1) {
    var header = (
      <div>
        {t("Preparing your Download")}
        <i className="mdi mdi-loading mdi-spin fs-2 align-middle ms-2" />
      </div>
    );
    body = (
      <div className="text-center">
        <div>{t("Download Process is starting..")}</div>
      </div>
    );
  } else if (downloadStatus.status === 0) {
    header = (
      <div>
        {t("Started Zipping Images")}
        <i className="mdi mdi-loading mdi-spin fs-2 align-middle ms-2" />
      </div>
    );
  } else if (downloadStatus.status === 1) {
    header = (
      <div>
        {t("Download Ready")}
        <i
          className="mdi mdi-progress-check fs-1 align-middle ms-2"
          style={{ color: "green" }}
        />
      </div>
    );
  } else if (downloadStatus.status === 2) {
    header = (
      <div>
        {t("One or more parts zip creation failed")}
        <i
          className="mdi mdi-progress-alert fs-1 align-middle ms-2"
          style={{ color: "orange" }}
        />
      </div>
    );
  }

  if (downloadStatus.status !== -1 && downloadStatus.info) {
    body = (
      <div className="text-center">
        {/* Creating the zip, This may take few minutes... */}
        <div className="d-flex flex-column gap-2">
          {Object.entries(downloadStatus.info).map(([part, item]) => (
            <div
              key={part}
              className={
                "d-flex gap-2 align-items-center justify-content-center w-100"
              }
            >
              {getPartStatus(item, part)}
            </div>
          ))}
        </div>
        <p className="mt-4">
          {t("You can wait here")} <br /> {t("or")} <br />
          {t(
            "We will also send you an email with the download link as soon as its ready"
          )}
        </p>
      </div>
    );
  }
  return (
    <div
      style={{ height: "80vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <Row className="w-100 justify-content-center">
        <Card className="col-md-5 col-sm-11">
          <CardHeader className="d-flex justify-content-center">
            <h5>{header}</h5>
          </CardHeader>
          <CardBody>
            <div>{body}</div>
          </CardBody>
          {/* <Fotoowl /> */}
        </Card>
      </Row>
    </div>
  );
};

export default DownloadJob;

import { Modal, ModalBody, Row } from "reactstrap";
import { GET_OPEN_COMMENT_BY_ID } from "helpers/jiraya_url";
import { getOpenImageCommentById } from "helpers/jiraya_helper";
import useSWR from "swr";
import { useState } from "react";
import classNames from "classnames";
import React from "react";
import { toast } from "react-toastify";
import { postOpenImageComment } from "helpers/jiraya_helper";
import { useTranslation } from "react-i18next";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const useComment = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandComment = () => {
    setIsExpanded(!isExpanded);
  };
  return { isExpanded, handleExpandComment };
};

const CommentToolbar = ({
  handlers,
  slide,
  offset,
  rect,
  eventId,
  eventKey,
  emailId,
  checkLogin,
  isExpanded,
  handleExpandComment,
}) => {
  const { t } = useTranslation();
  const { data, error, mutate } = useSWR(
    () =>
      slide?.id && isExpanded
        ? [GET_OPEN_COMMENT_BY_ID, slide.id, eventId]
        : null,
    () =>
      getOpenImageCommentById({
        params: {
          event_id: eventId,
          key: eventKey,
          image_id: slide?.id,
        },
      })
  );

  return (
    <div
      id={`comment-${slide?.id}`}
      className={classNames("comment-container-new lightbox-shadow", {
        expand: isExpanded,
      })}
      {...handlers}
    >
      <i
        onClick={handleExpandComment}
        className="mdi mdi-chevron-double-down py-2 w-100 text-center d-lg-none"
      />
      <div className="comment-header">
        <h4 className="comments-heading pb-0 mb-0 ">{t("Comments")}</h4>
        <small>{slide.name}</small>
      </div>
      {
        <ClientComment
          eventId={eventId}
          imageId={slide?.id}
          pass_key={eventKey}
          commentList={data ? data.data : null}
          email_id={emailId}
          checkLogin={checkLogin}
          mutateComments={mutate}
        />
      }
    </div>
  );
};

const ClientComment = ({
  commentList,
  eventId,
  imageId,
  mutateComments,
  pass_key,
  email_id,
  setScrollTo,
  checkLogin,
}) => {
  const [commentText, setCommentText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const showCommentList = commentList;

  const { t } = useTranslation();
  const handleOnComment = async () => {
    setIsSubmitting(true);

    try {
      const response = await postOpenImageComment({
        event_id: eventId,
        image_id: imageId,
        comment: commentText,
        key: pass_key,
        email_id: email_id,
      });

      if (!response.ok) {
        toast("Failed to comment !! try after some time");
      } else {
        setCommentText("");
        mutateComments();
      }
    } catch (err) {
      console.log(err);
      toast("Failed to comment !! try after some time");
    }
    setIsSubmitting(false);
    setIsOpen(false);
  };

  const onInputCommentChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (checkLogin()) {
      setCommentText(event.target.value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleOnComment(e);
    }
  };

  const modelInputRef = React.createRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleOnCommentClick = (e) => {
    if (checkLogin()) {
      e.stopPropagation();
      e.preventDefault();

      if (isMobile) {
        setIsOpen(true);
        // modelInputRef.focus();
      }
    }
  };
  const get_ago_time_str = (comment_time_min) => {
    const current_utc_min = Math.floor(Date.now() / (1000 * 60));
    let ago = current_utc_min - comment_time_min;
    if (ago == 0) return "now";
    if (ago < 60) return `${ago} min ago`;

    ago = Math.floor(ago / 60);
    if (ago < 24) return `${ago} hr ago`;

    ago = Math.floor(ago / 24);
    return `${ago} d ago`;
  };
  const PlaceHolder = () => {
    return (
      <div className="comment placeholder-glow w-100">
        <div className="profile-name avatar-sm comment-avatar placeholder"></div>
        <div className="comment-info-containe w-100">
          <div className="comment-top-info w-100">
            <span className="placeholder col-5"></span>

            <div className="comment-time w-100">
              <span className="placeholder col-3"></span>
            </div>
          </div>
          <div className="comment-text w-100">
            <span className="placeholder col-11"></span>
            <span className="placeholder col-11"></span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id={`comment-${imageId}`} className="client-comment">
      <Modal isOpen={isOpen} zIndex={12000} toggle={() => setIsOpen(!isOpen)}>
        <ModalBody>
          <div className="client-comment">
            <div className="comment-list">
              <div className="add-comment">
                <input
                  ref={modelInputRef}
                  id={`comment-input-${imageId}`}
                  className="add-comment-input"
                  placeholder="Add a comment"
                  value={commentText}
                  // onKeyDown={handleKeyDown}
                  onChange={onInputCommentChange}
                  style={{ fontSize: "16px" }}
                  autofocus
                  onFocus={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <button
                  className="btn btn-soft-primary"
                  onClick={handleOnComment}
                  disabled={commentText.length == 0 || isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      {t("Posting")}
                      <i className="ms-2 mdi mdi-loading align-middle mdi-spin" />
                    </>
                  ) : (
                    "Post"
                  )}
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <div className="comment-list">
        {showCommentList ? (
          showCommentList.map((commentInfo, idx) => (
            <div className="comment" key={idx}>
              <div className="profile-name avatar-sm comment-avatar">
                {commentInfo.email_id ? commentInfo.email_id.slice(0, 2) : "AN"}
              </div>
              <div className="comment-info-container">
                <div className="comment-top-info">
                  <b>
                    {commentInfo.email_id ? commentInfo.email_id : "anonymous"}
                  </b>
                  <div className="comment-time">
                    {get_ago_time_str(commentInfo.add_time_min)}
                  </div>
                </div>
                <div className="comment-text">{commentInfo.comment}</div>
              </div>
            </div>
          ))
        ) : (
          <PlaceHolder />
        )}
      </div>
      <div className="add-comment">
        {/* <div className="comment-avatar"></div> */}

        {/* {isMobile ? (
          <button
            className="btn btn-outline-primary w-100 mt-3 px-2 "
            onClick={handleOnCommentClick}
            style={{ height: "40px" }}
            disabled={isSubmitting}
          >
            Add Comment
           
          </button>
        ) : ( */}
        <>
          <input
            id={`comment-input-${imageId}`}
            className="add-comment-input"
            placeholder={t("Add a comment")}
            value={commentText}
            onKeyDown={handleKeyDown}
            onChange={onInputCommentChange}
            disabled={isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            // onClick={handleOnCommentClick}
          />
          <button
            className="btn btn-soft-primary"
            onClick={handleOnComment}
            disabled={commentText.length == 0 || isSubmitting}
          >
            {isSubmitting ? (
              <>
                {t("Post")}
                <i className="ms-1 mdi mdi-loading align-middle mdi-spin" />
              </>
            ) : (
              <span className="px-2"> {t("Post")}</span>
            )}
          </button>
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default CommentToolbar;

import { useTranslation } from "react-i18next";
import BrandFooter from "./Branding/brand_footer";
import Fotoowl from "./FotoOwl";
import SponsorsList from "./Sponsor";
import { useMemo } from "react";
import { FotoowlFloating } from "./FotoOwl";
import classNames from "classnames";

const Footer = ({ eventData, eventId, brandingStyle }) => {
  const { i18n } = useTranslation();
  const language = useMemo(() => {
    return i18n.language.split("-")[0];
  });
  return (
    <>
      <SponsorsList eventId={eventId} />
      {eventData && !eventData.no_foto_owl_branding && <FotoowlFloating />}
      <div
        className={classNames("gallery-footer ", {
          "pb-5 pt-3": eventData && !eventData.no_foto_owl_branding,
        })}
      >
        {eventData.branding && (
          <BrandFooter
            {...eventData.branding}
            eventId={eventId}
            brandingStyle={brandingStyle}
          />
        )}
        <div className="row justify-content-center gx-0 px-4 align-items-center">
          {eventData && !eventData.no_foto_owl_branding_level_2 && (
            <div className="col-4 flex-grow-1 offset-3  col-md-4 offset-md-1">
              <Fotoowl />
            </div>
          )}
          <div className="col-3 col-md-1  ">
            <select
              className="px-2 py-1"
              onChange={(e) => {
                const language = e.target.value;
                if (language === "ar") {
                  document.documentElement.dir = "rtl";
                } else {
                  document.documentElement.dir = "ltr";
                }
                i18n.changeLanguage(language);
              }}
              value={language}
            >
              <option value={"en"}>English</option>
              <option value={"es"}>Spanish</option>
              <option value={"de"}>German</option>
              <option value={"fr"}>French</option>
              <option value={"tr"}>Turkish</option>
              <option value={"mr"}>Marathi</option>
              <option value={"hn"}>Hindi</option>
              <option value={"kn"}>Kannada</option>
              <option value={"ru"}>Russian</option>
              <option value={"zh"}>Mandarin</option>
              <option value={"ar"}>Arabic</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import classNames from "classnames";
import React, { createRef, useContext, useEffect, useState } from "react";
import { ACTIVITY } from "../enums";
import { add_https } from "helpers/utils";
import GalleryContext from "store/galleryContext";

export const BrandLogo = ({ website, img_url, name, style, className }) => {
  const { handleActivity } = useContext(GalleryContext);
  if (img_url && website) {
    return (
      <a
        href={add_https(website)}
        target="_blank"
        role="button"
        onClick={() => handleActivity(ACTIVITY.WEBSITE_VISIT)}
      >
        <div className="brand-container">
          <img src={img_url} className={classNames("brand-logo", className)} />
          {/* <div className="brand-name">{name}</div> */}
        </div>
      </a>
    );
  } else if (img_url) {
    return (
      <div className="brand-container">
        <img src={img_url} className={classNames("brand-logo", className)} />
        {/* <div className="brand-name">{name}</div> */}
      </div>
    );
  }
  return <div></div>;
};

export const ContactNav = ({ brandingData, className }) => {
  return (
    <div className="d-flex justify-content-center brand-contact">
      <div className={classNames("d-flex", "gap-2", className)}>
        {brandingData?.contact_number && (
          <a href={`tel:${brandingData.contact_number}`}>
            <div className="d-flex flex-row">
              <div className="icon phone me-1"></div>
              <div>{brandingData.contact_number}</div>
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

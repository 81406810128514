import classNames from "classnames";
import { getOrgEventList } from "helpers/jiraya_helper";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import useSWR from "swr";

const EventList = ({ eventId, orgId, handleOnChangeEventId, filterText }) => {
  const { data } = useSWR([orgId], () =>
    getOrgEventList({
      params: {
        org_id: orgId,
        filter_text: filterText,
      },
    })
  );
  return (
    <UncontrolledDropdown>
      <DropdownToggle tag={"div"}>
        <button className="btn btn-secondary px-3">
          Select Match <i className="ms-1 mdi mdi-chevron-down" />
        </button>
      </DropdownToggle>
      <DropdownMenu>
        {data?.data.map((item) => (
          <DropdownItem
            style={
              Number(eventId) === item.event_id
                ? {
                    backgroundColor: "var(--gallery-primary-color)",
                    color: "var(--gallery-primary-font-color)",
                  }
                : {}
            }
            onClick={() => handleOnChangeEventId(item.event_id)}
          >
            {item.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default EventList;

import classNames from "classnames";

const { Modal, Card, CardHeader, CardBody } = require("reactstrap");

const CustomModal = ({
  isOpen,
  toggle,
  children,
  header,
  className,
  ...props
}) => {
  return (
    <Modal
      size="lg"
      className={classNames("custom-modal", className)}
      centered={true}
      isOpen={isOpen}
      toggle={toggle}
      {...props}
    >
      <Card>
        <CardHeader>
          {header}
          <i
            onClick={toggle}
            className="mdi mdi-close position-absolute top-0 end-0 p-2"
          />
        </CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    </Modal>
  );
};

export default CustomModal;

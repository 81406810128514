import { useEffect, useState } from "react";
import { Input, Modal, ModalBody } from "reactstrap";
import { SocialNavColor } from "../Branding/SocialNav";
import { BrandLogo } from "../Branding/BrandLogo";
import { getPinCheck } from "helpers/jiraya_helper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const keyType = {
  pass_key: 0,
  share_key: 1,
};
const EventAccess = ({ eventId, eventDetails, handleOnValidPin, pin }) => {
  const { t } = useTranslation();
  const [enterPin, setPinEnterPin] = useState(pin || "");
  useEffect(() => {
    handleChangePin(pin);
  }, [pin]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChangePin = async (enterPin) => {
    if (enterPin == null || !isNaN(Number(enterPin))) {
      setPinEnterPin(enterPin);
      if (enterPin?.length == 4) {
        setIsSubmitting(true);
        try {
          const response = await getPinCheck({
            params: { event_id: eventId, key: enterPin },
          });
          if (response.ok) {
            if (response.data.is_valid) {
              handleOnValidPin(keyType[response.data.key_type], enterPin);
            } else {
              toast.warning(t("Incorrect PIN"));
              setPinEnterPin("");
            }
          } else {
            toast.warning(t("Incorrect PIN"));
            setPinEnterPin("");
          }
        } catch (err) {
          console.log(err);
          toast.warning(t("Something Went Wrong"));
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };
  const { branding, name, date } = eventDetails;
  return (
    <Modal
      className="modal-fullscreen"
      modalClassName="event-access-container gallery-cover gallery-modal"
      isOpen={true}
      backdrop={false}
      scrollable={true}
    >
      <ModalBody className="justify-content-center align-items-center">
        <div className="branding mb-3">
          <BrandLogo {...branding} className={"brand-sm"} />
        </div>

        <div className="event-details mb-4">
          <h4 className="event-name-access">{name}</h4>
          <h2 className="event-access-title">{t("Get Your Photos")}</h2>
        </div>
        {/* <div className="p-3">
          <FaceSearchForm
            eventId={eventDetails.id}
            eventData={eventDetails}
            passkey={enterPin}
          />
        </div> */}

        <div className="text-center">
          <div className="event-pin-label">{t("Event Pin")}</div>
          <Input
            type="password"
            pattern="[0-9]*"
            inputMode="numeric"
            className="event-pin-input"
            maxLength={4}
            min={0}
            max={9999}
            onChange={(e) => handleChangePin(e.target.value)}
            value={enterPin}
            placeholder=""
            disabled={isSubmitting}
          />
        </div>
        <div className="branding mt-4">
          {branding && (
            <>
              <small>Follow Us</small>
              <SocialNavColor brandingData={branding} iconSize={"15"} />
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EventAccess;

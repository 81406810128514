import { FaceDetection } from "@mediapipe/face_detection";
// import * as cam from "@mediapipe/camera_utils";
// import MyWebcam from "./Webcam";
import { useRef, useEffect, useState } from "react";
// import * as tfFaceDetection from "@tensorflow-models/face-detection";
import { Camera } from "react-camera-pro";
import face_scan from "assets/images/icons/face_scan.gif";
import classNames from "classnames";
import captureSelfieAnimation from "assets/images/animations/capture-selfie.json";
import captureSelfieDoneAnimation from "assets/images/animations/capture-selfie-done.json";
import loader from "assets/images/icons/loader.svg";
import Loader from "components/Loader";
import scanRight from "assets/images/animations/scan-right.json";
import AntiSpoofModel from "./isSpoof";

let width = window.innerWidth - 50;
let height = window.innerHeight;

const getAnimation = (
  startCapture,
  rightVerified,
  leftVerified,
  liveVerified,
  faceCentered
) => {
  return (
    <>
      <lottie-player
        src={JSON.stringify(captureSelfieDoneAnimation)}
        background="transparent"
        speed="1"
        style={{ zIndex: 300 }}
        // style={{ width: "auto", height: "305px" }}
        class={classNames(
          "animation position-absolute top-50 start-50 translate-middle h-100 w-100",
          {
            "d-none": !liveVerified,
          }
        )}
        // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
        loop
        autoplay
      ></lottie-player>

      <lottie-player
        src={JSON.stringify(captureSelfieAnimation)}
        background="transparent"
        speed="1"
        style={{ zIndex: 300 }}
        // style={{ width: "auto", height: "305px" }}
        class={classNames(
          "animation position-absolute top-50 start-50 translate-middle h-100 w-100",
          {
            "d-none": !faceCentered || liveVerified,
          }
        )}
        // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
        loop
        autoplay
      ></lottie-player>
      <lottie-player
        src={JSON.stringify(scanRight)}
        background="transparent"
        speed="1"
        style={{ zIndex: 300, width: "auto", height: "405px" }}
        class={classNames("animation position-absolute h-100 w-100", {
          "d-none":
            !startCapture || !faceCentered || rightVerified || liveVerified,
        })}
        // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
        loop
        autoplay
      ></lottie-player>
      <lottie-player
        src={JSON.stringify(scanRight)}
        background="transparent"
        speed="1"
        style={{
          zIndex: 300,
          width: "auto",
          height: "405px",
          transform: "scaleX(-1)",
        }}
        class={classNames("animation position-absolute h-100 w-100", {
          "d-none":
            !startCapture ||
            !faceCentered ||
            !rightVerified ||
            leftVerified ||
            liveVerified,
        })}
        // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
        loop
        autoplay
      ></lottie-player>
    </>
  );
};

export default function LiveSelfie({ onDone, onClose }) {
  if (width > 1000) {
    width = 640;
  }
  var frameCount = 0;
  var frontPose = false;
  var rightPose = false;
  var leftPose = false;
  var isCapture = false;

  const camera = useRef(null);
  const [capture, setCapture] = useState(false);
  const [liveliness, setLiveliness] = useState(false);
  const [frame, setFrame] = useState();
  const [result, setResult] = useState("");
  const [isFDReady, setIsFDReady] = useState(false);
  const [faceCentered, setFaceCentered] = useState(false);
  const [leftVerified, setLeftVerifield] = useState(false);
  const [rightVerified, setRightVerified] = useState(false);
  const [faceDetection, setFaceDetection] = useState(null);
  const faceDetectionRef = useRef();
  const [verifyingIsSpoof, setVerifingIsSpoof] = useState(false);

  const startCaptureRef = useRef(false);
  const rightRef = useRef();
  rightRef.current = rightVerified;
  const leftRef = useRef();
  leftRef.current = leftVerified;

  const stateRef = useRef();
  stateRef.current = capture;

  useEffect(() => {
    if (!faceCentered) {
      setLeftVerifield(false);
      setRightVerified(false);
      rightPose = false;
      leftPose = false;
    }
  }, [faceCentered]);

  const [frames, setFrames] = useState([]);
  const handleOnCapture = () => {
    setCapture(true);
    startCaptureRef.current = true;
    isCapture = true;
    const frame = camera.current.takePhoto();
    setFrame(frame);
    // setTimeout(() => handleOnStop(false), 20000);
  };

  const handleOnStop = () => {
    setRightVerified(false);
    setLeftVerifield(false);
    setLiveliness(false);
    setCapture(false);
    onClose();
  };
  const handleOnVerify = () => {
    // load antispoof model
    setVerifingIsSpoof(true);
  };

  const getCropCanvas = (sourceCanvas, left, top, width, height) => {
    let destCanvas = document.createElement("canvas");
    destCanvas.width = width;
    destCanvas.height = height;
    destCanvas.getContext("2d").drawImage(
      sourceCanvas,
      left,
      top,
      width,
      height, // source rect with content to crop
      0,
      0,
      width,
      height
    ); // newCanvas, same size as source rect
    return destCanvas;
  };

  useEffect(() => {
    const verify = async () => {
      if (verifyingIsSpoof) {
        faceDetectionRef.current.onResults((result) => {
          const bbox = result.detections[0].boundingBox;
          const imgCanvas = result.image;
          bbox.xCenter = bbox.xCenter * imgCanvas.width;
          bbox.yCenter = bbox.yCenter * imgCanvas.height;
          bbox.height = bbox.height * imgCanvas.height;
          bbox.width = bbox.width * imgCanvas.width;

          const left = bbox.xCenter - bbox.width / 2;
          const top = bbox.yCenter - bbox.height / 2;
          const croppedCanvas = getCropCanvas(
            imgCanvas,
            left,
            top,
            bbox.width,
            bbox.height
          );
          const d = croppedCanvas.toDataURL();
          console.log(d);
          new AntiSpoofModel((model) => {
            console.log(model);
            // model.isSpoof(frame);
            model.isSpoofCanvas(croppedCanvas);
            setRightVerified(false);
            setLeftVerifield(false);
            setLiveliness(true);
            setCapture(false);
            startCaptureRef.current = false;
          });
        });

        const image = new Image();
        image.src = frame;
        image.onload = () => {
          faceDetectionRef.current.send({ image: image });
        };
      }
    };
    verify();
  }, [verifyingIsSpoof]);

  function isFrontPose(right_eye, left_eye, nose, right_face, left_face) {
    // right-eye  left-eye nose should be insied right-face and left-face horizontally
    var xmin = right_face["x"];
    var xmax = left_face["x"];

    var checkLandmarks = [right_eye, left_eye, nose];
    var front = true;
    checkLandmarks.forEach((lm) => {
      if (xmin > lm["x"] || xmax < lm["x"]) {
        front = false;
      }
    });
    return front;
  }

  function isLeftPose(right_eye, left_eye, nose, right_face, left_face) {
    // right-eye  less from right-face
    var xmin = right_eye["x"];

    var checkLandmarks = [right_face];
    var right = true;
    checkLandmarks.forEach((lm) => {
      if (xmin * 0.95 > lm["x"]) {
        right = false;
      }
    });
    return right;
  }

  function isRightPose(right_eye, left_eye, nose, right_face, left_face) {
    // left-eye  max from left-face
    var xmax = left_eye["x"];

    var checkLandmarks = [left_face];
    var left = true;
    checkLandmarks.forEach((lm) => {
      if (xmax < lm["x"] * 0.95) {
        left = false;
      }
    });
    return left;
  }

  function checkLiveness(landmarks) {
    var right_eye = landmarks[0];
    var left_eye = landmarks[1];
    var nose = landmarks[2];
    var mouth = landmarks[3];
    var right_face = landmarks[4];
    var left_face = landmarks[5];

    // validate each pose
    if (!frontPose) {
      frontPose = isFrontPose(right_eye, left_eye, nose, right_face, left_face);
    }
    if (!leftRef.current) {
      leftPose = isLeftPose(right_eye, left_eye, nose, right_face, left_face);
      setLeftVerifield(leftPose);
    }
    if (!rightRef.current) {
      rightPose = isRightPose(right_eye, left_eye, nose, right_face, left_face);
      setRightVerified(rightPose);
    }

    console.log(
      `Front Pose check: ${frontPose} | right Pose check: ${rightRef.current} | left Pose check: ${leftRef.current}`
    );
    // setResult(
    //   `Front Pose check: ${frontPose} | right Pose check: ${rightPose} | left Pose check: ${leftPose}`
    // );
    setResult(`${JSON.stringify(right_eye)} : ${JSON.stringify(left_eye)}`);
    if (frontPose && leftPose && rightPose) {
      handleOnVerify();
    }
  }

  function onResults(stateRef, result) {
    // console.log("/////////////////////results////////////////////////");
    // console.log(result);
    setIsFDReady(true);
    var dets = result.detections;
    // frames.push(result.frames);
    // console.log(`capture : ${stateRef.current}`);
    if (dets.length > 0 && dets[0]) {
      const xCenter = dets[0].boundingBox.xCenter;
      const yCenter = dets[0].boundingBox.yCenter;
      // console.log(xCenter, yCenter);

      if (xCenter > 0.45 && xCenter < 0.6 && yCenter > 0.5 && yCenter < 0.65) {
        // console.log("face aligned");
        setFaceCentered(true);

        if (stateRef.current) {
          checkLiveness(dets[0].landmarks);
        }
      } else if (!startCaptureRef.current) {
        setFaceCentered(false);
        console.log("no face");
      }

      // console.log(dets[0].boundingBox);
    } else if (!startCaptureRef.current) {
      setFaceCentered(false);
      console.log("no face");
    } else {
      console.log("no face but capture already started");
    }
  }

  const handleOnRetake = () => {
    setFrames([]);
    setLiveliness(false);
    setLeftVerifield(false);
    setRightVerified(false);
    setFaceCentered(false);
    setCapture(false);
    setFrame();
    setIsFDReady(false);
  };

  const handleOnDone = () => {
    onDone(true, [frame]);
  };

  useEffect(() => {
    const faceDetection = new FaceDetection({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@0.4/${file}`;
        // return `https://assets.fotoowl.ai/assets/models/${file}`;
      },
    });
    faceDetection.setOptions({
      selfieMode: true,
      model: "short",
      minDetectionConfidence: 0.5,
    });

    faceDetection.onResults((result) => onResults(stateRef, result));
    setFaceDetection(faceDetection);
    faceDetectionRef.current = faceDetection;

    // return () => faceDetection.close();
  }, []);

  // init face detection
  useEffect(() => {
    if (faceDetection && camera.current) {
      const video = document.querySelector("#video");
      video.onloadeddata = async () => {
        console.log("init fd");
        await faceDetection.send({ image: video });
      };
    }
  }, [faceDetection, camera]);

  ///////////////////// start face detection
  const frameInterval = useRef();
  useEffect(() => {
    var interval;
    if (isFDReady) {
      if (camera.current) {
        const video = document.querySelector("#video");
        interval = setInterval(() => onFrame(camera, video), 200);
        frameInterval.current = interval;
      }
    }
    if (verifyingIsSpoof && frameInterval.current) {
      clearInterval(frameInterval.current);
    }
    return () => clearInterval(interval);
  }, [isFDReady, verifyingIsSpoof]);

  async function onFrame(webcamref, video) {
    // if (capture && webcamref.current) {

    if (true) {
      frameCount += 1;
      if (frameCount === 1) {
        const photo = webcamref.current.takePhoto();
        setFrames(frames.push(photo));
      }
      await faceDetection.send({ image: video });
      // const face = await tfFaceDetector.estimateFaces(
      //   webcamref.current.video
      // );
      // if (face.length > 0 && face[0]) checkLiveness(face[0].keypoints);

      // if (frameCount % 50 == 0) {
      //   await faceDetection.send({ image: video });
      //   // setFrames(frames.push(photo));
      // }
    }
  }
  const SVGIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 15 100 100">
      <defs>
        <mask id="image-mask">
          <rect width="100%" height="100%" fill="#fff" />
          {/* <circle id="outer" cx="50" cy="50" r="30" /> */}
          <ellipse cx="50" cy="50" rx="31" ry="34" />
          {/* <circle id="inner" cx="50" cy="50" r="25" /> */}
        </mask>
      </defs>
      <rect
        className="webcam-mask"
        x="0"
        y="0"
        width="100%"
        height="100%"
        mask="url(#image-mask)"
        fillOpacity="1"
        // fill="#ffff"
      />
    </svg>
  );
  console.log("face detection ready", isFDReady);
  return (
    <div className="d-flex flex-column">
      <div className="p-3 d-flex justify-content-center flex-column align-items-center position-relative">
        <h4>Capture Face</h4>

        <strong style={{ textAlign: "center" }} className="blink">
          Keep Face in the Center
        </strong>
        <div>and</div>
        <strong>Press Capture</strong>

        <div
          className="position-absolute end-0 top-0 p-3 fs-2"
          onClick={onClose}
        >
          <i className="mdi mdi-close" style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className="position-relative">
        <div className="overlay">{SVGIcon}</div>

        {isFDReady ? (
          getAnimation(
            capture,
            rightVerified,
            leftVerified,
            liveliness,
            faceCentered
          )
        ) : (
          <div
            style={{ zIndex: 300, backgroundColor: "0e0e0e33" }}
            className="animation position-absolute top-50 start-50 translate-middle h-100 w-100"
          >
            <Loader className="h-100 align-items-center" />
          </div>
        )}

        <div>
          {liveliness && frame ? (
            <img className="captured-frame" src={frame} />
          ) : (
            <Camera
              ref={camera}
              aspectRatio={4 / 3}
              facingMode={"user"}
              // numberOfCamerasCallback={handleNumberOfCameras}
              errorMessages={{
                noCameraAccessible: (
                  <div className="camera-access-error">
                    <div className="">
                      <img
                        width="48"
                        height="48"
                        src="https://img.icons8.com/fluency/48/general-warning-sign.png"
                        alt="general-warning-sign"
                      />

                      <div>Allow Camera Permissions and Reload the page</div>
                    </div>
                    <button className="btn btn-gallery-primary mt-3 py-1">
                      <a
                        href="https://youtube.com/shorts/4wv4YGPsxSM?feature=share"
                        target="_blank"
                      >
                        Steps to allow camera permission{" "}
                        <i className="mdi mdi-" />
                      </a>
                    </button>
                  </div>
                ),

                permissionDenied:
                  "Permission denied. Please refresh and give camera permission.",
                switchCamera:
                  "It is not possible to switch camera to different one because there is only one video device accessible.",
                canvas: "Canvas is not supported.",
              }}
            />
          )}
        </div>
      </div>

      <div className="d-flex gap-2 mt-5 px-2 me-2 pt-3 justify-content-end">
        {liveliness ? (
          <>
            <button className="btn btn-warning" onClick={handleOnStop}>
              <i className="mdi mdi-camera-retake  me-1"></i>
              Close
            </button>
            <button className="btn btn-success" onClick={handleOnDone}>
              <i className="mdi mdi-check-circle  me-1"></i>
              Done
            </button>
          </>
        ) : (
          <button
            disabled={capture || !isFDReady || !faceCentered}
            className="btn btn-gallery-primary"
            onClick={handleOnCapture}
          >
            <i className="mdi mdi-camera  me-2"></i>
            Capture
          </button>
        )}
      </div>
    </div>
  );
}

/**
 * @license
 * Copyright 2020 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import {KernelConfig, KernelFunc, TensorInfo, Transpose, TransposeAttrs, TransposeInputs, TypedArray} from '@tensorflow/tfjs-core';

import {MathBackendWebGL} from '../backend_webgl';

import {transposeImpl} from './Transpose_impl';
import {transposeImplCPU as cpuTranspose} from './Transpose_impl';

export function transpose(args: {
  inputs: TransposeInputs,
  attrs: TransposeAttrs,
  backend: MathBackendWebGL
}) {
  const {inputs, backend, attrs} = args;
  const {x} = inputs;
  const {perm} = attrs;
  const webglBackend = backend;

  const xRank = x.shape.length;

  const newShape: number[] = new Array(xRank);
  for (let i = 0; i < newShape.length; i++) {
    newShape[i] = x.shape[perm[i]];
  }

  let out: TensorInfo;
  if (webglBackend.shouldExecuteOnCPU([x])) {
    const xTexData = webglBackend.texData.get(x.dataId);
    const values = xTexData.values as TypedArray;
    const outValues = cpuTranspose(values, x.shape, x.dtype, perm, newShape);

    out = webglBackend.makeTensorInfo(newShape, x.dtype);
    const outData = webglBackend.texData.get(out.dataId);
    outData.values = outValues;
  } else {
    out = transposeImpl(x, perm, webglBackend);
  }
  return out;
}

export const transposeConfig: KernelConfig = {
  kernelName: Transpose,
  backendName: 'webgl',
  kernelFunc: transpose as unknown as KernelFunc
};

/**
 * @license
 * Copyright 2020 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the License);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an AS IS BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import {KernelConfig, Prelu, PreluInputs, TensorInfo, TypedArray} from '@tensorflow/tfjs-core';

import {MathBackendCPU} from '../backend_cpu';
import {assertNotComplex} from '../cpu_util';
import {createSimpleBinaryKernelImpl} from '../utils/binary_impl';

const preluImpl = createSimpleBinaryKernelImpl(
    (xValue: number, aValue: number) => xValue < 0 ? aValue * xValue : xValue);

export function prelu(args: {inputs: PreluInputs, backend: MathBackendCPU}):
    TensorInfo {
  const {inputs, backend} = args;
  const {x, alpha} = inputs;

  assertNotComplex([x, alpha], 'prelu');

  const aVals = backend.data.get(x.dataId).values as TypedArray;
  const bVals = backend.data.get(alpha.dataId).values as TypedArray;

  const [resultData, resultShape] =
      preluImpl(x.shape, alpha.shape, aVals, bVals, 'float32');

  return backend.makeTensorInfo(resultShape, 'float32', resultData);
}

export const preluConfig: KernelConfig = {
  kernelName: Prelu,
  backendName: 'cpu',
  kernelFunc: prelu,
};

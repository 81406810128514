import guestUploadImg from "assets/images/illustrations/guest-upload.svg";
import { Row, Col } from "reactstrap";
import no_photos from "assets/images/illustrations/not-found.svg";

const NoImages = ({ isGuestUpload }) => {
  return (
    <div>
      {isGuestUpload ? (
        <>
          <Row className="justify-content-center h-100">
            <Col md={6} sm={10}>
              <img src={guestUploadImg} className="img-fluid" />
            </Col>
          </Row>
          <Row>
            <h5 className="text-center">No Images Uploaded</h5>
            <small className="text-center">
              Upload images to show your perspective of the event
            </small>
          </Row>
        </>
      ) : (
        <>
          <Row className="justify-content-center">
            <Col md={3} sm={4}>
              <img src={no_photos} className="img-fluid" />
            </Col>
          </Row>
          <Row>
            <h5 className="text-center mt-4">No Images Found</h5>
          </Row>
        </>
      )}
    </div>
  );
};
export default NoImages;

import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
// import useDocumentTitle from "tracking/useTitle";
import notfound from "assets/images/illustrations/error-img.png";
const NotFound = () => {
  // useDocumentTitle("Foto Owl - 404 Not Found");

  return (
    <React.Fragment>
      <div className="page-content ht-80 d-flex align-items-center">
        <Container fluid className="">
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <img className="img-fluid" src={notfound} alt="404 - Not Found" />
            </Col>
          </Row>
          <div className="d-flex justify-content-center mt-3">
            <h2>Page Not Found</h2>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotFound;

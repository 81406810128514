import { useEffect, useState } from "react";
import { ModalBody, ModalHeader } from "reactstrap";
import swal from "sweetalert";

const useRequesMessage = (publishStatus) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const toggleIsOpen = () => {
    setIsOpen((p) => !p);
  };
  return { isOpen, handleOpen, toggleIsOpen };
};

const LiveEventRequestMessage = ({ isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      swal({
        title: "Registered",
        icon: "success",
        text: "You are successfully registered, your photos will appear on this page as soon as you get clicked",
      });
    }
  }, [isOpen]);
  return <></>;
};

export { useRequesMessage, LiveEventRequestMessage };

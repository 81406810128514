import { del, get, post, put, patch } from "./api_helper";
import * as url from "./jiraya_url";

export const getEventImages = (config) => get(url.GET_EVENT_IMAGES, config);

//// public apis
export const getRequestImages = (config) => get(url.GET_REQUEST_IMAGES, config);
export const postRequest = (data) => post(url.POST_REQUEST, data);
export const searchEvent = (config) => get(url.SEARCH_EVENT, config);
export const getPassKey = (config) => get(url.GET_PASS_KEY, config);
export const getOpenEvent = (config) => get(url.GET_EVENT_OPEN, config);
export const getOpenEventImages = (config) =>
  get(url.GET_EVENT_IMAGES_OPEN, config);
export const getOpenCollectionList = (config) =>
  get(url.GET_COLLECTION_LIST_OPEN, config);
export const getPinCheck = (config) => get(url.GET_PIN_CHECK, config);
export const getOpenPortfolio = (config) => get(url.GET_OPEN_PORTFOLIO, config);
export const getOpenPortfolioEventImages = (config) =>
  get(url.GET_OPEN_PORTFOLIO_EVENT_IMAGES, config);
export const downloadOpenImages = (config) =>
  get(url.GET_OPEN_DOWNLOAD_IMAGE, config);

export const startOpenDownloadJob = (config) =>
  get(url.GET_OPEN_START_DOWNLOAD_JOB, config);

export const getOpenDownloadJobStatus = (config) =>
  get(url.GET_OPEN_DOWNLOAD_JOB_STATUS, config);
export const getOpenImageLike = (config) =>
  get(url.GET_OPEN_IMAGE_LIKES, config);
export const getCommentImageList = (config) =>
  get(url.GET_OPEN_IMAGE_COMMENTS, config);
export const postOpenImageComment = (data) =>
  post(url.POST_OPEN_IMAGE_COMMENT, data);

export const getOpenImageCommentById = (config) =>
  get(url.GET_OPEN_COMMENT_BY_ID, config);

export const postOpenImageLike = (data) =>
  post(url.POST_OPEN_IMAGE_LIKES, data);

export const postOpenInquiry = (data) => post(url.POST_OPEN_INQUIRY, data);

export const postOpenActivity = (data) => post(url.POST_OPEN_ACTIVITY, data);

export const deleteOpenImageLike = (config) =>
  del(url.DELETE_OPEN_IMAGE_LIKES, config);
export const getOpenDownloadLinks = (config) =>
  get(url.GET_OPEN_DOWNLOAD_LINKS, config);
export const getOpenDownloadImageWithWM = (data) =>
  post(url.POST_OPEN_DOWNLOAD_IMAGE_WITH_WM, data);
export const getOpenUserLocation = (config) => get(url.GET_OPEN_USER_LOCATION);
export const getOpenPeople = (config) => get(url.GET_OPEN_PEOPLE, config);

// portfolio_event

export const getPortfolioEvent = (config) =>
  get(url.GET_PORTFOLIO_EVENT, config);

//for public gallery testing
export const patchCollectionToPortfolio = (data, config) =>
  patch(url.PATCH_COLLECTION_TO_PORTFOLIO, data, config);

export const getEvent = (config) => get(url.GET_EVENT_IMAGES_OPEN, config);

export const getCollectionList = (config) =>
  get(url.GET_COLLECTION_LIST, config);

export const getEventList = (config) => get(url.GET_EVENT_LIST, config);

export const getPublicGalleryEventList = (config) =>
  get(url.GET_PUBLIC_EVENT_GALLERY, config);

export const getPublicGalleryEventBranding = (config) =>
  get(url.GET_PUBLIC_EVENT_BRANDING, config);

export const getOpenSponsorsList = (config) =>
  get(url.GET_OPEN_SPONSORS_LIST, config);
export const getOrgEventList = (config) => get(url.GET_ORG_EVENT_LIST, config);

// guest user
export const patchGuestUserProfile = (data) =>
  patch(url.PATCH_GUEST_USER_PROFILE, data);
export const getUploadedImageList = (config) =>
  get(url.GET_UPLOADED_IMAGE_LIST, config);
export const deleteGuestUploadedImage = (data) =>
  del(url.DELETE_GUEST_UPLOADED_IMAGE, data);

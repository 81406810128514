import axios from "axios";
import * as urls from "./jiraya_url";
import ExifReader from "exifreader";

const MAX_REQUESTS_COUNT = 8;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

const UPLOAD_SERVER1 = process.env.REACT_APP_APIURL;
const UPLOAD_SERVER2 = process.env.REACT_APP_UPLOAD_SERVER2;

// create new axios instance
const api = axios.create({ baseURL: UPLOAD_SERVER1 });
const api2 = axios.create({ baseURL: UPLOAD_SERVER2 });

const servers = [api, api2];

const getUploadServer = () => {
  // const s = Math.round(Math.random());
  // return servers[s];
  return api;
};

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

api.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        // console.log("Add request to queue");
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});
/**
 * Axios Response Interceptor
 */
api.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    // console.log("solved pending request");
    return Promise.resolve(response);
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);
export default api;

const getHeightWidth = (file) =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let img = new Image();
      img.src = reader.result;
      img.onload = () => {
        resolve({ height: img.height, width: img.width });
      };
      img.onerror = () => {
        reject({ message: "failed to load image" });
      };
    };
  });

const getVideoData = (file) => {
  return new Promise((resolve, reject) => {
    var video = document.createElement("video");

    video.onloadedmetadata = () => {
      resolve({ height: video.videoHeight, width: video.videoWidth });
    };
    video.onerror = (e) => {
      // alert ("Error!");
      console.log(e);
      reject();
    };
    video.src = URL.createObjectURL(file);
  });
};

const getCreateDateTime = (tags) => {
  let datetime;
  if (tags.DateTimeOriginal) {
    datetime = tags.DateTimeOriginal.value[0];
  } else if (tags.DateTimeDigitized) {
    datetime = tags.DateTimeDigitized.value[0];
  } else if (tags.DateTime) {
    datetime = tags.DateTime.value[0];
  } else {
    datetime = "";
  }
  return datetime;
};
export const uploadWorker = async (uploadPayload) => {
  const file = uploadPayload.file;
  var height;
  var width;
  var fileData = {};
  var sha1 = "do_not_verify";
  var datetime;
  const size = file.size;
  var tags = {};
  if (file.type === "video/mp4") {
    var fileData = await getVideoData(file);
    sha1 = "do_not_verify";
    datetime = file.lastModifiedDate
      ? file.lastModifiedDate
          .toISOString()
          .replaceAll("-", ":")
          .replace("T", " ")
          .split(".")[0]
      : "";
  } else {
    try {
      tags = await ExifReader.load(uploadPayload.file);
      if (file.type === "image/heic") {
        height = tags["PixelYDimension"].value;
        width = tags["PixelXDimension"].value;
        // capture time
        datetime = getCreateDateTime(tags);
      } else if (file.type === "image/x-sony-arw") {
        height = tags["PixelYDimension"].value;
        width = tags["PixelXDimension"].value;
        // capture time
        datetime = getCreateDateTime(tags);
      } else {
        try {
          height = tags["Image Height"].value;
          width = tags["Image Width"].value;
          // capture time
          datetime = getCreateDateTime(tags);
        } catch (err) {
          height = tags["PixelYDimension"].value;
          width = tags["PixelXDimension"].value;
          // capture time
          datetime = getCreateDateTime(tags);
        }
      }
    } catch {
      var { height, width } = await getHeightWidth(file);
    }

    // fileData = await getFileData(file);
    const orientation = tags.Orientation ? tags.Orientation.value : 1;
    if ([0, 1, 2, 3, 4].includes(Number(orientation))) {
      // landscape
      fileData.height = height;
      fileData.width = width;
    } else {
      //portrait
      fileData.height = width;
      fileData.width = height;
    }

    // var source = fileData.binary;
    // sha1 = CryptoJS.SHA1(CryptoJS.enc.Latin1.parse(source)).toString();
  }

  var encodedPath = "";

  uploadPayload.path
    .split("/")
    .slice(1)
    .forEach(
      (component) => (encodedPath += `/${encodeURIComponent(component)}`)
    );
  const encodedFilename = encodeURIComponent(uploadPayload.filename);
  const bucketKey = uploadPayload.bucketKey + encodedPath;

  const headers = {
    "X-Bz-File-Name": bucketKey,
    "X-Bz-Content-Type": uploadPayload.type,
    "Content-Type": uploadPayload.type,
    "X-Bz-Content-Sha1": sha1,
    "FO-Event-Id": uploadPayload.eventId,
    "FO-Process-Id": uploadPayload.process_info_id,
    "FO-Image-Name": encodedFilename,
    "FO-Image-Path": encodedPath,
    "FO-User-Id": uploadPayload.userId,
    "FO-Image-Height": fileData.height,
    "FO-Image-Width": fileData.width,
    "FO-Callback": uploadPayload.callback,
    "FO-Is-Gallery": uploadPayload.isGallery,
    "FO-Date-Time": datetime,
    "FO-Collection-Ids": JSON.stringify(uploadPayload.collectionIds),
    "FO-Image-Size": size,
    "FO-Is-Guest-Upload": uploadPayload.isGuestUpload,
  };
  if (uploadPayload.collectionId)
    headers["FO-Collection-Id"] = uploadPayload.collectionId;
  return getUploadServer().post(urls.UPLOAD_WORKER_S3, file, {
    onUploadProgress: (e) => uploadPayload.onUploadProgress(e),
    headers: headers,
  });
};

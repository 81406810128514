import { AvField } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const StringField = ({
  label,
  errorMessage,
  placeholder,
  name,
  required,
  handleChange,
  handleRegisterName,
  additionalValues,
  className,
}) => {
  useEffect(() => {
    handleRegisterName(name, required, errorMessage);
  }, []);
  return (
    <FormGroup className="mb-3">
      <Label>
        {label} {required && "*"}
      </Label>
      <Input
        value={additionalValues[name]?.value || ""}
        name={name}
        placeholder={placeholder}
        type="text"
        onChange={(e) => handleChange(name, e.target.value)}
        // errorMessage={errorMessage}
        className={`form-control ${className}`}
        // validate={{ required: { value: errorMessage } }}
        id={name}
        invalid={additionalValues[name]?.isError}
      />
      <FormFeedback>{errorMessage}</FormFeedback>
    </FormGroup>
  );
};

const NumberField = ({
  options,
  label,
  errorMessage,
  placeholder,
  name,
  required,
  handleChange,
  handleRegisterName,
  additionalValues,
  className,
}) => {
  useEffect(() => {
    handleRegisterName(name, required, errorMessage);
  }, []);
  return (
    <FormGroup className="mb-3">
      <Label>
        {label} {required && "*"}
      </Label>
      <Input
        name={name}
        placeholder={placeholder}
        type="number"
        onChange={(e) => handleChange(name, e.target.value)}
        className={`form-control ${className}`}
        id={name}
        invalid={additionalValues[name]?.isError}
      />
      <FormFeedback>{errorMessage}</FormFeedback>
    </FormGroup>
  );
};

const DropDownField = ({
  options,
  label,
  errorMessage,
  placeholder,
  name,
  required,
  handleChange,
  handleRegisterName,
  additionalValues,
  className,
}) => {
  useEffect(() => {
    handleRegisterName(name, required, errorMessage, null);
  }, []);
  return (
    <FormGroup className="mb-3">
      {label} {required && "*"}
      <Input
        type="select"
        name={name}
        id={name}
        invalid={additionalValues[name]?.isError}
        onChange={(e) => handleChange(name, e.target.value)}
        className={`${className}`}
      >
        <option disabled selected value>
          -- select an option --
        </option>
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </Input>
      <FormFeedback>{errorMessage}</FormFeedback>
    </FormGroup>
  );
};

const useAdditionalFields = () => {
  const [additionalValues, setAdditionalValues] = useState({});
  const handleRegisterName = (name, required, errorMessage, defaultValue) => {
    setAdditionalValues((p) => {
      return {
        ...p,
        [name]: {
          value: defaultValue || "",
          required,
          errorMessage,
          isError: false,
        },
      };
    });
  };
  const handleChange = (name, value) => {
    setAdditionalValues((p) => {
      var field = p[name];
      field.value = value;
      field.isError = false;
      return { ...p, [name]: field };
    });
  };

  const clear = () => {
    Object.entries(additionalValues).forEach(([key, data]) => {
      const newData = { ...data };
      newData.value = "";
      newData.isError = false;
      additionalValues[key] = newData;
    });
    setAdditionalValues({ ...additionalValues });
  };

  const validate = () => {
    var isError = false;
    Object.entries(additionalValues).forEach(([key, data]) => {
      if (data.required && !data.value) {
        isError = true;
        data.isError = true;
      }
    });
    setAdditionalValues({ ...additionalValues });
    return !isError;
  };

  const toFormData = () => {
    let values = {};
    const entries = Object.entries(additionalValues);
    if (entries.length > 0) {
      entries.forEach(([key, data]) => {
        values[key] = data.value;
      });
      return JSON.stringify(values);
    } else {
      return null;
    }
  };
  return {
    additionalValues,
    handleChange,
    handleRegisterName,
    clear,
    validate,
    toFormData,
  };
};

const getField = (type) => {
  switch (type) {
    case "str":
      return StringField;
    case "int":
      return NumberField;
    case "single_choice":
      return DropDownField;
    default:
      return StringField;
  }
};

export default getField;
export { useAdditionalFields };

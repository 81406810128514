import { toast } from "react-toastify";

function copyUrl(id, successMessage) {
  /* Get the text field */
  var copyText = document.getElementById(id);

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  navigator.clipboard.writeText(copyText.value);

  if (successMessage) {
    toast(successMessage);
  }
}
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const dateToString = (date, locale = "en-us") => {
  const jsDate = new Date(date);
  const offset = new Date().getTimezoneOffset();
  jsDate.setMinutes(jsDate.getMinutes() + offset);
  return jsDate.toLocaleDateString(locale, {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const pastelColors = [
  "#F7A4A4",
  "#FEBE8C",
  "#FFFBC1",
  "#B6E2A1",
  "#FEE2B3",
  "#FFA299",
  "#AD6989",
  "#562349",
  "#876445",
  "#CA965C",
  "#EEC373",
  "#F4DFBA",
];
const add_https = (site) => {
  if (site !== "" && !site.includes("https://")) {
    site = "https://" + site;
  }
  return site;
};
const CFImageUrl = (imgUrl, height, width) => {
  if (imgUrl) {
    const { pathname, origin } = new URL(imgUrl);
    return `${origin}/cdn-cgi/image/height=${height}${pathname}`;
  }
};

function removeSlash(theString) {
  return theString.replace(/^\/|\/$/g, "");
}

export {
  copyUrl,
  formatBytes,
  randomIntFromInterval,
  dateToString,
  add_https,
  CFImageUrl,
  pastelColors,
  removeSlash,
};

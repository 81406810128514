import React, { useEffect, useReducer, useState } from "react";

export const UPLOAD_STATUS = {
  UPLOADED: 0,
  UPLOAD_ERROR: 1,
  IDLE: 2,
  UPLOADING: 3,
  UPLOAD_STOP: 4,
  UPLOAD_STOPPING: 5,
};

export const getFileUploadStatus = (uploadStatus) => {
  var fileUploadStatus;
  switch (Math.max(...uploadStatus)) {
    case 0:
      fileUploadStatus = UPLOAD_STATUS.UPLOADED;
      break;
    case 1:
      fileUploadStatus = UPLOAD_STATUS.UPLOAD_ERROR;
      break;
    case 2:
      fileUploadStatus = UPLOAD_STATUS.IDLE;
      break;
    case 3:
      fileUploadStatus = UPLOAD_STATUS.UPLOADING;
      break;
    case 4:
      if (
        uploadStatus.filter((i) => i === UPLOAD_STATUS.UPLOADING).length > 0
      ) {
        fileUploadStatus = UPLOAD_STATUS.UPLOAD_STOPPING;
      } else {
        fileUploadStatus = UPLOAD_STATUS.UPLOAD_STOP;
      }

      break;
    case 5:
      fileUploadStatus = UPLOAD_STATUS.UPLOAD_STOPPING;
      break;
    default:
      fileUploadStatus = UPLOAD_STATUS.IDLE;
  }
  return fileUploadStatus;
};

export const useUploadBucket = (api, listUploadPayload, key = "#") => {
  const [cancelUpload, setCancelUpload] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const [retry, setRetry] = useState(0);

  const MAX_BATCH_RETRY = 2;
  const uploadIntervalId = React.createRef();
  const initialState = {
    uploadBatchIndex: -1,
    userRetry: false,
    currentUpploadStatus: UPLOAD_STATUS.IDLE,
    files: {},
    notification: null,
    notificationCallback: null,
    uploadQueue: [],
    showUploadProgress: 0,
    duplicateResolving: false,
    isCheckingDuplicates: false,
    listUploadBatch: [
      {
        batchUploadStatus: UPLOAD_STATUS.IDLE,
        batchSize: 0,
        batchProgress: 0,
        progress: [],
        size: [],
        uploadStatus: [],
        error: [],
        data: [],
        retry: 0,
        listUploadPayload: [],
        index: [],
        eventId: null,
      },
    ],
    isStopped: false,
  };

  const stopUpload = () => {
    clearInterval(intervalId);
    setCancelUpload(true);
    // console.log(uploadIntervalId);
    // console.log(intervalId);
    dispatch({
      type: "UPLOAD_CANCEL",
      payload: { batchIndex: state.uploadBatchIndex },
    });
  };

  const [state, dispatch] = useReducer((state, action) => {
    var batchUploadStatus;
    var batch;
    var batchIndex = action.payload.batchIndex;

    const getNextBatch = (nextBatch, state) => {
      if (state.listUploadBatch[nextBatch])
        if (
          state.listUploadBatch[nextBatch].batchUploadStatus !==
          UPLOAD_STATUS.UPLOADED
        )
          return nextBatch;
        else return getNextBatch(nextBatch + 1, state);
      else return -1;
    };
    const runNextBatchOrRetrySameBatch = (state, batchUploadStatus) => {
      batch = state.listUploadBatch[batchIndex];
      if (batchUploadStatus === UPLOAD_STATUS.UPLOADED) {
        var nextBatch = getNextBatch(batchIndex + 1, state);
        return { nextBatch, retry: batch.retry };
      } else if (batchUploadStatus === UPLOAD_STATUS.UPLOAD_ERROR) {
        if (batch.retry < MAX_BATCH_RETRY) {
          setRetry((p) => p + 1);
          return { nextBatch: -1, retry: batch.retry + 1 };
        } else if (state.listUploadBatch.length - 1 > state.uploadBatchIndex) {
          var nextBatch = getNextBatch(batchIndex + 1, state);
          return { nextBatch, retry: MAX_BATCH_RETRY };
        } else {
          return { nextBatch: -1, retry: batch.retry };
        }
      } else {
        return { nextBatch: -1, retry: batch.retry };
      }
    };

    switch (action.type) {
      case "INIT_CHECKING_DUPLICATE": {
        return { ...state, isCheckingDuplicates: true };
      }

      case "INIT_UPLOADING": {
        console.log(`Init Upload batch num: ${batchIndex}`);
        console.log(
          `========= in Init ${action.payload.listUploadPayload.length}`
        );
        if (action.payload.listUploadPayload.length === 0) {
          state.files[batchIndex] = null;
          state.notification = null;
          state.showUploadProgress = 0;
          return { ...state };
        }
        state.duplicateResolving = false;
        state.showUploadProgress += 1;
        state.notification = null;
        var size = action.payload.listUploadPayload.map(
          (item) => item.file.size
        );
        const eventId = action.payload.listUploadPayload[0].eventId;
        var batchSize = size.reduce((partialSum, a) => partialSum + a, 0);
        batch = {
          progress: Array(action.payload.numFiles).fill(0),
          uploadStatus: Array(action.payload.numFiles).fill(UPLOAD_STATUS.IDLE),
          error: Array(action.payload.numFiles).fill(null),
          data: Array(action.payload.numFiles).fill({
            request: null,
            response: null,
          }),
          size,
          batchSize,
          batchProgress: 0,
          listUploadPayload: [...action.payload.listUploadPayload],
          batchUploadStatus: UPLOAD_STATUS.IDLE,
          retry: 0,
          index: Array.from(Array(action.payload.numFiles).keys()),
          eventId,
        };

        state.listUploadBatch[batchIndex] = batch;
        //no upload started , start batch 1
        if (state.uploadBatchIndex === -1) {
          state.uploadBatchIndex = 0;
        }
        // upload prev index already complete, upload next batch
        else if (
          state.listUploadBatch[state.uploadBatchIndex].batchUploadStatus ===
            UPLOAD_STATUS.UPLOADED ||
          state.listUploadBatch[state.uploadBatchIndex].batchUploadStatus ===
            UPLOAD_STATUS.UPLOAD_ERROR
        ) {
          state.uploadBatchIndex += 1;
        }
        return { ...state, listUploadBatch: [...state.listUploadBatch] };
      }
      case "UPLOADING":
        // console.log(`start Uploading batch index ${batchIndex}`);
        state.showUploadProgress += 1;
        batch = state.listUploadBatch[batchIndex];
        // console.log(`========= in uploading ${batch.listUploadPayload.length}`);

        batch.uploadStatus[action.payload.index] = UPLOAD_STATUS.UPLOADING;
        batch.data[action.payload.index] = action.payload.data;
        batchUploadStatus = getFileUploadStatus(batch.uploadStatus);
        batch = {
          ...batch,
          uploadStatus: batch.uploadStatus.slice(),
          data: batch.data.slice(),
          batchUploadStatus,
        };
        state.listUploadBatch[batchIndex] = batch;
        return {
          ...state,
          currentUpploadStatus: UPLOAD_STATUS.UPLOADING,
          listUploadBatch: [...state.listUploadBatch],
        };

      case "UPLOAD_PROGRESS":
        batch = state.listUploadBatch[batchIndex];

        batch.progress[action.payload.index] = action.payload.progress;
        var batchProgress = batch.progress.reduce(
          (partialSum, a) => partialSum + a,
          0
        );
        batch = { ...batch, progress: batch.progress.slice(), batchProgress };
        state.listUploadBatch[batchIndex] = batch;
        return { ...state, listUploadBatch: [...state.listUploadBatch] };

      case "UPLOADED":
        // console.log(`Upload done batch index ${batchIndex}`);
        batch = state.listUploadBatch[batchIndex];

        batch.uploadStatus[action.payload.index] = UPLOAD_STATUS.UPLOADED;
        batch.data[action.payload.index].response =
          action.payload.data.response;
        batchUploadStatus = getFileUploadStatus(batch.uploadStatus);
        batch.progress[action.payload.index] = batch.size[action.payload.index];
        var batchProgress = batch.progress.reduce(
          (partialSum, a) => partialSum + a,
          0
        );
        // if (batchUploadStatus === UPLOAD_STATUS.UPLOADED) {
        //   state.showUploadProgress = 0;
        // }
        batch = {
          ...batch,
          uploadStatus: batch.uploadStatus.slice(),
          data: batch.data.slice(),
          batchUploadStatus,
          batchProgress,
        };
        state.listUploadBatch[batchIndex] = batch;
        // var { nextBatch, retry } = runNextBatchOrRetrySameBatch(state, batchUploadStatus);
        // if (nextBatch !== -1) {
        //   state.uploadBatchIndex = nextBatch;
        // }
        return {
          ...state,
          currentUpploadStatus: batchUploadStatus,
          listUploadBatch: [...state.listUploadBatch],
        };

      case "UPLOAD_ERROR":
        console.log(`===============upload error -> batch index ${batchIndex}`);
        batch = state.listUploadBatch[batchIndex];

        batch.uploadStatus[action.payload.index] = UPLOAD_STATUS.UPLOAD_ERROR;
        batch.error[action.payload.index] = action.payload.error;
        batchUploadStatus = getFileUploadStatus(
          state.listUploadBatch[batchIndex].uploadStatus
        );
        batch.progress[action.payload.index] = 0;
        // var { nextBatch, retry } = runNextBatchOrRetrySameBatch(state, batchUploadStatus);
        // if (nextBatch !== -1) {
        //   console.log(`============next batch after error ${nextBatch}`)
        //   state.uploadBatchIndex = nextBatch;
        // }
        batch = {
          ...batch,
          uploadStatus: batch.uploadStatus.slice(),
          error: batch.error.slice(),
          batchUploadStatus,
          retry,
        };

        state.listUploadBatch[batchIndex] = batch;

        return {
          ...state,
          currentUpploadStatus: batchUploadStatus,
          listUploadBatch: [...state.listUploadBatch],
        };
      case "UPLOAD_CANCEL":
        for (let batchi = 0; batchi < state.listUploadBatch.length; batchi++) {
          batch = state.listUploadBatch[batchi];
          for (var i = 0; i < batch.uploadStatus.length; i++) {
            if (batch.uploadStatus[i] === UPLOAD_STATUS.IDLE) {
              batch.uploadStatus[i] = UPLOAD_STATUS.UPLOAD_STOP;
            }
          }
          batchUploadStatus = getFileUploadStatus(
            state.listUploadBatch[batchi].uploadStatus
          );
          batch = {
            ...batch,
            batchUploadStatus,
          };
          state.listUploadBatch[batchi] = batch;
        }
        return {
          ...state,
          currentUpploadStatus: batchUploadStatus,
          listUploadBatch: [...state.listUploadBatch],
        };

      case "RETRY_BATCH":
        setRetry((p) => (p += 1));
        return { ...state, uploadBatchIndex: batchIndex };

      case "UPLOAD_RETRY":
        var batch = state.listUploadBatch[batchIndex];
        batch.retry = action.payload.retry;
        setRetry((p) => (p += 1));
        return {
          ...state,
          uploadBatchIndex: batchIndex,
          listUploadBatch: [...state.listUploadBatch],
        };

      case "UPDATE_STATE":
        return {
          ...state,
          ...action.payload,
          batchUploadStatus,
        };

      case "UPLOAD_NEXT_BATCH":
        return { ...state, uploadBatchIndex: action.payload.batchIndex };

      case "RESET":
        return initialState;

      default:
        return state;
    }
  }, initialState);

  const retryBatch = (batchIndex) => {
    dispatch({
      type: "RETRY_BATCH",
      payload: { batchIndex },
    });
  };
  const resetUploads = () => {
    if (!state.duplicateResolving) {
      dispatch({
        type: "RESET",
        payload: { batchIndex: state.uploadBatchIndex },
      });
    }
  };
  const onUploadProgress = (index, event) => {
    // console.log(`onupload progress ${event.loaded}`);
    dispatch({
      type: "UPLOAD_PROGRESS",
      payload: {
        progress: 0.9 * event.loaded,
        index,
        batchIndex: state.uploadBatchIndex,
      },
    });
  };

  const CONCURRENT_UPLOADS = 12;
  var INPROGRESS = 0;
  let cancelRequest = false;

  const uploadData = async (uploadPayload, index) => {
    dispatch({
      type: "UPLOADING",
      payload: {
        index,
        data: { request: uploadPayload },
        batchIndex: state.uploadBatchIndex,
      },
    });
    try {
      // await new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve("done");
      //   }, 10000);
      // });

      api(uploadPayload)
        .then(async (response) => {
          if (cancelRequest) return;

          INPROGRESS -= 1;
          if (response.data.ok) {
            // console.log(`done upload ${index}`);
            dispatch({
              type: "UPLOADED",
              payload: {
                data: { response: response.data },
                index,
                batchIndex: state.uploadBatchIndex,
              },
            });
          } else {
            dispatch({
              type: "UPLOAD_ERROR",
              payload: {
                error: response.data.message,
                index,
                batchIndex: state.uploadBatchIndex,
              },
            });
          }
        })
        .catch((error) => {
          INPROGRESS -= 1;
          console.log(error);
          dispatch({
            type: "UPLOAD_ERROR",
            payload: {
              error: error.message,
              index,
              data: { request: uploadPayload },
              batchIndex: state.uploadBatchIndex,
            },
          });
        });
      // }
    } catch (error) {
      INPROGRESS -= 1;
      console.log(error);
      if (cancelRequest) return;
      dispatch({
        type: "UPLOAD_ERROR",
        payload: {
          error: error.message,
          index,
          data: { request: uploadPayload },
          batchIndex: state.uploadBatchIndex,
        },
      });
    }
  };

  const getNextBatch = (nextBatch, state) => {
    if (state.listUploadBatch[nextBatch])
      if (
        state.listUploadBatch[nextBatch].batchUploadStatus !==
        UPLOAD_STATUS.UPLOADED
      )
        return nextBatch;
      else return getNextBatch(nextBatch + 1, state);
    else return -1;
  };

  useEffect(() => {
    const uploadBatch = state.uploadQueue.pop();
    if (uploadBatch) {
      dispatch(uploadBatch);
    }
  }, [state.uploadQueue]);
  useEffect(() => {
    // console.log(
    //   `================> Current Upload Status: ${state.currentUpploadStatus}`
    // );
    const batch = state.listUploadBatch[state.uploadBatchIndex];
    if (state.currentUpploadStatus === UPLOAD_STATUS.UPLOADED) {
      //take next batch
      const nextBatch = getNextBatch(state.uploadBatchIndex + 1, state);
      if (nextBatch !== -1) {
        dispatch({
          type: "UPLOAD_NEXT_BATCH",
          payload: { batchIndex: state.uploadBatchIndex + 1 },
        });
      }
    } else if (state.currentUpploadStatus === UPLOAD_STATUS.UPLOAD_ERROR) {
      if (batch.retry < MAX_BATCH_RETRY) {
        // console.log(
        //   `============> Retry: ${state.uploadBatchIndex} : ${batch.retry + 1} `
        // );
        dispatch({
          type: "UPLOAD_RETRY",
          payload: {
            retry: batch.retry + 1,
            batchIndex: state.uploadBatchIndex,
          },
        });
      } else {
        const nextBatch = getNextBatch(state.uploadBatchIndex + 1, state);
        if (nextBatch !== -1) {
          dispatch({
            type: "UPLOAD_NEXT_BATCH",
            payload: { batchIndex: state.uploadBatchIndex + 1 },
          });
        }
      }
    }
  }, [state.currentUpploadStatus]);

  const handleNotification = () => {};

  ///current batch to upload
  useEffect(() => {
    if (state.uploadBatchIndex !== -1) {
      // console.log(
      //   `===============useeffect -> batch index ${state.uploadBatchIndex}`
      // );
      // const process_info_id = processId; //uuidv4();

      const listUploadStatus =
        state.listUploadBatch[state.uploadBatchIndex].uploadStatus;

      const _listUploadPayload =
        state.listUploadBatch[state.uploadBatchIndex].listUploadPayload;
      const _indexes = state.listUploadBatch[state.uploadBatchIndex].index;
      var listUploadPayload = [];

      listUploadStatus.forEach((status, idx) => {
        if (
          status === UPLOAD_STATUS.UPLOAD_ERROR ||
          status === UPLOAD_STATUS.IDLE
        ) {
          listUploadPayload.push([_listUploadPayload[idx], _indexes[idx]]);
        }
      });

      const TOTAL_UPLOADS = listUploadPayload.length - 1;
      console.log(`====Uploading files ${listUploadPayload.length}`);
      // var CURRENT_IDX = TOTAL_UPLOADS;
      let _intervalId = setInterval(() => {
        if (listUploadPayload.length === 0) {
          clearInterval(_intervalId);
        } else {
          if (INPROGRESS < CONCURRENT_UPLOADS) {
            let [uploadPayload, CURRENT_IDX] = listUploadPayload.pop();

            if (!uploadPayload) {
              clearInterval(_intervalId);
            } else {
              let index = CURRENT_IDX;
              // CURRENT_IDX -= 1;
              // uploadPayload["process_info_id"] = process_info_id;
              uploadPayload.onUploadProgress = (event) =>
                onUploadProgress(index, event);

              uploadData(uploadPayload, index);
              INPROGRESS += 1;
              // console.log(`start upload ${index}`);
            }
          }
        }
      }, 2);
      uploadIntervalId.current = _intervalId;
      setIntervalId(_intervalId);
    }
  }, [state.uploadBatchIndex, retry]);

  useEffect(() => {
    const fetchData = async () => {
      if (!key) return;
      var batchIndex =
        state.listUploadBatch.length === 1 && state.uploadBatchIndex === -1
          ? 0
          : state.listUploadBatch.length;
      dispatch({
        type: "INIT_UPLOADING",
        payload: {
          listUploadPayload: listUploadPayload,
          numFiles: listUploadPayload.length,
          batchIndex,
        },
      });

      return () => {
        cancelRequest = true;
      };
    };
    fetchData();
  }, [key]);

  return {
    ...state,
    retryBatch,
    resetUploads,
    stopUpload,
    handleNotification,
  };
};

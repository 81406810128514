import { useHistory } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { useState, useCallback, useMemo, useRef, useContext } from "react";

import classNames from "classnames";

import "react-contexify/ReactContexify.css";

import "yet-another-react-lightbox/plugins/captions.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import DownloadMessage from "../../Branding/download_message";

import CommentToolbar, { useComment } from "./comment";
import MobileLightboxToolbar from "./mobile_toolbar";
import PeopleTab from "./people_tab";
import GalleryContext from "store/galleryContext";
import useTracks from "components/Events/HashTrack/factory";

const LightBox = ({
  eventId,
  eventKey,
  eventData,
  frame,
  email,
  checkLogin,
  albumSelection,
  showLikeButton,
  index,
  handleSetIndex,
  photos,
  handleOnImageDownload,
  imageToolbarSubmitting,
  handleLoadMore,
  loadedImages,
  liked,
  handleOnLike,
  allowDownload,
  allowSingleDownload,
  downloadMessageProps,
  branding,
  allowPeopleTab,
  peopleTabProps,
}) => {
  const history = useHistory();
  const [downloadingIndex, setDownloadingIndex] = useState([]);
  const { trackRegistry, handleTrigger } = useTracks();

  const handleOnCloseImageView = () => {
    handleSetIndex(-1);
    history.push(`${history.location.pathname}${history.location.search}`);
  };
  const handleOnImageView = ({ index: currentIndex }) => {
    handleSetIndex(currentIndex);
    history.push(
      `${history.location.pathname}${history.location.search}#${currentIndex}`
    );
    if (handleLoadMore && currentIndex + 10 > loadedImages) {
      console.log(`loading more ${currentIndex}/${loadedImages} `);
      handleLoadMore();
    }
  };

  const handleImageDownload = async (e) => {
    // setImageToolbarSubmitting(true);
    if (!downloadingIndex.includes(index)) {
      const _downloadIndex = [...downloadingIndex, index];
      console.log(`Downloading ${index}`);
      setDownloadingIndex(_downloadIndex);
      // downloadMessageProps.handleOnDownloadMessage(photos[index]);
      handleTrigger("download", { photo: photos[index], eventData });
      await handleOnImageDownload(e, photos[index]);

      const idx = _downloadIndex.indexOf(index);
      console.log(`Downloaded ${index}`);
      if (idx !== -1) {
        _downloadIndex.splice(idx);
        setDownloadingIndex([..._downloadIndex]);
      }
    }
  };

  const galleryControls = useCallback(() => {
    return (
      <>
        {allowPeopleTab && photos[index] && (
          <PeopleTab
            eventId={eventId}
            photo={photos[index]}
            eventKey={eventKey}
            {...peopleTabProps}
          />
        )}
        {/* <DownloadMessage {...downloadMessageProps} /> */}
        {trackRegistry.map((item, key) => {
          const Component = item.Component;
          const isOpen = item.isOpen;
          const data = item.data;
          const props = item.props;
          return (
            <Component
              key={key}
              isOpenDownloadMessage={isOpen}
              {...data}
              {...props}
            />
          );
        })}

        <MobileLightboxToolbar
          index={index}
          downloadingIndex={downloadingIndex}
          handleImageDownload={handleImageDownload}
          albumSelection={albumSelection}
          allowSingleDownload={allowSingleDownload}
          branding={branding}
          liked={liked}
          photos={photos}
          handleOnLike={handleOnLike}
          eventId={eventId}
          eventKey={eventKey}
          emailId={email}
          checkLogin={checkLogin}
        />
      </>
    );
  }, [
    liked,
    handleOnLike,
    photos,
    downloadingIndex,
    downloadMessageProps,
    albumSelection,
    allowSingleDownload,
    email,
  ]);

  const toolbar = {
    buttons: [galleryControls(), "close"],
  };

  // const galleryRenderSlide = useCallback((slide, offset, rect) => {
  //   return <GalleryLightBoxSlide slide={slide} offset={offset} rect={rect} />;
  // }, []);

  var plugins = [Fullscreen, Slideshow, Zoom];
  // if (!albumSelection) {
  //   plugins.push(Captions);
  // }

  return (
    <>
      <Lightbox
        className="client-gallery-container"
        on={{ view: handleOnImageView, exited: handleOnCloseImageView }}
        slides={photos}
        open={index >= 0 && photos?.length > 0}
        index={index}
        close={() => handleSetIndex(-1)}
        toolbar={toolbar}
        render={{
          iconEnterFullscreen: () => <i className="mdi mdi-fullscreen top" />,
          iconExitFullscreen: () => (
            <i className="mdi mdi-fullscreen-exit top" />
          ),
          iconClose: () => <i className="mdi mdi-window-close top" />,
          iconSlideshowPause: () => <i className="mdi mdi-pause top" />,
          iconSlideshowPlay: () => <i className="mdi mdi-play top" />,
          iconZoomIn: () => <i className="mdi mdi-magnify-plus-outline top" />,
          iconZoomOut: () => (
            <i className="mdi mdi-magnify-minus-outline top" />
          ),
          // iconNext: () => (
          //   <i className="mdi mdi-arrow-right-drop-circle  navigation" />
          // ),
        }}
        // enable optional lightbox plugins

        plugins={plugins}
        animation={{ fade: 330, swipe: 200 }}
        controller={{ closeOnPullDown: true }}
      ></Lightbox>
    </>
  );
  // return myModule;
};

// const GalleryLightBoxSlide = ({ slide, offset, rect, eventId, eventKey }) => {
//   return (
//     <>
//       <div className="gallery-light-box-container">
//         <img
//           class="yarl__slide_image gallery-light-box-img"
//           draggable="false"
//           src={slide.src}
//         ></img>
//       </div>
//     </>
//   );
// };

export default LightBox;

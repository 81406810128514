export const basic = {
  style: {
    type: "basic",
    thumbnail:
      "https://assets.fotoowl.ai/cdn-cgi/image/width=540/assets/dashboard/gallery-themes/wedding-basic-1.jpg",
    designSettings: {
      v1: {
        galleryProps: {
          layout: "rows",
          radius: 5,
          sidePadding: "2.5",
        },
        gridStyle: {
          layout: {
            mobile: "rows",
            laptop: "rows",
          },
          spacing: {
            mobile: 5,
            laptop: 12,
          },
          radius: {
            mobile: 5,
            laptop: 5,
          },
          sidePadding: {
            mobile: 10,
            laptop: "2.5",
          },
          targetRowHeight: {
            mobile: 200,
            laptop: "410",
          },
          columns: {
            mobile: 4,
            laptop: 5,
          },
          orderBy: {
            name: "Name",
            value: 2,
          },
        },
        coverTheme: {
          theme: {
            name: "Center",
            value: "center",
          },
        },
        coverTextStyle: {
          "cover-event-font-scale": 0.85,
          "cover-event-font": "Send Flowers",
        },
        colorPaletteStyle: {
          socialIconColor: "White",
          "primary-color": "rgb(208, 2, 27)",
          "secondary-color": "rgb(74, 74, 74)",
          "background-color": "rgb(255, 255, 255)",
          "cover-font-color": "rgb(248, 247, 246)",
          "cover-other-font-color": "rgb(246, 239, 239)",
          "cover-overlay-color": "rgba(24, 24, 24, 0.35)",
          "navbar-background-color": "rgba(248, 248, 248, 0.4)",
          "navbar-color": "rgb(0, 0, 0)",
          "navbar-active-color": "rgb(208, 2, 27)",
          "footer-background-color": "rgba(85, 85, 85, 0.58)",
          "footer-color": "rgb(255, 247, 247)",
          "background-color-2": "rgb(242, 242, 242)",
          "background-color-3": "rgb(191, 191, 191)",
          "color-mode": "light",
          "primary-color-2": "rgb(157, 2, 20)",
          "primary-color-3": "rgb(157, 2, 20)",
          mode: "light",
          "primary-font-color": "rgb(255,255,255)",
          "primary-font-color-2": "rgb(255,255,255)",
          "primary-font-color-3": "rgb(255,255,255)",
          "font-color": "rgb(0,0,0)",
          "background-color-transparent-1": "rgba(255, 255, 255, 0.8)",
          "cover-social-icon-color": "Color",
          "background-color-transparent-2": "rgba(255, 255, 255, 0.5)",
        },
        brandingStyle: {
          cover: {
            logoSize: "5",
            showLogo: true,
            showSocial: true,
            showEventName: true,
            showInquiry: true,
          },
          footer: {
            showInquiry: true,
            showFooter: true,
            showLogo: true,
            showSocial: true,
            note: "Thank you for choosing",
            showContact: false,
            showName: false,
            logoSize: "8",
          },
        },
        viewMode: {
          mode: 0,
        },
      },
    },
  },
  name: "Basic - Light ",
  is_user_default: false,
  category: "wedding",
  is_global: false,
};

export const marathon2 = {
  style: {
    type: "marathon",
    thumbnail:
      "https://assets.fotoowl.ai/cdn-cgi/image/width=540/assets/dashboard/gallery-themes/marathon2.jpg?id=2",
    designSettings: {
      v1: {
        galleryProps: {
          layout: "rows",
          radius: 5,
          sidePadding: "2.5",
        },
        gridStyle: {
          layout: {
            mobile: "rows",
            laptop: "rows",
          },
          spacing: {
            mobile: 5,
            laptop: 12,
          },
          radius: {
            mobile: 5,
            laptop: 5,
          },
          sidePadding: {
            mobile: 10,
            laptop: "2.5",
          },
          targetRowHeight: {
            mobile: 200,
            laptop: "410",
          },
          columns: {
            mobile: 4,
            laptop: 5,
          },
          orderBy: {
            name: "Name",
            value: 2,
          },
        },
        coverTheme: {
          theme: {
            name: "Center",
            value: "center",
          },
        },
        coverTextStyle: {
          "cover-event-font-scale": 0.85,
          "cover-event-font": "Poppins",
        },
        colorPaletteStyle: {
          socialIconColor: "White",
          "primary-color": "rgb(2, 65, 141)",
          "secondary-color": "rgb(74, 74, 74)",
          "background-color": "rgb(251, 251, 251)",
          "cover-font-color": "rgb(255, 230, 230)",
          "cover-other-font-color": "rgb(255, 255, 255)",
          "cover-overlay-color": "rgba(241, 11, 11, 0)",
          "navbar-background-color": "rgba(225, 225, 225, 0.53)",
          "navbar-color": "rgb(70, 70, 70)",
          "navbar-active-color": "rgb(3, 105, 232)",
          "footer-background-color": "rgb(0, 0, 0)",
          "footer-color": "rgb(255, 255, 255)",
          "background-color-2": "rgb(238, 238, 238)",
          "background-color-3": "rgb(187, 187, 187)",
          "color-mode": "light",
          "primary-color-2": "rgb(1, 42, 91)",
          "primary-color-3": "rgb(1, 42, 91)",
          mode: "light",
          "primary-font-color": "rgb(255,255,255)",
          "primary-font-color-2": "rgb(255,255,255)",
          "primary-font-color-3": "rgb(255,255,255)",
          "font-color": "rgb(0,0,0)",
          "background-color-transparent-1": "rgba(251, 251, 251, 0.8)",
          "cover-social-icon-color": "White",
        },
        brandingStyle: {
          cover: {
            logoSize: "3",
            showLogo: false,
            showSocial: false,
            showEventName: true,
            showInquiry: true,
          },
          footer: {
            showInquiry: true,
            showFooter: true,
            showLogo: true,
            showSocial: true,
            note: "Thank you for choosing",
            showContact: false,
            showName: false,
            logoSize: "4",
          },
        },
        coverPosition: {
          laptop: {
            data: {
              x: 0,
              y: 0,
              width: 2672,
              height: 1669.9999999999995,
              scaleX: 1,
              scaleY: 1,
            },
            canvasData: {
              left: 0,
              top: 0,
              width: 1097.0473053892215,
              height: 523,
              naturalWidth: 3503,
              naturalHeight: 1670,
            },
            cropBoxdata: {
              left: 0,
              top: 0,
              width: 836.8,
              height: 522.9999999999999,
            },
            imageData: {
              naturalWidth: 3503,
              naturalHeight: 1670,
              aspectRatio: 2.0976047904191617,
              width: 1097.0473053892215,
              height: 523,
              left: 0,
              top: 0,
              scaleX: 1,
              scaleY: 1,
            },
          },
          mobile: {
            data: {
              x: 1280.6296226211073,
              y: 0,
              width: 835,
              height: 1670,
              scaleX: 1,
              scaleY: 1,
            },
            canvasData: {
              left: -443.23588136227545,
              top: 0,
              width: 1212.4155688622754,
              height: 578,
              naturalWidth: 3503,
              naturalHeight: 1670,
            },
            cropBoxdata: {
              left: 0,
              top: 0,
              width: 289,
              height: 578,
            },
            imageData: {
              naturalWidth: 3503,
              naturalHeight: 1670,
              aspectRatio: 2.0976047904191617,
              width: 1212.4155688622754,
              height: 578,
              left: 0,
              top: 0,
              scaleX: 1,
              scaleY: 1,
            },
          },
        },
        viewMode: {
          mode: 0,
        },
      },
    },
  },
};

import React, { useState } from "react";

import { socialMediaData } from "../Events/Client/Branding/utils";
// // import MetaTags from "react-meta-tags";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const PublicGalleryNavbar = ({ branding }) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <>
      <Navbar color="dark" dark className="public-gallery-nav">
        <div className="d-flex d-md-none ">
          <UncontrolledDropdown
            className="d-block d-md-none nav-toggler"
            // direction={window.innerWidth < 769 ? "left" : "left"}
          >
            <DropdownToggle
              style={{
                // width: "max-content",
                cursor: "pointer",
              }}
              tag="div"
            >
              <button className="btn nav-toggler">
                <i className="mdi mdi-menu fs-3"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu className="px-1 w-100" end>
              <div className="custom-menu-container">
                <DropdownItem className="w-100">
                  <div className="w-100">
                    <h2
                      className="my-3 ms-0 text-black w-100 "
                      style={{ whiteSpace: "initial" }}
                    >
                      {branding ? branding?.name : "Event Gallery"}
                    </h2>
                  </div>
                </DropdownItem>
                <hr />
                {/* <DropdownItem>
                  <a
                    href="/portfolio/gallery"
                    target="_blank"
                    style={{ cursor: "pointer" }}
                  >
                    <p className="text-black ms-4 fs-3">Portfolio</p>
                  </a>
                </DropdownItem> */}
                {/* <hr /> */}

                <div className="d-flex flex-wrap w-100 ms-3">
                  {branding && (
                    <div className="">
                      <div className="text-black fs-5">Follow Us</div>
                      <div>
                        {socialMediaData.map((item) => {
                          const href = branding[item.apiName];
                          if (href)
                            return (
                              <a
                                className="me-2"
                                href={href}
                                target="_blank"
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className="nav-social-logo py-2"
                                  src={item.icon}
                                  alt="website-logo"
                                  height={50}
                                />
                              </a>
                            );
                          else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  )}
                </div>

                {(branding?.contact_number || branding?.email_id) && (
                  <div className="ms-2">
                    <hr />
                    <div className="text-black fs-5">Contact</div>
                    <br />
                    <div className="text-black d-flex align-items-center mb-2">
                      <i className="mdi mdi-cellphone text-center contact-icon fs-4">
                        {" "}
                      </i>
                      <div className="fs-5 text-black ms-1 ">
                        {branding?.contact_number}
                      </div>
                    </div>
                    <div className="text-black fs-3 d-flex align-items-center mb-2">
                      <i className="mdi mdi-email text-center contact-icon fs-4"></i>
                      <div
                        className="fs-5 text-black ms-1"
                        style={{ overflowWrap: "anywhere" }}
                      >
                        {branding?.email_id}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>

          {branding && (
            <div className=" d-flex flex-column flex-md-row algin-items-cneter">
              <div className="d-none social-media-cont d-md-block py-2 px-2">
                {socialMediaData.map((item) => {
                  const href = branding[item.apiName];
                  if (href)
                    return (
                      <a
                        className="py-3"
                        href={href}
                        target="_blank"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="nav-social-logo py-2"
                          src={item.icon}
                          alt="website-logo"
                        />
                      </a>
                    );
                  else {
                    return null;
                  }
                })}
              </div>
            </div>
          )}
        </div>
        {branding?.img_url && (
          <NavbarBrand className="brandname">
            <img
              alt="logo"
              src={branding?.img_url}
              className="me-2"
              style={{
                height: 45,
                width: "auto",
              }}
            />
            <div className="brandname-title">{branding?.name}</div>
          </NavbarBrand>
        )}
        <Nav className="me-auto d-none d-md-block" navbar>
          {/* <NavItem>
            <NavLink href="/portfolio">Portfolio</NavLink>
          </NavItem> */}
        </Nav>

        {/* </Col> */}
      </Navbar>
    </>
  );
};

export default PublicGalleryNavbar;

import { getImageVersions } from "components/Events/utils";
import {
  getCloudflareUrl,
  getFrameUrl,
} from "../../../../../gallery_helpers/cloudflare_image";
import { ACTIVITY } from "../../enums";
import { useState, useMemo, useContext, useEffect } from "react";
import GalleryContext from "store/galleryContext";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const useLightBox = (imageData, eventData, eventId, filterPeople) => {
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    if (filterPeople) {
      setIndex(-1);
    }
  }, [filterPeople]);

  const { handleActivity } = useContext(GalleryContext);
  var fullSizeFrame;
  const frame = eventData?.frame;
  const photos = useMemo(() => {
    if (imageData) {
      var images = [];

      imageData.forEach((pageData) => {
        images.push(...pageData.data.image_list);
      });
      fullSizeFrame = imageData[0]?.data?.frame;

      const photos = images.map((img, idx) => {
        var originalHeight = Number(img.height);
        var originalWidth = Number(img.width);
        const imageVersions = getImageVersions(
          img,
          eventData?.branding,
          frame,
          fullSizeFrame
        );
        let visibleImageVersion;
        if (fullSizeFrame) {
          visibleImageVersion = imageVersions.med.fullSizeFrameData;
        } else if (frame) {
          visibleImageVersion = imageVersions.med.cf;
        } else {
          visibleImageVersion = imageVersions.med;
        }
        const srcSet = [];
        return {
          ...visibleImageVersion,
          imageVersions,
          originalHeight: originalHeight,
          originalWidth: originalWidth,
          srcSet: srcSet,
          ...img,
          title: !isMobile && img.name,
          description: isMobile && img.name,
        };
      });
      return photos;
    } else {
      return [];
    }
  }, [imageData]);

  const handleSetIndex = (index, noTrack) => {
    try {
      photos?.length > 0 &&
        index !== -1 &&
        !noTrack &&
        handleActivity(ACTIVITY.IMAGE_VIEW, photos[index].id);
    } catch (e) {
      console.log(e);
    }
    setIndex(index);
  };
  return { index, handleSetIndex, photos, frame };
};

export default useLightBox;

import DownloadLinks from "components/Events/Forms/download_links";
import { FotoowlTop } from "components/Fotoowl";
import { getDownloadJobStatus, startDownloadJob } from "helpers/jiraya_helper";
import { GET_DOWNLOAD_JOB_STATUS } from "helpers/jiraya_url";

const DownloadLinksPage = () => {
  return (
    <div>
      <FotoowlTop />

      <DownloadLinks />
    </div>
  );
};

export default DownloadLinksPage;

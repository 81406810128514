import React, { useCallback, useContext } from "react";
import { ACTIVITY } from "../enums";
import { add_https } from "helpers/utils";
import classNames from "classnames";
import GalleryContext from "store/galleryContext";
import { handleOpenLink, socialMediaData } from "./utils";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const getSocialIcons = (brandingData, mode, className, handleActivity) => {
  const brandingOptionsList = [];
  if (brandingData) {
    socialMediaData.forEach((sm) => {
      const brand = brandingData[sm.apiName];
      if (brand) {
        brandingOptionsList.push(
          <div
            key={sm.apiName}
            onClick={() =>
              handleOpenLink(brand, sm.host, sm.activity, handleActivity)
            }
            style={{ cursor: "pointer" }}
            className={className}
          >
            <img src={sm[mode]} height={22} />
          </div>
        );
      }
    });
    return brandingOptionsList;
  }
};

export const SocialNavMonochrome = ({
  brandingData,
  color,
  className,
  iconSize,
}) => {
  const { handleActivity } = useContext(GalleryContext);
  const getIcons = useCallback(
    () => getSocialIcons(brandingData, "iconWhiteFill", "", handleActivity),
    [brandingData, handleActivity]
  );

  return <div className="d-flex gap-3 gap-md-4">{getIcons()}</div>;
};

export const SocialNavMonochromeBlack = ({
  brandingData,
  color,
  className,
  iconSize,
}) => {
  const { handleActivity } = useContext(GalleryContext);
  const getIcons = useCallback(
    () => getSocialIcons(brandingData, "iconBlackFill", "", handleActivity),
    [brandingData, handleActivity]
  );

  return <div className="d-flex gap-2">{getIcons()}</div>;
};

export const SocialNavColor = ({
  brandingData,
  color,
  className,
  iconSize,
}) => {
  const { handleActivity } = useContext(GalleryContext);
  const getIcons = useCallback(
    () => getSocialIcons(brandingData, "icon", "icon-bg-white", handleActivity),
    [brandingData, handleActivity]
  );

  return (
    <div className={classNames("d-flex gap-2", className)}>{getIcons()}</div>
  );
};

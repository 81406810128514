import { Item, Menu, useContextMenu } from "react-contexify";
import { useLightboxState } from "yet-another-react-lightbox/core";
import { SocialNavColor } from "../../Branding/SocialNav";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import CommentToolbar, { useComment } from "./comment";
import { add_https } from "helpers/utils";
import { useSwipeable } from "react-swipeable";
import GalleryContext from "store/galleryContext";
import { ACTIVITY } from "components/Events/Client/enums";
import { brandingOptions, mainBranding } from "../../Branding/LightboxBranding";
import useScreenOrientation from "./useScreenOrientation";
import { useTranslation } from "react-i18next";

const MobileLightboxToolbar = ({
  index,
  downloadingIndex,
  handleImageDownload,
  albumSelection,
  allowSingleDownload,
  branding,
  photos,
  liked,
  handleOnLike,
  eventId,
  eventKey,
  emailId,
  checkLogin,
}) => {
  const { show } = useContextMenu();
  const { t } = useTranslation();
  // const { slides, currentSlide, currentIndex } = useLightboxState();

  const orientation = useScreenOrientation();
  // alert(orientation);
  const { handleActivity } = useContext(GalleryContext);
  const shareRef = useRef();
  const [openBrandingSlider, setOpenBrandingSlider] = useState(false);
  const toggleBrandingSlider = () => setOpenBrandingSlider((p) => !p);

  const getMainToolbarBranding = useCallback(
    (options, branding) => {
      mainBranding(options, branding, handleActivity, t);
    },
    [branding, handleActivity]
  );

  const getInfoOptions = useCallback(() => {
    return brandingOptions(branding, handleActivity, t);
  }, [handleActivity, branding]);

  const options = (hideBranding) => {
    const options = [];
    if (albumSelection) {
      options.push(
        <div className="gallery-light-box-menu-mobile-option" key="like">
          <i
            className={classNames(
              "mdi align-middle fs-2",
              photos[index] && liked.includes(photos[index].id)
                ? "mdi-heart liked"
                : "mdi-heart-outline"
            )}
            onClick={(e) => handleOnLike(e, photos[index])}
          />
          <div className="d-xl-none">{t("Like")}</div>
        </div>
      );
      options.push(
        <div
          key="comment"
          onClick={handleExpandComment}
          className={classNames("gallery-light-box-menu-mobile-option", {
            active: isExpanded,
          })}
        >
          <i className="mdi mdi-comment-outline" />
          <div className="d-xl-none">{t("Comment")}</div>
        </div>
      );
    } else {
      !hideBranding && getMainToolbarBranding(options, branding);
      options.push(
        <div
          key="branding"
          ref={shareRef}
          //   onClick={handleShare}
          onClick={toggleBrandingSlider}
          className={classNames("gallery-light-box-menu-mobile-option", {
            active: openBrandingSlider,
          })}
        >
          <i className="mdi mdi-information-outline" />
          <div className="d-xl-none">{t("Info")}</div>
        </div>
      );
    }

    if (allowSingleDownload) {
      options.push(
        <div
          key={"download"}
          className="gallery-light-box-menu-mobile-option"
          onClick={handleImageDownload}
        >
          {downloadingIndex.includes(index) ? (
            <i className="mdi mdi-loading mdi-spin fs-2" />
          ) : (
            <i className="mdi mdi-download" />
          )}
          <div className="d-xl-none">{t("Download")}</div>
        </div>
      );
    }

    return options;
  };

  const commentHandler = useSwipeable({
    onSwipedDown: () => {
      handleExpandComment();
    },
  });

  const infoHandler = useSwipeable({
    onSwipedDown: () => {
      toggleBrandingSlider();
    },
  });
  const { isExpanded, handleExpandComment } = useComment();
  return (
    <>
      <CommentToolbar
        handlers={commentHandler}
        eventId={eventId}
        eventKey={eventKey}
        emailId={emailId}
        checkLogin={checkLogin}
        isExpanded={isExpanded}
        slide={photos[index]}
        handleExpandComment={handleExpandComment}
      />
      <div
        {...infoHandler}
        className={classNames(
          "lighbox-slider branding-options lightbox-shadow",
          {
            expand: openBrandingSlider,
          }
        )}
      >
        <i
          onClick={toggleBrandingSlider}
          className="mdi mdi-chevron-double-down py-1 w-100 text-center d-lg-none"
        />

        {branding && (
          <>
            <div className="mb-3">
              <strong>
                <i className="mdi mdi-information" /> INFO{" "}
              </strong>
              <div>{photos[index]?.name}</div>
            </div>

            <div className=" branding-options-container">
              {getInfoOptions()}
            </div>
          </>
        )}
      </div>
      {orientation !== "mobile-landscape" && (
        <div className="gallery-light-box-menu-mobile icon-demo-content d-xl-none lightbox-shadow">
          {options()}
        </div>
      )}
      <div className="gallery-light-box-menu d-none d-xl-flex">
        {options(true)}
      </div>
    </>
  );
};

export default MobileLightboxToolbar;

import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const CutomNavbar = ({ pages, brand, noBrand }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  console.log(brand);
  var brandComponent = "BRAND";
  if (brand) {
    if (brand.img_url) {
      brandComponent = <img height={80} src={brand.img_url} />;
    } else {
      brandComponent = brand.name;
    }
  }
  return (
    <div
      className="position-absolute top-0 start-0 d-flex gap-4 w-100"
      style={{ zIndex: 1000 }}
    >
      <Navbar expand={"sm"}>
        {!noBrand && <NavbarBrand href="/">{brandComponent}</NavbarBrand>}
        {/* <NavbarToggler onClick={toggle} /> */}
        <div className="navbar-toggler" onClick={toggle}>
          <i className="mdi mdi-menu" />
        </div>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="d-flex gap-3" navbar>
            {pages.map((item, index) => (
              <NavItem key={index}>
                <Link to={`/${item.id}`}>{item.id}</Link>
                {/* <NavLink href="#">{item.id}</NavLink> */}
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};
CutomNavbar.defaultProps = {
  noBrand: false,
};
export default CutomNavbar;

export const ACTIVITY = {
  GALLERY_VISIT: 0,
  INSTA_VISIT: 1,
  WEBSITE_VISIT: 2,
  FB_VISIT: 3,
  SINGLE_IMAGE_DOWNLOAD: 4,
  IMAGE_VIEW: 5,
  YOUTUBE_VISIT: 6,
  COPY_HASHTAG: 7,
  LINKEDIN_VISIT: 8,
  WA_VISIT: 9,
  X_VISIT: 10,
  SNAP_VISIT: 11,
  TIKTOK_VISIT: 12,
  SHARE_LINKEDIN: 13,
  SHARE_FB: 14,
  SHARE_INSTA: 15,
  SPONSOR_VISIT: 16,
};

export const GALLERY_PRIVACY = {
  OPEN: 0,
  FACE_ONLY: 1,
};

export const DOWNLOAD_ZIP_STATUS = {
  PENDING: 0,
  STARTED: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const GALLERY_PREVIEW_MODE = {
  GALLERY_PREVIEW: 0,
  REGISTRATION_PREVIEW: 1,
  CLIENT: 2,
};

export const GALLERY_MODE = {
  GALLERY: 0,
  REGISTRATION: 1,
};

export const GALLERY_ACCESS_LEVEL = {
  FULL: 0,
  FACE_ONLY: 1,
};

export const ORDER_BY = { CLICK_TIME: 0, ID: 1, IMAGE_NAME: 2 };
export const COLLECTION_TYPE = { GENERAL: 4, PEOPLE: 5, HIGHLIGHT: 7, USER: 3 };
//test
//test2

const getAdditionalFields = (eventId, eventData) => {
  let additionalFields = [];
  if (eventId === "47101") {
    additionalFields = [
      {
        name: "company_name",
        label: "Company Name",
        type: "str",
        required: true,
        errorMessage: "Enter Company Name",
        placeholder: "Company Name",
      },
      {
        name: "city",
        label: "City",
        type: "str",
        required: true,
        errorMessage: "Enter Your City Name",
        placeholder: "City",
      },
    ];
  } else if (eventId === "44836") {
    additionalFields = [
      {
        name: "village_name",
        label: "गावाचे नाव",
        type: "str",
        required: true,
        errorMessage: "गावाचे नाव टाका",
        placeholder: "गावाचे नाव टाका",
      },
    ];
  } else if (eventId === "38965") {
    additionalFields = [
      {
        name: "team",
        label: "Who are you supporting?",
        type: "single_choice",
        required: true,
        errorMessage: "Select Team",
        options: [
          {
            value: "Chhatrapati Sambhaji Kings",
            label: "Chhatrapati Sambhaji Kings",
          },
          {
            value: "Eagle Nashik Titans",
            label: "Eagle Nashik Titans",
          },
          {
            value: "PBG Kolhapur Tuskers",
            label: "PBG Kolhapur Tuskers",
          },
          {
            value: "4S Puneri Bappa",
            label: "4S Puneri Bappa",
          },

          {
            value: "Ratnagiri Jets",
            label: "Ratnagiri Jets",
          },
          {
            value: "Raigad Royals",
            label: "Raigad Royals",
          },
        ],
      },
    ];
  } else if (eventId === "38769") {
    additionalFields = [
      {
        name: "team",
        label: "Select Team",
        type: "single_choice",
        required: true,
        errorMessage: "Select Team",
        options: [
          {
            value: "Chhatrapati Sambhaji Kings",
            label: "Chhatrapati Sambhaji Kings",
          },
          {
            value: "Eagle Nashik Titans",
            label: "Eagle Nashik Titans",
          },
          {
            value: "PBG Kolhapur Tuskers",
            label: "PBG Kolhapur Tuskers",
          },
          {
            value: "4S Puneri Bappa",
            label: "4S Puneri Bappa",
          },

          {
            value: "Ratnagiri Jets",
            label: "Ratnagiri Jets",
          },
          {
            value: "Raigad Royals",
            label: "Raigad Royals",
          },
          {
            value: "Other",
            label: "Other",
          },
        ],
      },
    ];
  } else if (eventId === "38081") {
    additionalFields = [
      {
        name: "batch",
        label: "Select Batch",
        type: "single_choice",
        required: true,
        errorMessage: "Select Batch",
        options: [
          {
            value: "6-7",
            label: "6PM - 7PM",
          },
          {
            value: "7-8",
            label: "7PM - 8PM",
          },
        ],
      },
    ];
  } else if (
    eventId === "12408" ||
    eventId === "10209" ||
    eventId === "11729"
  ) {
    additionalFields = [
      {
        name: "company_name",
        label: "Enter your Company Name",
        type: "str",
        required: true,
        errorMessage: "Enter Company Name",
      },
    ];
  } else if (eventId === "30198") {
    additionalFields = [
      {
        name: "company_name",
        label: "Select your Company / Team Name",
        type: "single_choice",
        options: [
          { value: "Amazon", label: "Amazon" },
          { value: "Freo", label: "Freo" },
          { value: "KaptureCX", label: "KaptureCX" },
          { value: "Samsung", label: "Samsung" },
          { value: "SportsCentric", label: "SportsCentric" },
        ],
        required: true,
        errorMessage: "Select Company Name",
      },
    ];
  } else if (eventId === "28277") {
    additionalFields = [
      {
        name: "company_name",
        label: "Select your Company / Team Name",
        type: "single_choice",
        options: [
          { value: "Adobe", label: "Adobe" },
          { value: "AB InBev", label: "AB InBev" },
          { value: "Bosch", label: "Bosch" },
          { value: "Oracle", label: "Oracle" },
          { value: "Mu Sigma", label: "Mu Sigma" },
          { value: "Northern Trust", label: "Northern Trust" },
          { value: "SportsCentric", label: "SportsCentric" },
          { value: "Swiss RE Global", label: "Swiss RE Global" },
        ],
        required: true,
        errorMessage: "Select Company Name",
      },
    ];
  }

  switch (eventData?.creator_user_id) {
    case "e417m2vo30fARCLLIrg43srGcsu1":
      additionalFields = [
        {
          name: "relation_with_sany",
          label: "Your relationship with Sany India",
          type: "single_choice",
          options: [
            { value: "Employee", label: "Employee" },
            { value: "Dealer/Partner", label: "Dealer/Partner" },
            { value: "Customer", label: "Customer" },
            { value: "Visitor/Guest", label: "Visitor/Guest" },
          ],
          required: true,
          errorMessage: "Select Relation",
        },
      ];
  }
  return additionalFields;
};

export default getAdditionalFields;

import { useState, useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import GalleryContent from "./gallery_content";
import useSWRInfinite from "swr/infinite";
import { useHistory } from "react-router-dom";
import loader from "assets/images/icons/loader.svg";
import LightBox, { useLightBox } from "./lightbox";
import useSWR from "swr";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import no_photos from "assets/images/illustrations/not-found.svg";
import { useDownloadMessage } from "../Branding/download_message";
import getFrameData from "./frames_data";
import {
  COLLECTION_TYPE,
  GALLERY_ACCESS_LEVEL,
  GALLERY_PRIVACY,
} from "../enums";
import { LiveEventRequestMessage, useRequesMessage } from "./request_message";
import { usePeopleTab } from "./lightbox/people_tab";
import Error from "components/Error";
import { useTranslation } from "react-i18next";
import NoImages from "./no_images";
import useSelection from "./helperHooks/useSelection";
import { MyUploadToolbar } from "./my_uploads";
import { deleteGuestUploadedImage } from "helpers/jiraya_helper";
import swal from "sweetalert";
import { toast } from "react-toastify";

const ALL_COLLECTION = -1;

const NoPhotosFound = ({}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column align-items-center">
      <img
        className="px-1 px-md-5 "
        src={no_photos}
        height={150}
        style={{ maxWidth: "80vw" }}
      />
      <div className="helper-message ">
        <div className="mt-2 helper-resolution">
          <h5 className="text-center">
            <i className="mdi mdi-information-outline me-2" />
            {t("You will receive a notification when your photos are uploaded")}
          </h5>
          {/* <ul className="">
            <li>
              You will receive a notification when your photos are uploaded
            </li>
          </ul> */}
          <div className="text-center mt-3">
            <strong className="my-3">
              {t("If you think your photos are already uploaded")}
            </strong>
            <br />
            <strong> {t("Try to do following things")}</strong>
          </div>

          <ul>
            <li>{t("Click a selfie in better lighting condition")}</li>
            <li>
              {t(
                "Make sure your complete face is within the cirlce when you click selfie"
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const CollectionGallery = ({
  eventId,
  eventData,
  collection,
  collectionId,
  collectionData,
  eventKey,
  eventKeyType,
  showAllImages,
  getImageParams,
  galleryParams,
  galleryHandlers,
  userData,
  allowLoadMore,
  allowPeopleTab,
  peopleTabProps,
  isGuestUpload,
}) => {
  const { t } = useTranslation();
  const searchParam = new URLSearchParams(window.location.search);
  const inspectKey = searchParam.get("inspect_key");
  const direct = eventData.indexing_type === 1;

  const {
    fetcherUrl,
    fetcher,
    request_id,
    request_key,
    orderBy,
    orderAsc,
    access_key,
    team_view,
  } = getImageParams;
  const {
    showLikeButton,
    liked,
    albumSelection,
    galleryProps,
    allow_guest_download,
    allow_guest_single_download,
    imageToolbarSubmitting,
    checkFreePlanLimit,
    checkImageLocked,
  } = galleryParams;
  const {
    handleOnLike,
    handleOnImageDownload,
    handleOnOpenFaceSearch,
    handleShowAll,
  } = galleryHandlers;
  const { email, checkLogin } = userData;
  const history = useHistory();
  const page_size = 40;

  const getFullCollection = useCallback(
    (id) => {
      const collection = collectionData.filter((c) => c.id === id);
      if (collection) {
        return collection[0];
      } else {
        console.error(`collection ${id} not  found`);
      }
    },
    [collection]
  );

  const imageDataSWRConfig =
    eventData.publish_status === 3
      ? {
          refreshInterval: 30000,
          // revalidateOnFocus: true,
        }
      : {
          revalidateFirstPage: false,
        };
  const getKey = (pageIndex, previousPageData) => {
    if (collection !== collectionId) return null;
    if (previousPageData && !previousPageData.data.image_list.length)
      return null;

    var send_request_id = request_id;
    if (
      (eventKeyType === GALLERY_ACCESS_LEVEL.FULL &&
        collection === ALL_COLLECTION) ||
      showAllImages
    ) {
      send_request_id = null;
    }
    const fullCollection = getFullCollection(collectionId);

    const key = [
      fetcherUrl,
      eventId,
      pageIndex,
      fullCollection.type === COLLECTION_TYPE.PEOPLE ? null : collection,
      page_size,
      eventKey,
      send_request_id,
      request_key,
      allowPeopleTab ? peopleTabProps?.filterPeople : null,
      access_key,
      team_view,
    ];
    return key;
  };
  const {
    data: imageData,
    error: imageError,
    isLoading: isImageDataLoading,
    isValidating,
    mutate: imageDataMutate,
    size: imageDataSize,
    setSize: setImageDataSize,
  } = useSWRInfinite(
    getKey,
    (
      url,
      eventId,
      page,
      collection,
      page_size,
      eventKey,
      request_id,
      request_key,
      uuid_num_list_str,
      access_key,
      team_view
    ) => {
      // console.log(`========> fetching page: ${page}`);
      // const { offset, subtract } = getOffset(page)
      const params = {
        event_id: eventId,
        collection_id:
          Number(collection) === ALL_COLLECTION ? null : collection,
        page: page,
        page_size,
        key: eventKey,
        request_id,
        request_key,
        order_by: orderBy,
        order_asc: orderAsc,
        inspect_key: inspectKey,
        uuid_num_list_str,
        direct,
        access_key,
        team_view,
      };
      return fetcher({
        params,
      });
    },
    imageDataSWRConfig
  );

  useEffect(() => {
    imageDataMutate();
  }, [orderBy, orderAsc]);

  const isFirstImageLoad = !imageError && !imageData;
  const isLoadingMore =
    isImageDataLoading ||
    (imageDataSize > 0 &&
      imageData &&
      typeof imageData[imageDataSize - 1] === "undefined");
  const isEmpty =
    !imageError &&
    imageData?.[0]?.ok &&
    imageData?.[0]?.data.image_list.length === 0;
  const isReachingEnd =
    !allowLoadMore ||
    isEmpty ||
    (imageData &&
      imageData[0]?.ok &&
      imageData[imageData.length - 1]?.data.image_list.length < page_size);

  const isRefreshing =
    isValidating && imageData && imageData.length === imageDataSize;
  var totalImages = 0;
  imageData &&
    imageData[0]?.ok &&
    imageData?.forEach((element) => {
      totalImages += element.data.image_list.length;
    });

  // hardcode frame for event id 210
  if (imageData && imageData[0]?.data) {
    imageData[0].data.frame = getFrameData(eventId, request_id);
  }

  // video data =
  const getVideoKey = () => {
    if (albumSelection) return null;
    const key = [
      fetcherUrl,
      eventId,
      0,
      collection,
      10,
      albumSelection,
      true,
      access_key,
    ];

    return key;
  };
  const {
    data: videoData,
    error: videoError,
    isLoading: isVideoLoading,
    isValidating: isVideoDataValidating,
    mutate: videoDataMutate,
  } = useSWR(
    getVideoKey,
    (
      url,
      eventId,
      page,
      collection,
      page_size,
      get_like,
      get_video,
      access_key
    ) => {
      // console.log(`========> fetching page: ${page}`);
      // const { offset, subtract } = getOffset(page)
      const params = {
        event_id: eventId,
        collection_id: Number(collection) === -1 ? null : collection,
        page: page,
        page_size: 100,
        key: eventKey,
        request_id,
        request_key,
        get_like,
        get_video,
        offset: null,
        minus_count: null,
        access_key,
      };
      return fetcher({
        params,
      });
    },
    { revalidateFirstPage: false }
  );
  // const isVideoEmpty = videoData?.data.image_list.length === 0;
  /////
  const { index, handleSetIndex, photos } = useLightBox(
    imageData && imageData[0]?.ok ? imageData : [],
    eventData,
    eventId,
    peopleTabProps.filterPeople
  );

  //////

  //////// //// //// //// //// //// Load more
  const handleLoadMore = () => {
    if (!isLoadingMore && allowLoadMore) {
      setImageDataSize(imageDataSize + 1);
    }
  };

  useEffect(() => {
    history.listen((location, action) => {
      console.log(action, location.pathname, location.hash);
      if (action === "POP") {
        handleSetIndex(-1);
        history.push(`${history.location.pathname}${history.location.search}`);
      }
    });
    // const imageIndex =
    //   window.location.hash && window.location.hash.split("#")[1];
    // imageIndex && handleSetIndex(Number(imageIndex));
  }, []);

  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const requestMessageProps = useRequesMessage();
  useEffect(() => {
    if (
      getFullCollection(collection)?.type === COLLECTION_TYPE.PEOPLE &&
      imageData &&
      videoData?.ok &&
      imageData[0]?.ok &&
      imageData[0]?.data?.image_list.length === 0 &&
      videoData?.data?.image_list.length === 0
    ) {
      if (eventData.publish_status === 3) {
        requestMessageProps.handleOpen(true);
      } else {
        setIsOpenMessageModal(true);
      }
    }
  }, [imageData, videoData]);

  // useEffect(()=>{
  //   const url =

  // },[])
  const downloadMessageProps = useDownloadMessage();
  const handleOnDownloadMessage = (photo) => {
    downloadMessageProps.handleSetDownloadImageUrl(photo);
    downloadMessageProps.handleOnOpenDownloadMessage();
  };

  /////// selection of photos
  const {
    selectedImages,
    isAllImagesSelected,
    handleOnImageSelection,
    toggleAllImageSelection,
    clearImageSelection,
    selectedImagePageMapping,
    setSelectedImagePageMapping,
  } = useSelection({
    imageData,
    totalImages,
  });
  const [markDeleting, setMarkdeleting] = useState([]);
  const acceptDeleteImages = async (photoIds) => {
    // setLoadingApi(true);
    setMarkdeleting([...markDeleting, ...photoIds]);
    try {
      const stringPhotoIds = photoIds.map((x) => x.toString());
      const response = await deleteGuestUploadedImage({
        data: {
          image_id_list: stringPhotoIds,
          event_id: eventId,
        },
      });

      // setLoadingApi(false);

      if (response.ok) {
        clearImageSelection();
        // reload();
        imageDataMutate();
        // videoDataMutate();
      } else {
        toast(response.message);
      }
    } catch (err) {
      toast.error("Failed to delete image, try again later");
    }
  };
  const handleOnImageDelete = (photoId, page) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this image file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (selectedImages.length > 0) {
          acceptDeleteImages(selectedImages, selectedImagePageMapping);
        } else if (photoId) {
          setSelectedImagePageMapping({ [page]: 1 });
          acceptDeleteImages([photoId], { [page]: 1 });
        }
      }
    });
  };

  return (
    <>
      {isGuestUpload && (
        <MyUploadToolbar
          selectedImages={selectedImages}
          isAllImagesSelected={isAllImagesSelected}
          toggleAllImageSelection={toggleAllImageSelection}
          handleOnImageDelete={handleOnImageDelete}
        />
      )}
      <LiveEventRequestMessage {...requestMessageProps} />
      {!videoError && videoData && videoData?.ok && (
        <div className="mb-1 mb-md-3">
          <GalleryContent
            eventId={eventId}
            images={videoData.data.image_list}
            page={0}
            {...galleryProps}
            onDownload={handleOnImageDownload}
            allowDownload={false}
            liked={liked}
            onLike={handleOnLike}
            // onComment={handleOnComment}
            onClick={(index) => {
              // console.log(index);
              // console.log(index + page * page_size);
              // handleSetIndex(index + page * page_size);
            }}
            imageToolbarSubmitting={imageToolbarSubmitting}
            showLikeButton={false}
            albumSelection={false}
            checkImageLocked={(args) => false}
          />
        </div>
      )}

      <Modal
        isOpen={isOpenMessageModal}
        centered
        toggle={() => setIsOpenMessageModal(!isOpenMessageModal)}
        size="md"
      >
        <ModalHeader className="align-items-center position-relative">
          <div
            className="position-absolute top-0 end-0"
            onClick={() => setIsOpenMessageModal(false)}
          >
            <i className="mdi mdi-close me-2 fs-2" />
          </div>
          <i className="mdi mdi-information-outline me-2 fs-2 align-middle" />
          {t("No Photos Found")}
        </ModalHeader>
        <ModalBody>
          <NoPhotosFound />
          <button
            onClick={() => {
              setIsOpenMessageModal(false);
              handleOnOpenFaceSearch();
            }}
            className="btn btn-success mt-3 w-100"
          >
            {t("Try Again")}
          </button>
          {eventKeyType === GALLERY_ACCESS_LEVEL.FULL && (
            <button
              onClick={() => {
                setIsOpenMessageModal(false);
                handleShowAll();
              }}
              className="btn btn-primary mt-3 w-100"
            >
              {t("Check All photos")}
            </button>
          )}
        </ModalBody>
        {/* <ModalFooter>


        </ModalFooter> */}
      </Modal>
      {imageData && imageData[0].data.image_list.length === 0 && (
        <NoImages isGuestUpload={isGuestUpload} />
      )}

      {isFirstImageLoad || (!imageError && imageData && imageData[0]?.ok) ? (
        <>
          <InfiniteScroll
            dataLength={imageData ? imageData.length : 0}
            next={handleLoadMore}
            hasMore={!isReachingEnd}
            scrollThreshold={0.5}
            loader={
              <div className="mt-2 w-100 d-flex justify-content-center">
                <img src={loader} />
              </div>
            }
          >
            {imageData &&
              imageData.map((pageImages, page) => (
                <div className="mb-1 mb-md-3" key={page}>
                  <GalleryContent
                    key={page}
                    eventId={eventId}
                    eventData={eventData}
                    images={pageImages.data.image_list}
                    frame={eventData.frame}
                    page={page}
                    keyPrefix={`${collectionId}-${page}`}
                    {...galleryProps}
                    onDownload={handleOnImageDownload}
                    allowDownload={allow_guest_download}
                    allowSingleDownload={allow_guest_single_download}
                    liked={liked}
                    onLike={handleOnLike}
                    // onComment={handleOnComment}
                    onClick={(index) => {
                      if (checkFreePlanLimit(page, index)) {
                        return;
                      } else {
                        const fIndex = index + page * page_size;
                        handleSetIndex(fIndex, true);
                      }
                    }}
                    imageToolbarSubmitting={imageToolbarSubmitting}
                    showLikeButton={showLikeButton}
                    albumSelection={albumSelection}
                    downloadMessageProps={{
                      ...downloadMessageProps,
                      handleOnDownloadMessage,
                      ...eventData.download_prompt,
                      branding: eventData.branding,
                    }}
                    checkImageLocked={checkImageLocked}
                    isGuestUpload={isGuestUpload}
                    selectedImages={selectedImages}
                    handleOnImageSelection={handleOnImageSelection}
                  />
                </div>
              ))}
          </InfiniteScroll>
          <LightBox
            {...{
              eventId,
              eventKey,
              eventData,
              email,
              checkLogin,
              albumSelection,
              showLikeButton,
              index,
              handleSetIndex,
              photos,
              handleOnImageDownload,
              imageToolbarSubmitting,
              handleLoadMore,
              loadedImages: totalImages,
              liked,
              handleOnLike,
              allowDownload: allow_guest_download,
              allowSingleDownload: allow_guest_single_download,
              downloadMessageProps: {
                ...downloadMessageProps,
                handleOnDownloadMessage,
                ...eventData.download_prompt,
                branding: eventData.branding,
              },
              branding: eventData?.branding,
              allowPeopleTab,
              peopleTabProps,
            }}
          />
        </>
      ) : (
        <>
          <Error>
            <div className="text-warning">
              <button
                className="btn btn-warning mt-2"
                onClick={() => {
                  const { origin, pathname, search } = window.location;
                  const searchParam = new URLSearchParams(search);
                  const key =
                    searchParam.get("pass_key") || searchParam.get("share_key");

                  // remove cache from localstorage
                  localStorage.removeItem(`event-requestId:${eventId}`);
                  const newSearchParam = new URLSearchParams();
                  newSearchParam.append("share_key", key);

                  window.location.replace(
                    `${origin}${pathname}?${newSearchParam.toString()}`
                  );
                }}
              >
                {" "}
                Please Try Again
              </button>
            </div>
          </Error>
        </>
      )}
    </>
  );
};

export default CollectionGallery;

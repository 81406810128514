const getFrameData = (eventId, requestId) => {
  // if (eventId === "28277") {
  //   return {
  //     vertical: {
  //       frame_url:
  //         "https://storage.fotoowl.ai/user/11YmqkFahBVCaVTamOMcaEQSY4k1/frames/vertical/041fd6b9-03d3-4b6a-8f2e-e9a6ce9c5147",
  //       border: [1120, 740, 778, 550], //top-right-bototm-left
  //       frame_size: [6000, 4000], //height, width
  //     },
  //     horizontal: {
  //       frame_url:
  //         "https://storage.fotoowl.ai/user/11YmqkFahBVCaVTamOMcaEQSY4k1/frames/horizontal/041fd6b9-03d3-4b6a-8f2e-e9a6ce9c5147",
  //       // border: [215, 147, 148, 215], //top-right-bototm-left
  //       border: [1200, 760, 470, 1150],
  //       frame_size: [4000, 6000], //height, width
  //     },
  //   };
  // }

  if (eventId === "28277") {
    return {
      vertical: {
        frame_url:
          "https://storage.fotoowl.ai/user/11YmqkFahBVCaVTamOMcaEQSY4k1/frames/vertical/041fd6b9-03d3-4b6a-8f2e-e9a6ce9c5147",
        border: [1120, 740, 778, 550], //top-right-bototm-left
        frame_size: [6000, 4000], //height, width
      },
      horizontal: {
        frame_url:
          "https://storage.fotoowl.ai/user/11YmqkFahBVCaVTamOMcaEQSY4k1/frames/horizontal/041fd6b9-03d3-4b6a-8f2e-e9a6ce9c5147",
        // border: [215, 147, 148, 215], //top-right-bototm-left
        border: [1200, 760, 470, 1150],
        frame_size: [4000, 6000], //height, width
      },
    };
  }

  if (eventId === "28277") {
    return {
      vertical: {
        frame_url:
          "https://storage.fotoowl.ai/user/11YmqkFahBVCaVTamOMcaEQSY4k1/frames/vertical/041fd6b9-03d3-4b6a-8f2e-e9a6ce9c5147",
        border: [1120, 740, 778, 550], //top-right-bototm-left
        frame_size: [6000, 4000], //height, width
      },
      horizontal: {
        frame_url:
          "https://storage.fotoowl.ai/user/11YmqkFahBVCaVTamOMcaEQSY4k1/frames/horizontal/041fd6b9-03d3-4b6a-8f2e-e9a6ce9c5147",
        // border: [215, 147, 148, 215], //top-right-bototm-left
        border: [1200, 760, 470, 1150],
        frame_size: [4000, 6000], //height, width
      },
    };
  }

  if (eventId === "16991" || eventId === "17021") {
    return {
      vertical: {
        frame_url:
          "https://storage.fotoowl.ai/user/KXcp7kk7U1d6ADbMToLhqFnXtph2/frames/vertical/700a8225-fee5-4652-a497-eeacec833200",
        border: [0, 0, 0, 0], //top-right-bototm-left
        frame_size: [870, 600],
      },
      horizontal: {
        frame_url:
          "https://storage.fotoowl.ai/user/KXcp7kk7U1d6ADbMToLhqFnXtph2/frames/horizontal/700a8225-fee5-4652-a497-eeacec833200",
        border: [0, 0, 0, 0], //top-right-bototm-left
        frame_size: [600, 870],
      },
    };
  }
  if (eventId === "7517") {
    return {
      vertical: {
        frame_url:
          "https://storage.fotoowl.ai/user/xwqFlkSD3MUAmC9IUYbHinlVqqU2/frames/vertical/87387cda-5108-433f-9f00-801b10017cdf",
        border: [0, 367, 0, 0], //top-right-bototm-left
        frame_size: [1620, 1447],
      },
      horizontal: {
        frame_url:
          "https://storage.fotoowl.ai/user/xwqFlkSD3MUAmC9IUYbHinlVqqU2/frames/horizontal/87387cda-5108-433f-9f00-801b10017cdf",
        border: [0, 244, 0, 0], //top-right-bototm-left
        frame_size: [1080, 1864],
      },
    };
  }

  if (eventId === "210") {
    return {
      vertical: {
        frame_url: "https://assets.fotoowl.ai/frames/bengaluru-smashers-2.png",
        border: [60, 60, 211, 60], //top-right-bototm-left
        frame_size: [1181, 1181],
      },
      horizontal: {
        frame_url: "https://assets.fotoowl.ai/frames/bengaluru-smashers-2.png",
        border: [60, 60, 211, 60], //top-right-bototm-left
        frame_size: [1181, 1181],
      },
    };
  } else if (eventId === "193") {
    const horizontal = [
      "https://dev-storage.fotoowl.ai/user/XbxRrntnBHSn8e5GPj0i8H5HkTv2/frames/horizontal/b77a69a5-b699-47e1-ac46-bb2d604637bd",
      "https://dev-storage.fotoowl.ai/user/XbxRrntnBHSn8e5GPj0i8H5HkTv2/frames/horizontal/1e98adbe-6173-496f-9ece-a5cf49194fd5",
      "https://dev-storage.fotoowl.ai/user/XbxRrntnBHSn8e5GPj0i8H5HkTv2/frames/horizontal/e355d30c-5726-44e3-86cc-d42405c648c9",
      "https://dev-storage.fotoowl.ai/user/XbxRrntnBHSn8e5GPj0i8H5HkTv2/frames/horizontal/4d27feb2-dc29-4bcf-ab5b-cebd076329d7",
    ];
    const vertical = [
      "https://dev-storage.fotoowl.ai/user/XbxRrntnBHSn8e5GPj0i8H5HkTv2/frames/vertical/b77a69a5-b699-47e1-ac46-bb2d604637bd",
      "https://dev-storage.fotoowl.ai/user/XbxRrntnBHSn8e5GPj0i8H5HkTv2/frames/vertical/1e98adbe-6173-496f-9ece-a5cf49194fd5",
      "https://dev-storage.fotoowl.ai/user/XbxRrntnBHSn8e5GPj0i8H5HkTv2/frames/vertical/e355d30c-5726-44e3-86cc-d42405c648c9",
      "https://dev-storage.fotoowl.ai/user/XbxRrntnBHSn8e5GPj0i8H5HkTv2/frames/vertical/4d27feb2-dc29-4bcf-ab5b-cebd076329d7",
    ];
    if (requestId) {
      const idx = requestId % 4;
      return {
        vertical: {
          frame_url: vertical[idx],
          border: [154, 88, 680, 88], //top-right-bototm-left
          frame_size: [1920, 1080], //height, width
        },
        horizontal: {
          frame_url: horizontal[idx],
          border: [15, 15, 251, 15], //top-right-bototm-left
          frame_size: [1181, 1181],
        },
      };
    }
  }
};

export default getFrameData;

// https://dev-frame.fotoowl.ai/?
// image=https%3A%2F%2Fstorage.fotoowl.ai%2Fevents%2F28206%2F7xaroBXiDETMXmHr3J3i3XU8mU72%2Fraw%2F_ABH1498.jpg%3Flast%3D1710410636&
// frame_img=https%3A%2F%2Fstorage.fotoowl.ai%2Fuser%2F11YmqkFahBVCaVTamOMcaEQSY4k1%2Fframes%2Fhorizontal%2Fa16da6a1-20a5-4593-967f-f09d8277c6f2&frame_size=1365%2C2048&
// height=1080&
// width=1620.3956043956043&
// border=215%2C0%2C215%2C0&
// image_type=horizontal

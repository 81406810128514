import { ACTIVITY } from "../enums";

export const socialMediaData = [
  {
    name: "LinkedIn Page",
    apiName: "linkedin",
    icon: "https://img.icons8.com/color/48/linkedin.png",
    host: "https://www.linkedin.com/company/",
    iconWhite: "https://img.icons8.com/ios/50/FFFFFF/linkedin.png",
    iconBlack: "https://img.icons8.com/ios/50/1A1A1A/linkedin.png",
    iconWhiteFill: "https://img.icons8.com/ios-glyphs/60/FFFFFF/linkedin.png",
    iconBlackFill: "https://img.icons8.com/ios-glyphs/60/1A1A1A/linkedin.png",
    activity: ACTIVITY.LINKEDIN_VISIT,
    text: "Follow",
  },
  {
    name: "Facebook",
    apiName: "fb_link",
    icon: "https://img.icons8.com/color/48/null/facebook-new.png",
    iconWhite: "https://img.icons8.com/ios/50/FFFFFF/facebook-new.png",
    iconBlack: "https://img.icons8.com/ios/50/1A1A1A/facebook-new.png",
    iconWhiteFill:
      "https://img.icons8.com/ios-glyphs/60/FFFFFF/facebook-new.png",
    iconBlackFill:
      "https://img.icons8.com/ios-glyphs/60/1A1A1A/facebook-new.png",
    host: "https://www.facebook.com/",
    activity: ACTIVITY.FB_VISIT,
    text: "Follow",
  },
  {
    name: "Instagram Handle",
    apiName: "insta_link",
    icon: "https://img.icons8.com/color/48/null/instagram-new--v1.png",
    iconWhite: "https://img.icons8.com/ios/50/FFFFFF/instagram-new--v1.png",
    iconBlack: "https://img.icons8.com/ios/50/1A1A1A/instagram-new--v1.png",
    iconWhiteFill:
      "https://img.icons8.com/ios-glyphs/60/FFFFFF/instagram-new.png",
    iconBlackFill:
      "https://img.icons8.com/ios-glyphs/60/1A1A1A/instagram-new.png",
    host: "https://instagram.com/",
    activity: ACTIVITY.INSTA_VISIT,
    text: "Follow",
  },

  {
    name: "Youtube Channel Name ",
    apiName: "youtube_link",
    icon: "https://img.icons8.com/color/48/youtube-play.png",
    iconWhite: "https://img.icons8.com/ios/50/FFFFFF/youtube-play--v1.png",
    iconBlack: "https://img.icons8.com/ios/50/1A1A1A/youtube-play--v1.png",
    iconWhiteFill:
      "https://img.icons8.com/ios-glyphs/60/FFFFFF/youtube-play.png",
    iconBlackFill:
      "https://img.icons8.com/ios-glyphs/60/1A1A1A/youtube-play.png",
    host: "https://www.youtube.com/@",
    activity: ACTIVITY.YOUTUBE_VISIT,
    text: "Subscribe",
  },

  {
    name: "WhatsApp Number",
    apiName: "whatsapp",
    icon: "https://img.icons8.com/color/48/whatsapp.png",
    iconWhite:
      "https://img.icons8.com/fluency-systems-regular/48/FFFFFF/whatsapp--v1.png",
    iconBlack:
      "https://img.icons8.com/fluency-systems-regular/48/1A1A1A/whatsapp--v1.png",
    iconWhiteFill: "https://img.icons8.com/ios-glyphs/60/FFFFFF/whatsapp.png",
    iconBlackFill: "https://img.icons8.com/ios-glyphs/60/1A1A1A/whatsapp.png",
    host: "https://wa.me/",
    activity: ACTIVITY.WA_VISIT,
    text: "Chat",
  },

  {
    name: "Twitter Handle ",
    apiName: "twitter",
    icon: "https://img.icons8.com/ios-filled/100/twitterx--v1.png",
    iconWhite: "https://img.icons8.com/ios/50/FFFFFF/twitterx--v2.png",
    iconBlack: "https://img.icons8.com/ios/50/1A1A1A/twitterx--v2.png",
    iconWhiteFill:
      "https://img.icons8.com/ios-filled/100/FFFFFF/twitterx--v2.png",
    iconBlackFill:
      "https://img.icons8.com/ios-filled/100/1A1A1A/twitterx--v2.png",
    host: "https://x.com/",
    activity: ACTIVITY.X_VISIT,
    text: "Follow",
  },

  {
    name: "Snapchat ID ",
    apiName: "snapchat",
    icon: "https://img.icons8.com/fluency/48/snapchat.png",
    iconWhite: "https://img.icons8.com/ios/50/FFFFFF/snapchat--v1.png",
    iconBlack: "https://img.icons8.com/ios/50/1A1A1A/snapchat--v1.png",
    iconWhiteFill:
      "https://img.icons8.com/ios-filled/100/FFFFFF/snapchat--v1.png",
    iconBlackFill:
      "https://img.icons8.com/ios-filled/100/1A1A1A/snapchat--v1.png",
    host: "https://www.snapchat.com/add/",
    activity: ACTIVITY.SNAP_VISIT,
    text: "Add",
  },
  {
    name: "TikTok",
    apiName: "tiktok",
    icon: "https://img.icons8.com/ios-filled/100/tiktok--v1.png",
    iconWhite: "https://img.icons8.com/ios/50/FFFFFF/tiktok--v1.png",
    iconBlack: "https://img.icons8.com/ios/50/1A1A1A/tiktok--v1.png",
    iconWhiteFill: "https://img.icons8.com/ios-glyphs/60/FFFFFF/tiktok.png",
    iconBlackFill: "https://img.icons8.com/ios-glyphs/60/1A1A1A/tiktok.png",
    host: "https://vm.tiktok.com/",
    activity: ACTIVITY.TIKTOK_VISIT,
    text: "Follow",
  },
  {
    name: "Webiste",
    apiName: "website",
    icon: "https://img.icons8.com/fluency/48/internet.png",
    iconWhite: "https://img.icons8.com/ios/50/FFFFFF/globe--v1.png",
    iconBlack: "https://img.icons8.com/ios/50/1A1A1A/globe--v1.png",
    iconWhiteFill: "https://img.icons8.com/ios-filled/100/FFFFFF/globe--v1.png",
    iconBlackFill: "https://img.icons8.com/ios-filled/100/1A1A1A/globe--v1.png",
    host: "https://",
    activity: ACTIVITY.WEBSITE_VISIT,
    text: "Visit",
  },
];

export const makeHyperLinks = (brandingData) => {
  socialMediaData.forEach((sm) => {
    const userInput = brandingData[sm.apiName];
    if (userInput && !userInput.includes("http")) {
      brandingData[sm.apiName] = sm.host + userInput;
    }
  });
};

export const makeHyperLink = (link, host) => {
  var newLink = link;
  if (link && !link.includes("http")) {
    newLink = host + link;
  }
  return newLink;
};

export const handleOpenLink = (username, host, activity, handleActivity) => {
  const href = makeHyperLink(username, host);
  handleActivity(activity);
  window.open(href, "_blank");
};

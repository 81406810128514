import { useState } from "react";
import { Col, Modal } from "reactstrap";

const Youtube = ({ videoId, alt, height, width, icon, autoplay }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOnClick = () => {
    setOpenModal(true);
  };
  // const [isOpenModal, setIsOpenModal] =

  if (autoplay) {
    return (
      <iframe
        //   type="video/mp4"
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
        alt={alt}
        allow="fullscreen;autoplay"
      />
    );
  } else {
    return (
      <>
        <Modal
          centered
          size="lg"
          isOpen={openModal}
          toggle={() => setOpenModal(!openModal)}
        >
          <iframe
            //   type="video/mp4"
            width="100%"
            height="480"
            src={`https://www.youtube.com/embed/${videoId}`}
            alt={alt}
            allow="fullscreen;"
          />
        </Modal>
        <div className="position-relative youtube">
          <img
            className="img-fluid thumbnail"
            onClick={handleOnClick}
            src={`http://img.youtube.com/vi/${videoId}/0.jpg`}
            height={height}
            width={width}
          />
          <div
            className="position-absolute top-50 start-50 translate-middle"
            onClick={handleOnClick}
          >
            {icon === "art" ? (
              <img
                src="https://img.icons8.com/doodle/48/null/youtube-play--v2.png"
                alt="play"
                style={{ cursor: "pointer" }}
                className={"play-icon"}
              />
            ) : (
              <img
                src="https://img.icons8.com/color/48/null/youtube-play.png"
                alt="play"
                style={{ cursor: "pointer" }}
                className={"play-icon"}
              />
            )}
          </div>
        </div>
      </>
    );
  }
};
Youtube.defaultProps = { icon: "art" };
export default Youtube;

import {
  enableProdMode,
  setBackend,
  ready,
  loadGraphModel,
  loadLayersModel,
  tidy,
  image,
  div,
  expandDims,
  browser,
} from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl";
// import modelJson from "assets/model/anti-spoofing.json";

const modelOptions = {
  modelPath: "https://assets.fotoowl.ai/assets/models/anti-spoofing2.json",
  outputTensors: ["activation_4"],
};

class AntiSpoofModel {
  constructor(onLoad) {
    this.modelLoaded = false;
    const loadModel = async () => {
      await enableProdMode();
      //   await setBackend("tensorflow");
      await ready();

      // load model
      this.model = await loadGraphModel(modelOptions.modelPath);
      this.inputSize = Object.values(
        this.model.modelSignature["inputs"]
      )[0].tensorShape.dim[2].size;
      this.modelLoaded = true;
      onLoad(this);
    };
    loadModel();
    // init tensorflow
  }

  async getTensorFromDataUrl(dataUrl) {
    var img = new Image();
    img.src = dataUrl;
    return new Promise((resolve, reject) => {
      img.onload = () => {
        var output = browser.fromPixels(img);
        resolve(output);
      };
    });
  }
  async getTensorFromElement(element) {
    var output = browser.fromPixels(element);
    return output;
  }

  async loadImage(buffer, inputSize) {
    const obj = tidy(() => {
      const resize = image.resizeBilinear(buffer, [128, 128]); // model input resolution is 128x128
      const normalize = div(resize, 255); // normalize input to range 0..1
      const expand = expandDims(normalize, 0);
      const tensor = expand;
      const img = {
        tensor,
        inputShape: [buffer.shape[1], buffer.shape[0]],
        outputShape: tensor.shape,
        size: buffer.size,
      };
      return img;
    });

    return obj;
  }

  base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
  async isSpoofCanvas(canvas) {
    if (this.modelLoaded) {
      const tensor = await this.getTensorFromElement(canvas);
      const img = await this.loadImage(tensor, this.inputSize);
      // run actual prediction
      const res = this.model.execute(img.tensor, modelOptions.outputTensors);
      const real = await res.data();
      console.log("is Real", real[0]);
    }
  }
  async isSpoof(dataUrl) {
    if (this.modelLoaded) {
      const tensor = await this.getTensorFromDataUrl(dataUrl);
      const img = await this.loadImage(tensor, this.inputSize);

      // run actual prediction
      const res = this.model.execute(img.tensor, modelOptions.outputTensors);
      const real = await res.data();
      console.log("is Real", real[0]);
    }
  }
}

export default AntiSpoofModel;

import "assets/scss/custom/gallery/cover_themes/marathon.scss";
import "assets/scss/custom/gallery/cover_themes/common.scss";
import { BrandLogo, ContactNav } from "../Branding/BrandLogo";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import loader from "assets/images/icons/loader.svg";

const MarathonTheme = ({
  eventDetails,
  handleOnViewGallery,
  brandingStyle,
  SocialMediaNav,
  buttonTitle,
  coverImgRef,
  coverTextRef,
  isImgLoading,
  onLoadCoverImg,
  coverImageVersion,
  isMobile,
  uploadButtonTitle,
  isGuestUploadAllowed,
  handleOpenGuestUpload,
}) => {
  const { branding, cover_image_info, name, date } = eventDetails;
  const { logoSize, showLogo, showSocial, showEventName } = brandingStyle.cover;
  const coverImg = cover_image_info.path_dict.high_url;
  const { mobileCover, laptopCover, cover } = coverImageVersion;
  // const coverSrc = useMemo(
  //   () => (isMobile ? mobileCover : laptopCover),
  //   [coverImageVersion, isMobile]
  // );
  const coverSrc = cover;

  const Branding = useCallback(
    ({ className }) => (
      <div className={classNames("branding", className)}>
        {showLogo && (
          <>
            <BrandLogo {...branding} className={classNames(logoSize.value)} />
            <ContactNav brandingData={branding} />
          </>
        )}
        {showSocial && (
          <SocialMediaNav
            brandingData={branding}
            iconSize={"15"}
            className={""}
          />
        )}
      </div>
    ),
    [brandingStyle, branding]
  );
  return (
    <div className="gallery-cover cover-theme-marathon">
      {/* <Branding className={"d-flex d-md-none"} /> */}
      <div className="cover-img-container">
        {isImgLoading && (
          <img
            className="position-absolute top-50 start-50 translate-middle"
            src={loader}
          />
        )}
        <img
          ref={coverImgRef}
          className="cover-img easeload"
          src={coverSrc}
          onLoad={() => {
            onLoadCoverImg();
          }}
        />
        <div className="cover-img-overlay"></div>
      </div>
      <div className="cover-text" ref={coverTextRef}>
        {/* <Branding className={"d-none d-md-block"} /> */}
        <div className="event-details">
          {showEventName && (
            <>
              <time className="event-date">{date}</time>
              <div className="event-name"> {name}</div>
            </>
          )}
        </div>

        <Branding className={"d-flex"} />
        {isGuestUploadAllowed && (
          <div className="view-gallery-btn" onClick={handleOpenGuestUpload}>
            {uploadButtonTitle}
          </div>
        )}
      </div>
    </div>
  );
};
export default MarathonTheme;

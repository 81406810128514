import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationGr from "./locales/gr/translation.json";
import translationSP from "./locales/sp/translation.json";
import translationENG from "./locales/eng/translation.json";
import translationFr from "./locales/fr/translation.json";
import translationTr from "./locales/tr/translation.json";
import translationMr from "./locales/mr/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationHN from "./locales/hn/translation.json";
import translationKN from "./locales/kn/translation.json";
import translationZH from "./locales/zh/translation.json";
import translationAR from "./locales/ar/translation.json";

// the translations
const resources = {
  de: {
    translation: translationGr,
  },
  es: {
    translation: translationSP,
  },
  en: {
    translation: translationENG,
  },
  fr: {
    translation: translationFr,
  },
  tr: {
    translation: translationTr,
  },
  mr: {
    translation: translationMr,
  },
  hn: {
    translation: translationHN,
  },
  kn: {
    translation: translationKN,
  },
  ru: {
    translation: translationRU,
  },
  zh: {
    translation: translationZH,
  },
  ar: {
    translation: translationAR,
  },
};

export const supportedLanguages = [
  {
    name: "Arabic",
    code: "ar",
  },
  {
    name: "English",
    code: "en",
  },
  {
    name: "French",
    code: "fr",
    emoji: "🇫🇷",
  },
  {
    name: "German",
    code: "de",
    emoji: "🇩🇪",
  },
  {
    name: "Hindi",
    code: "hn",
  },
  {
    name: "Kannada",
    code: "kn",
  },
  { name: "Mandarin", code: "zh" },
  {
    name: "Marathi",
    code: "mr",
  },
  {
    name: "Russian",
    code: "ru",
    emoji: "🇷🇺",
  },
  {
    name: "Spanish",
    code: "es",
  },
  {
    name: "Turkish",
    code: "tr",
  },
];

const languages = supportedLanguages.map((item) => item.code);
const language = navigator.language;
// const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en");
}

i18n
  // .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-
  .init({
    detection: { order: ["navigator"] },
    resources,
    // lng: "en",
    // lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: "en", // use en if detected lng is not available
    caches: [],
    keySeparator: false, // we do not use keys in form messages.welcome
    whitelist: languages,
    // debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

console.log(language);

export const handleChangeLanguage = (language) => {
  if (language === "ar") {
    document.documentElement.dir = "rtl";
  } else {
    document.documentElement.dir = "ltr";
  }
  i18n.changeLanguage(language);
};

handleChangeLanguage(language);

export default i18n;

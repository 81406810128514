import "assets/scss/custom/gallery/cover_themes/vintage.scss";
import "assets/scss/custom/gallery/cover_themes/common.scss";
import { BrandLogo, ContactNav } from "../Branding/BrandLogo";
import classNames from "classnames";
import loader from "assets/images/icons/loader.svg";
import { useMemo } from "react";

const VintageTheme = ({
  eventDetails,
  handleOnViewGallery,
  brandingStyle,
  buttonTitle,
  coverImgRef,
  coverTextRef,
  isImgLoading,
  onLoadCoverImg,
  coverImageVersion,
  isMobile,
  SocialMediaNav,
  uploadButtonTitle,
  isGuestUploadAllowed,
  handleOpenGuestUpload,
}) => {
  const { branding, cover_image_info, name, date } = eventDetails;
  const { logoSize, showLogo, showSocial, showEventName } = brandingStyle.cover;
  const { mobileCover, laptopCover, cover, fourByThree, sixteenbySeven } =
    coverImageVersion;
  const coverSrc = useMemo(
    () => (isMobile ? fourByThree : sixteenbySeven),
    [coverImageVersion, isMobile]
  );
  const Branding = ({ className }) => (
    <div className={classNames("branding", className)}>
      {showLogo && (
        <>
          <BrandLogo {...branding} className={classNames(logoSize.value)} />
          <ContactNav brandingData={branding} />
        </>
      )}
      {showSocial && (
        <SocialMediaNav
          brandingData={branding}
          iconSize={"15"}
          className={""}
        />
      )}
    </div>
  );
  return (
    <div className="gallery-cover cover-theme-vintage">
      <Branding className={"d-flex d-md-none"} />
      <div className="cover-img-container">
        {isImgLoading && (
          <img
            className="position-absolute top-50 start-50 translate-middle"
            src={loader}
          />
        )}
        <img
          ref={coverImgRef}
          className="cover-img easeload"
          src={coverSrc}
          onLoad={() => {
            onLoadCoverImg();
          }}
        />
        <div className="cover-img-overlay"></div>
      </div>
      <div className="cover-text" ref={coverTextRef}>
        <Branding className={"d-none d-md-block"} />
        <div className="event-details">
          {showEventName && (
            <>
              <time className="event-date">{date}</time>
              <div className="event-name"> {name}</div>
            </>
          )}
        </div>
        <div className="view-gallery-btn" onClick={handleOnViewGallery}>
          {buttonTitle}
        </div>
        {isGuestUploadAllowed && (
          <div className="view-gallery-btn" onClick={handleOpenGuestUpload}>
            {uploadButtonTitle}
          </div>
        )}
      </div>
    </div>
  );
};
export default VintageTheme;

export const ORDER_BY = { CLICK_TIME: 0, ID: 1 };
export const COLLECTION_TYPE = { GENERAL: 4, PEOPLE: 5, HIGHLIGHT: 7, USER: 3 };

// for public gallery
export const EVENT_ORDER_BY = {
  EVENT_NAME_ASC: 0,
  EVENT_NAME_DESC: 1,
  EVENT_CREATION_TIME_ASC: 2,
  EVENT_CREATION_TIME_DESC: 3,
  EVENT_TIME_ASC: 4,
  EVENT_TIME_DESC: 5,
};

import DownloadPromptBasic from "./Styles/DownloadPromptBasic";
import HashTagBig from "./Styles/HashTagBig";
import { useState } from "react";

const TRACKS = {
  0: {
    Component: DownloadPromptBasic,
    props: {},
  },
  1: {
    Component: HashTagBig,
    props: {},
  },
};

const triggerStates = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  10: 0,
  11: 0,
  12: 0,
};

const triggerRegistry = {};

const getTriggerCategory = (initialTrigger) => {
  if (initialTrigger >= 9 && initialTrigger <= 12) {
    return "download";
  } else if (initialTrigger > 0 && initialTrigger <= 3) {
    return "gallery_visit";
  }
};

const trackRegistry = [];
const useTracks = () => {
  // const [trackRegistry, setTrackRegistry] = useState([]);
  const [reRender, setRerender] = useState(1);
  const [isTrackRegistered, setIsTrackRegistered] = useState(false);
  const registerTrigger = (tracks) => {
    const newTracks = [];
    tracks?.forEach((item) => {
      const { type: trackId, trigger_type: triggerId, data } = item;
      // triggerRegistry[triggerRegistry] = trackId;
      const triggerCategory = getTriggerCategory(triggerId);
      const Component = TRACKS[trackId].Component;
      let props = {};
      props.handleOnCloseDownloadMessage = () => handleOnCloseTrack(triggerId);
      const newTrack = {
        triggerId,
        trackId,
        isOpen: false,
        data,
        props,
        Component,
        triggerCategory,
      };
      newTracks.push(newTrack);
    });

    // setTrackRegistry([...trackRegistry, ...newTracks]);
    trackRegistry.push(...newTracks);
  };

  const handleOnCloseTrack = (triggerId) => {
    // setTrackRegistry((trackRegistry) => {
    //   const d = trackRegistry.filter((x) => x.triggerId === triggerId);
    //   if (d.length > 0) {
    //     d[0].isOpen = false;
    //   }
    //   return [...trackRegistry];
    // });

    const d = trackRegistry.filter((x) => x.triggerId === triggerId);
    if (d.length > 0) {
      d[0].isOpen = false;
    }
    setRerender((p) => p + 1);
  };
  const handleOnOpenTrack = (targetTrack, props) => {
    // setTrackRegistry((trackRegistry) => {
    //   targetTrack.isOpen = true;
    //   targetTrack.props = { ...targetTrack.props, ...props };
    //   return [...trackRegistry];
    // });
    targetTrack.isOpen = true;
    targetTrack.props = { ...targetTrack.props, ...props };
    setRerender((p) => p + 1);
  };

  const handleTrigger = async (triggerCategory, props) => {
    let state;
    const targetTrack = trackRegistry.filter(
      (x) => x.triggerCategory === triggerCategory
    );
    if (targetTrack.length > 0) {
      switch (targetTrack[0].triggerId) {
        case 0:
          state = triggerStates[0] + 1;
          if (state === 1) {
            handleOnOpenTrack(targetTrack[0], props);
            //   return triggerRegistry[triggerId];
          }
        case 1:
          state = triggerStates[1] + 1;
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(handleOnOpenTrack(targetTrack[0], props));
            }, 30000);
          });
        case 2:
          state = triggerStates[2] + 1;
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(handleOnOpenTrack(targetTrack[0], props));
            }, 60000);
          });
        case 10:
          triggerStates[10] += 1;
          state = triggerStates[10];
          handleOnOpenTrack(targetTrack[0], props);
          break;
        case 11:
          triggerStates[11] += 1;
          state = triggerStates[11];
          if (state >= 2) {
            handleOnOpenTrack(targetTrack[0], props);
            triggerStates[11] = 0;
          }

          break;
        case 12:
          triggerStates[12] += 1;
          state = triggerStates[12];
          if (state >= 5) {
            handleOnOpenTrack(targetTrack[0], props);
            triggerStates[11] = 0;
          }

          break;
        default:
          return null;
      }
    }
  };

  return {
    isTrackRegistered,
    setIsTrackRegistered,
    trackRegistry,
    registerTrigger,
    handleOnOpenTrack,
    handleOnCloseTrack,
    handleTrigger,
  };
};

export default useTracks;
export { getTriggerCategory };

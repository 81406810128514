import "assets/scss/custom/gallery/cover_themes/ceremony.scss";
import "assets/scss/custom/gallery/cover_themes/common.scss";
import { BrandLogo, ContactNav } from "../Branding/BrandLogo";
import classNames from "classnames";
import loader from "assets/images/icons/loader.svg";
import { useMemo } from "react";
import { FotoowlTop } from "../FotoOwl";

const CeremonyTheme = ({
  eventDetails,
  handleOnViewGallery,
  brandingStyle,
  buttonTitle,
  coverImgRef,
  coverTextRef,
  isImgLoading,
  onLoadCoverImg,
  coverImageVersion,
  isMobile,
  SocialMediaNav,
  isFotoOwlBranding,
  uploadButtonTitle,
  isGuestUploadAllowed,
  handleOpenGuestUpload,
}) => {
  const { branding, cover_image_info, name, date } = eventDetails;
  const { logoSize, showLogo, showSocial, showEventName } = brandingStyle.cover;
  const { mobileCover, laptopCover, cover } = coverImageVersion;
  const coverSrc = useMemo(
    () => (isMobile ? mobileCover : laptopCover),
    [coverImageVersion, isMobile]
  );
  return (
    <div className="gallery-cover cover-theme-ceremony">
      <div className="cover-img-container">
        {isImgLoading && (
          <img
            className="position-absolute top-50 start-50 translate-middle"
            src={loader}
          />
        )}
        <img
          ref={coverImgRef}
          className="cover-img easeload"
          src={coverSrc}
          onLoad={() => {
            onLoadCoverImg();
          }}
        />
        <div className="cover-img-overlay"></div>
      </div>
      <div className="cover-text easeload" ref={coverTextRef}>
        <div className="branding">
          {showLogo && (
            <div>
              <BrandLogo {...branding} className={classNames(logoSize.value)} />
            </div>
          )}
        </div>
        <div className="event-details">
          {showEventName && (
            <div className="flex-grow-1">
              <div className="event-name"> {name}</div>
              <time className="event-date">{date}</time>
            </div>
          )}
          <div className="view-gallery-btn" onClick={handleOnViewGallery}>
            {" "}
            {buttonTitle}
          </div>
          {isGuestUploadAllowed && (
            <div className="view-gallery-btn" onClick={handleOpenGuestUpload}>
              {uploadButtonTitle}
            </div>
          )}
          {showSocial && (
            <SocialMediaNav
              brandingData={branding}
              iconSize={"15"}
              className={""}
            />
          )}
          {showLogo && <ContactNav brandingData={branding} />}
        </div>
      </div>
    </div>
  );
};
export default CeremonyTheme;

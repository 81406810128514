import { GALLERY_ACCESS_LEVEL } from "./enums";
import { useEffect } from "react";

const useRequestRedirect = ({
  request_id,
  request_key,
  isPortfolio,
  eventId,
  galleryAccessControl,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    let storedRequestId = localStorage.getItem(`event-requestId:${eventId}`);
    if (
      Number(eventId) !== 27191 &&
      storedRequestId &&
      !request_id &&
      !isPortfolio &&
      galleryAccessControl.pinAccessLevel === GALLERY_ACCESS_LEVEL.FACE_ONLY
    ) {
      const { request_id, request_key } = JSON.parse(storedRequestId);
      if (request_id && request_key) {
        const { origin, pathname } = window.location;
        // history.location;
        queryParams.append("request_id", request_id);
        queryParams.append("request_key", request_key);
        // history.push({ search: queryParams.toString() });
        const urlNew = new URL(
          `${origin}${pathname}?${queryParams.toString()}`
        );
        window.location.replace(urlNew);
      }
    }
    if (request_id) {
      localStorage.setItem(
        `event-requestId:${eventId}`,
        JSON.stringify({ request_id, request_key })
      );
    }
  }, [request_id, galleryAccessControl]);
};

export { useRequestRedirect };

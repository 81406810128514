const GalleryExpired = () => {
  return (
    <div
      style={{ zIndex: 5000 }}
      className="text-center fs-5 position-fixed top-0 start-0 w-100 w-md-50 "
    >
      {/* <i className="mdi mdi-alert me-2" /> Gallery is Expired Please contact
      Admin */}
      <div className="alert alert-danger fade show">
        <i className="mdi mdi-alert me-2" /> Gallery is Expired, please contact
        the admin
      </div>
    </div>
  );
};

export { GalleryExpired };

import logo from "assets/images/logo_full.png";

const Fotoowl = () => {
  return (
    <div className="powered-by">
      <div>POWERED BY</div>
      <a href="https://fotoowl.ai" target={"_blank"}>
        <img src={logo} alt="fotoowl" />
      </a>
    </div>
  );
};

export const FotoowlTop = () => {
  return (
    <div className="fotoowl-top">
      <a href="https://fotoowl.ai" target={"_blank"}>
        <img src={logo} alt="fotoowl" />
      </a>
    </div>
  );
};

export default Fotoowl;

import React, { useCallback, useContext } from "react";
import { ACTIVITY } from "gallery_helpers/enums";
import classNames from "classnames";
import { socialMediaData } from "gallery_helpers/utils";
import GalleryContext from "store/galleryContext";
import { getCloudflareUrl } from "gallery_helpers/cloudflare_image";

const ShareOnSocial = ({ className, iconSize, photo, eventData }) => {
  const linkedIn = socialMediaData.find((sm) => sm.apiName === "linkedin");
  const instagram = socialMediaData.find((sm) => sm.apiName === "insta_link");
  const facebook = socialMediaData.find((sm) => sm.apiName === "fb_link");
  const { handleActivity } = useContext(GalleryContext);

  const getImageData = useCallback(() => {
    if (eventData && photo) {
      const { img_url, originalHeight, originalWidth } = photo;
      const { id: event_id, branding, frame } = eventData;
      let imageUrl;
      if (frame) {
        imageUrl = getCloudflareUrl({
          img_url,
          originalHeight,
          originalWidth,
          branding,
          frame,
        });
      } else {
        imageUrl = photo.path_dict.high_url;
      }
      return { imageUrl, imageId: photo.id };
    } else {
      return { imageUrl: null, imageId: null };
    }
  }, [photo]);

  const { imageUrl, imageId } = getImageData();
  if (!imageUrl) {
    return <></>;
  } else {
    return (
      <div className={classNames("social-nav-container", className)}>
        {/* <div>
          <i className="me-1 mdi mdi-share-variant align-middle" />
          Follow/Share
        </div> */}

        {/* {brandingData.fb_link && (
          <a
            style={{ color: color }}
            href={add_https(brandingData.fb_link)}
            target="_blank"
            role="button"
            onClick={() => handlActivity(ACTIVITY.FB_VISIT)}
          >
            <div
              className="icon fb"
              style={iconSize && { height: iconSize, width: iconSize }}
            />
          </a>
        )} */}

        <button
          style={{ borderColor: "#e1dada" }}
          className="btn btn-outline-light justify-content-center d-flex gap-2 py-1 px-2 align-items-center flex-grow-1"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleActivity(ACTIVITY.SHARE_LINKEDIN, imageId);
            window.open(
              // `http://www.linkedin.com/sharing/share-offsite/?url=${rawImageUrl}`,
              `http://www.linkedin.com/shareArticle?mini=true&url=${imageUrl}`,
              "_blank"
            );
          }}
          type="button"
        >
          <img src={linkedIn.icon} height={iconSize} />
          <small>POST</small>

          {/* </a> */}
        </button>
        <div className="" data-href={imageUrl} data-layout="" data-size="">
          <button
            style={{ borderColor: "#e1dada" }}
            className="btn btn-outline-light justify-content-center d-flex gap-2 py-1 px-4 align-items-center flex-grow-1"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleActivity(ACTIVITY.SHARE_FB, imageId);
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${imageUrl}&amp;src=sdkpreparse`
              );
            }}
            type="button"
          >
            {/* <a
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?u=${rawImageUrl}&amp;src=sdkpreparse`}
          > */}
            <img src={facebook.icon} height={iconSize} /> <small>SHARE</small>
            {/* </a> */}
          </button>
        </div>
        <button
          style={{ borderColor: "#e1dada" }}
          className="btn btn-outline-light justify-content-center d-flex gap-2 py-1 px-2 align-items-center flex-grow-1"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleActivity(ACTIVITY.SHARE_INSTA, imageId);
            window.open("https://instagram.com", "_blank");
          }}
          type="button"
        >
          <img src={instagram.icon} height={iconSize} />
          <small>POST</small>

          {/* </a> */}
        </button>

        {/* {brandingData.youtube_link && (
          <a
            style={{ color: color }}
            href={add_https(brandingData.youtube_link)}
            target="_blank"
            role="button"
            onClick={() => handlActivity(ACTIVITY.YOUTUBE_VISIT)}
          >
            <div
              className="icon youtube"
              style={iconSize && { height: iconSize, width: iconSize }}
            />
          </a>
        )} */}

        {/* <button
        style={{ borderColor: "#e1dada" }}
        className="btn btn-outline-light justify-content-center d-flex gap-2 py-1 px-2 align-items-center flex-grow-1"
        onClick={() => {
          if (isMobile) {
            const a = document.createElement("a");
            a.setAttribute(
              "href",
              `whatsapp://send?text=Check Out Beautiful Gallery: ${window.location.href}`
            );
            a.setAttribute("target", "_blank");
            a.style.visibility = "hidden";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            window.open(
              `https://wa.me/?text=Check Out Beautiful Gallery: ${window.location.href}`,
              "_blank"
            );
          }
        }}
      >
        <div
          className="icon whatsapp"
          style={iconSize && { height: iconSize, width: iconSize }}
        />
        <small>S H A R E</small>
      </button> */}
      </div>
    );
  }
};

export default ShareOnSocial;

// import * as cam from "@mediapipe/camera_utils";
// import MyWebcam from "./Webcam";
import { useRef, useEffect, useState, useMemo } from "react";
// import * as tfFaceDetection from "@tensorflow-models/face-detection";
import { Camera } from "react-camera-pro";
import face_scan from "assets/images/icons/face_scan.gif";
import classNames from "classnames";
import captureSelfieAnimation from "assets/images/animations/capture-selfie.json";
import captureSelfieDoneAnimation from "assets/images/animations/capture-selfie-done.json";
import loader from "assets/images/icons/loader.svg";
import Loader from "components/Loader";
import scanRight from "assets/images/animations/scan-right.json";
import AntiSpoofModel from "./isSpoof";
import CheckLiveness from "./isLive";
import useLiveliness from "./isLive";
import { getEventCustomisations } from "components/Events/Client/Forms/customisation";

let width = window.innerWidth - 50;
let height = window.innerHeight;

const getAnimation = (
  startCapture,
  rightVerified,
  leftVerified,
  liveVerified,
  faceCentered
) => {
  return (
    <>
      <lottie-player
        src={JSON.stringify(captureSelfieDoneAnimation)}
        background="transparent"
        speed="1"
        style={{ zIndex: 300, marginTop: "4px" }}
        // style={{ width: "auto", height: "305px" }}
        class={classNames(
          "animation position-absolute top-50 start-50 translate-middle h-100 w-100",
          {
            "d-none": !liveVerified,
          }
        )}
        // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
        loop
        autoplay
      ></lottie-player>

      <lottie-player
        src={JSON.stringify(captureSelfieAnimation)}
        background="transparent"
        speed="1"
        style={{ zIndex: 300, marginTop: "4px" }}
        initialSegment={[80, 90]}
        // style={{ width: "auto", height: "305px" }}
        class={classNames(
          "animation position-absolute top-50 start-50 translate-middle h-100 w-100",
          {
            "d-none": liveVerified,
          }
        )}
        // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
        loop
        autoplay
      ></lottie-player>
      <lottie-player
        src={JSON.stringify(scanRight)}
        background="transparent"
        speed="1"
        style={{ zIndex: 300, width: "auto", height: "405px" }}
        class={classNames("animation position-absolute h-100 w-100", {
          "d-none":
            !startCapture || !faceCentered || rightVerified || liveVerified,
        })}
        // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
        loop
        autoplay
      ></lottie-player>
      <lottie-player
        src={JSON.stringify(scanRight)}
        background="transparent"
        speed="1"
        style={{
          zIndex: 300,
          width: "auto",
          height: "405px",
          transform: "scaleX(-1)",
        }}
        class={classNames("animation position-absolute h-100 w-100", {
          "d-none":
            !startCapture ||
            !faceCentered ||
            !rightVerified ||
            leftVerified ||
            liveVerified,
        })}
        // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
        loop
        autoplay
      ></lottie-player>
    </>
  );
};

export default function LiveSelfie({ onDone, onClose, showCameraHelpVideo }) {
  if (width > 1000) {
    width = 640;
  }
  const camera = useRef(null);
  const checkHeadRotation = true;
  const checkSpoof = false;

  const {
    isFDReady,
    faceCentered,
    capture,
    header,
    leftVerified,
    rightVerified,
    liveliness,
    frame,
    cameraReady,
    handleOnClose,
    handleOnCaptureFrame,
    handleNumberOfCameras,
  } = useLiveliness({
    camera,
    checkSpoof,
    checkHeadRotation,
  });

  useEffect(() => {
    return () => handleOnClose();
  }, []);

  const stateRef = useRef();
  stateRef.current = capture;

  const handleOnCapture = () => {
    handleOnCaptureFrame();
  };
  const handleOnCloseCamera = () => {
    handleOnClose();
    onClose();
  };

  const handleOnDone = () => {
    onDone(true, [frame]);
  };

  const SVGIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" height={"100%"} width={"100%"}>
      <defs>
        <mask id="image-mask">
          <rect width="100%" height="100%" fill="#fff" />

          {/* <ellipse
            style={{ transform: "translate(50%,50%)" }}
            cx="0"
            cy="0"
            rx="115"
            ry="130"
          /> */}
          <rect
            width="64%"
            height="90%"
            style={{
              transform: "translate(0%, 10px)",
            }}
            x="18%"
            y="0"
            rx="120"
            ry="120"
          ></rect>
        </mask>
      </defs>
      <rect
        className="webcam-mask"
        x="0"
        y="0"
        width="100%"
        height="100%"
        mask="url(#image-mask)"
        fillOpacity="1"
        // fill="#ffff"
      />
    </svg>
  );
  // console.log("face detection ready", isFDReady);

  return (
    <div className="d-flex flex-column">
      <div className="p-3 d-flex justify-content-center flex-column align-items-center position-relative">
        <h4>{header.header}</h4>
        {header.body}

        <div
          className="position-absolute end-0 top-0 p-3 fs-2"
          onClick={onClose}
        >
          <i className="mdi mdi-close" style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className="position-relative" style={{ height: "300px" }}>
        <div className="overlay h-100">{SVGIcon}</div>

        {isFDReady && cameraReady ? (
          getAnimation(
            capture,

            rightVerified,
            leftVerified,
            liveliness,
            faceCentered
          )
        ) : (
          <div
            style={{ zIndex: 300, backgroundColor: "0e0e0e33" }}
            className="animation position-absolute top-50 start-50 translate-middle h-100 w-100"
          >
            <Loader className="h-100 align-items-center" />
          </div>
        )}

        <div>
          {liveliness && frame ? (
            <img className="captured-frame" src={frame} />
          ) : (
            <Camera
              ref={camera}
              aspectRatio={4 / 3}
              facingMode={"user"}
              numberOfCamerasCallback={handleNumberOfCameras}
              errorMessages={{
                noCameraAccessible: (
                  <div className="camera-access-error">
                    <div className="">
                      <img
                        width="48"
                        height="48"
                        src="https://img.icons8.com/fluency/48/general-warning-sign.png"
                        alt="general-warning-sign"
                      />

                      <div>Allow Camera Permissions and Reload the page</div>
                    </div>
                    {showCameraHelpVideo && (
                      <button className="btn btn-gallery-primary mt-3 py-1">
                        <a
                          href="https://youtube.com/shorts/4wv4YGPsxSM?feature=share"
                          target="_blank"
                        >
                          Steps to allow camera permission{" "}
                          <i className="mdi mdi-" />
                        </a>
                      </button>
                    )}
                  </div>
                ),

                permissionDenied:
                  "Permission denied. Please refresh and give camera permission.",
                switchCamera:
                  "It is not possible to switch camera to different one because there is only one video device accessible.",
                canvas: "Canvas is not supported.",
              }}
            />
          )}
        </div>
      </div>

      <div className="d-flex gap-2 mt-4 px-2 me-2 pt-3 justify-content-center">
        {liveliness ? (
          <>
            <div className="d-flex gap-4 px-4 justify-content-between">
              <>
                <div onClick={handleOnCloseCamera}>
                  <span className="fs-5 px-3 p-2 badge bg-secondary rounded-pill ">
                    <i className="mdi mdi-camera-retake  me-2 "></i>
                    Close
                  </span>
                </div>
                <div onClick={handleOnDone}>
                  <span className="fs-5 px-3 p-2 badge rounded-pill bg-success">
                    <i className="mdi mdi-check-circle  me-2 "></i>
                    Done
                  </span>
                </div>
              </>
            </div>
          </>
        ) : (
          <button
            disabled={capture || !isFDReady || !faceCentered}
            className="btn btn-gallery-primary"
            onClick={handleOnCapture}
          >
            <i className="mdi mdi-camera  me-2"></i>
            {!faceCentered ? "Keep Face Centered to Capture" : "Capture"}
          </button>
        )}
      </div>
    </div>
  );
}

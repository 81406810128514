import { useEffect, useState } from "react";

const useSelection = ({ imageData, totalImages }) => {
  ///////////////////////////////////////////////////// selection////////////////////////////////////////////////////
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagePageMapping, setSelectedImagePageMapping] = useState({});
  const [isAllImagesSelected, setIsAllImagesSelected] = useState(false);

  useEffect(() => {
    if (totalImages === selectedImages.length) {
      setIsAllImagesSelected(true);
    } else {
      setIsAllImagesSelected(false);
    }
  }, [totalImages, selectedImages]);

  const addImageToPage = (page) => {
    if (selectedImagePageMapping[page]) {
      selectedImagePageMapping[page] += 1;
    } else {
      selectedImagePageMapping[page] = 1;
    }
    setSelectedImagePageMapping({ ...selectedImagePageMapping });
  };
  const removeImageFromPage = (page) => {
    selectedImagePageMapping[page] -= 1;
    setSelectedImagePageMapping({ ...selectedImagePageMapping });
  };

  const clearImageSelection = () => {
    setSelectedImages([]);
    Object.entries(selectedImagePageMapping).map(
      ([key]) => (selectedImagePageMapping[key] = 0)
    );
    setSelectedImagePageMapping({ ...selectedImagePageMapping });
  };
  const [shiftSelectionStart, setShiftSelectionStart] = useState(false);
  const handleOnImageSelection = (event, photo, page) => {
    event.stopPropagation();
    event.preventDefault();

    const idx = selectedImages.indexOf(photo.id);
    //remove from selection
    if (idx > -1) {
      // only splice array when item is found
      selectedImages.splice(idx, 1); // 2nd parameter means remove one item only
      // delete selectedPhoto[photo.id]
      setSelectedImages([...selectedImages]);
      removeImageFromPage(page);
    } else {
      setSelectedImages((p) => [...p, photo.id]);
      addImageToPage(page);
      // setSchedulePhoto((p)=>({...p, [photo.id]: photo}))
    }

    if (event.shiftKey && !shiftSelectionStart) {
      if (selectedImages.length !== 0) {
        setShiftSelectionStart(selectedImages[selectedImages.length - 1]);
      } else {
        setShiftSelectionStart(photo.id);
      }
    } else if (!event.shiftKey) {
      setShiftSelectionStart();
    }
    if (
      event.shiftKey &&
      selectedImages.length > 0 &&
      selectedImages[selectedImages.length - 1] !== photo.id
    ) {
      const flatImageIds = imageData
        .map((pageData) => pageData.data.image_list.map((image) => image.id))
        .flat();
      const s = flatImageIds.indexOf(selectedImages[selectedImages.length - 1]);
      const e = flatImageIds.indexOf(photo.id);
      const prevEnd = flatImageIds.indexOf(
        selectedImages[selectedImages.length - 1]
      );

      var shiftSelection = flatImageIds.slice(s, e + 1);

      if (prevEnd > e) {
        //redo shift part
        const prevS = selectedImages.indexOf(shiftSelectionStart);
        const prevE = selectedImages.indexOf(
          selectedImages[selectedImages.length - 1]
        );

        const newS = flatImageIds.indexOf(shiftSelectionStart);
        shiftSelection = flatImageIds.slice(newS, e + 1);
        selectedImages.splice(prevS, prevE + 1);
        setSelectedImages([...new Set([...selectedImages, ...shiftSelection])]);
      } else {
        setSelectedImages((p) => [...new Set([...p, ...shiftSelection])]);
        // console.log(shiftSelection);
      }
    }
  };

  const [cmdPressed, setCmdPressed] = useState(false);
  const keyboardToggleAllImageSelection = (e) => {
    if (e.key === "a" && (e.ctrlKey || cmdPressed)) {
      // console.log("ctrl+a");
      toggleAllImageSelection();
      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === "Meta") {
      setCmdPressed(true);
    } else {
      setCmdPressed(false);
    }
  };

  const toggleAllImageSelection = () => {
    if (selectedImages.length === totalImages) {
      clearImageSelection();
    } else {
      setSelectedImages(
        imageData
          .map((pageData, page) =>
            pageData.data.image_list.map((image) => image.id)
          )
          .flat()
        // .concat(eventVideos[collection].videos.map((video) => video.id))
      );
    }
  };

  return {
    selectedImages,
    isAllImagesSelected,
    handleOnImageSelection,
    setSelectedImagePageMapping,
    selectedImagePageMapping,
    toggleAllImageSelection,
    clearImageSelection,
    keyboardToggleAllImageSelection,
  };
};

export default useSelection;

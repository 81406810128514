import Fotoowl from "components/Fotoowl";
import Loader from "components/Loader";
import { getOpenDownloadLinks } from "helpers/jiraya_helper";
import { GET_OPEN_DOWNLOAD_LINKS } from "helpers/jiraya_url";
import { formatBytes } from "helpers/utils";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Row } from "reactstrap";
import useSWR from "swr";


const DownloadLinks = ({}) => {
  const { search } = new URL(window.location);
  const searchParam = new URLSearchParams(search);
  const event_id = searchParam.get("event_id");
  const processId = searchParam.get("process_id");
  const request_id = searchParam.get("request_id");
  const key = searchParam.get("event_key");

  // state -1 idle, 0-started 1-completed 2 failed
  const { data, error } = useSWR(
    [GET_OPEN_DOWNLOAD_LINKS, event_id, processId],
    () =>
      getOpenDownloadLinks({
        params: {
          event_id,
          download_process_id: processId,
          key,
        },
      })
  );

  var header;
  var body;
  if (data) {
    if (data.data.status === 1) {
      header = (
        <div>
          Download Ready
          <i
            className="mdi mdi-progress-check fs-1 align-middle ms-2"
            style={{ color: "green" }}
          />
        </div>
      );

      body = (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <p>Click the below link to start Downloading</p>
          <p>We have also mailed you the link</p>

          <div className="d-flex flex-column gap-2">
            {Object.entries(data.data.info).map(([part, item]) => (
              <div key={key}>
                <a className="btn btn-primary py-0" href={item.link}>
                  Part {Number(part) + 1} - Download {formatBytes(item.size)}
                  <i className="mdi mdi-download-circle ms-2 fs-3 align-middle" />
                </a>
              </div>
            ))}
          </div>
          <small className="mt-2">
            The download link will expire in 10 days
          </small>
        </div>
      );
    } else {
      header = (
        <div>
          Download Link Expired
          <i
            className="mdi mdi-progress-check fs-1 align-middle ms-2"
            style={{ color: "green" }}
          />
        </div>
      );

      body = (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <p>Download links are expired! </p>
          <p>Restart the download process</p>

          <small className="mt-2">The download link has expired</small>
        </div>
      );
    }
  }
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      {data ? (
        <Row className="w-100 justify-content-center">
          <Card className="col-md-5 col-sm-11">
            <CardHeader className="d-flex justify-content-center">
              <h5>{header}</h5>
            </CardHeader>
            <CardBody>
              <div>{body}</div>
            </CardBody>
            <Fotoowl />
          </Card>
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DownloadLinks;

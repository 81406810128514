import DownloadJob from "components/Events/Forms/download_job";
import { FotoowlTop } from "components/Fotoowl";
import {
  getOpenDownloadJobStatus,
  startOpenDownloadJob,
  getOpenEvent,
} from "helpers/jiraya_helper";
import { GET_OPEN_DOWNLOAD_JOB_STATUS } from "helpers/jiraya_url";
import { GET_EVENT_OPEN } from "helpers/jiraya_url";
import useSWR from "swr";
import Fotoowl from "components/Fotoowl";

const DownloadPage = () => {
  const { search } = new URL(window.location);
  const searchParam = new URLSearchParams(search);
  const eventId = searchParam.get("event_id");
  const collectionId =
    searchParam.get("collection_id") !== "-1"
      ? searchParam.get("collection_id")
      : null;
  const requestId = searchParam.get("request_id");
  const key = searchParam.get("event_key");

  const { data: eventData, error } = useSWR(GET_EVENT_OPEN, () =>
    getOpenEvent({ params: { event_id: eventId } })
  );
  return (
    <div>
      {eventData && !eventData?.data?.no_foto_owl_branding && <FotoowlTop />}
      <DownloadJob
        startDownloadJob={startOpenDownloadJob}
        getDownloadJobStatus={getOpenDownloadJobStatus}
        downloadJobStatusUrl={GET_OPEN_DOWNLOAD_JOB_STATUS}
        isOpen={true}
        eventId={eventId}
        collectionId={collectionId}
        requestId={requestId}
        eventKey={key}
      />
      {eventData && !eventData?.data?.no_foto_owl_branding_level_2 && (
        <Fotoowl />
      )}
    </div>
  );
};

export default DownloadPage;

import "assets/scss/custom/gallery/cover_themes/no_cover.scss";
import "assets/scss/custom/gallery/cover_themes/common.scss";
import { BrandLogo, ContactNav } from "../Branding/BrandLogo";
import classNames from "classnames";

const FlatColorCover = ({ eventDetails, brandingStyle, SocialMediaNav }) => {
  const { branding, cover_image_info, name, date } = eventDetails;
  const { logoSize, showLogo, showSocial, showEventName } = brandingStyle.cover;
  return (
    <div className="gallery-cover cover-theme-flat-color-cover">
      <div className="cover-text">
        <div className="event-details">
          {showEventName && (
            <>
              <div className="event-name"> {name}</div>
              <time className="event-date">{date}</time>
            </>
          )}
        </div>
        <div className="branding">
          {showLogo && (
            <div>
              <BrandLogo {...branding} className={classNames(logoSize.value)} />
            </div>
          )}
          {showSocial && (
            <div className="d-flex flex-column gap-2">
              <SocialMediaNav
                brandingData={branding}
                iconSize={"15"}
                className={""}
              />
              <ContactNav brandingData={branding} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default FlatColorCover;

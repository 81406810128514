import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { SWRConfig } from "swr";
import i18n from "i18n";
import { I18nextProvider } from "react-i18next";

const app = (
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <SWRConfig
        value={{
          refreshInterval: 0,
          revalidateOnMount: true,
          revalidateOnFocus: false,
        }}
      >
        <App />
      </SWRConfig>
    </BrowserRouter>
  </I18nextProvider>
);

ReactDOM.render(app, document.getElementById("root"));

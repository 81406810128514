import { useRef, useEffect, useState, useMemo } from "react";
import { FaceDetection } from "@mediapipe/face_detection";
import AntiSpoofModel from "./isSpoof";

class CheckLiveness {
  constructor(handleOnVerify) {
    this.frontPose = false;
    this.leftPose = false;
    this.rightPose = false;
    this.handleOnVerify = handleOnVerify;
  }
  isFrontPose(right_eye, left_eye, nose, right_face, left_face) {
    // right-eye  left-eye nose should be insied right-face and left-face horizontally
    var xmin = right_face["x"];
    var xmax = left_face["x"];

    var checkLandmarks = [right_eye, left_eye, nose];
    var front = true;
    checkLandmarks.forEach((lm) => {
      if (xmin > lm["x"] || xmax < lm["x"]) {
        front = false;
      }
    });
    return front;
  }

  isLeftPose(right_eye, left_eye, nose, right_face, left_face) {
    // right-eye  less from right-face
    var xmin = right_eye["x"];

    var checkLandmarks = [right_face];
    var right = true;
    checkLandmarks.forEach((lm) => {
      // console.log("left verify, eye x:", xmin, "face x:", lm["x"]);
      if (xmin * 0.95 > lm["x"]) {
        right = false;
      }
    });
    // if (right) {
    //   console.log(checkLandmarks, xmin);
    // }
    return right;
  }

  isRightPose(right_eye, left_eye, nose, right_face, left_face) {
    // left-eye  max from left-face
    var xmax = left_eye["x"];

    var checkLandmarks = [left_face];
    var left = true;

    checkLandmarks.forEach((lm) => {
      // console.log("right verify, eye x:", xmax, "face x:", lm["x"]);
      if (xmax < lm["x"] * 0.95) {
        left = false;
      }
    });
    // if (left) {
    //   console.log(checkLandmarks, xmax);
    // }
    return left;
  }

  checkLiveness(landmarks) {
    var right_eye = landmarks[0];
    var left_eye = landmarks[1];
    var nose = landmarks[2];
    var mouth = landmarks[3];
    var right_face = landmarks[4];
    var left_face = landmarks[5];

    // validate each pose
    if (!this.frontPose) {
      this.frontPose = this.isFrontPose(
        right_eye,
        left_eye,
        nose,
        right_face,
        left_face
      );
    }
    if (!this.rightPose) {
      this.header = {
        header: (
          <div>
            Rotate Right
            <i className="mdi mdi-axis-z-rotate-clockwise ms-2" />
          </div>
        ),
        body: (
          <>
            <strong style={{ textAlign: "center" }}>
              Keep Face in the Center
            </strong>
            <div>and</div>
            <strong className="blink">Rotate Head Right</strong>
          </>
        ),
      };
      this.rightPose = this.isRightPose(
        right_eye,
        left_eye,
        nose,
        right_face,
        left_face
      );
      //   setRightVerified(rightPose);
    }

    if (this.rightPose && !this.leftPose) {
      this.header = {
        header: (
          <div>
            <i className="mdi mdi-axis-z-rotate-counterclockwise me-2" />
            Rotate Right
          </div>
        ),
        body: (
          <>
            <strong style={{ textAlign: "center" }}>
              Keep Face in the Center
            </strong>
            <div>and</div>
            <strong className="blink">Rotate Head Left</strong>
          </>
        ),
      };
      this.leftPose = this.isLeftPose(
        right_eye,
        left_eye,
        nose,
        right_face,
        left_face
      );
      //   setLeftVerifield(leftPose);
    }

    console.log(
      `Front Pose check: ${this.frontPose} | right Pose check: ${this.rightPose} | left Pose check: ${this.leftPose}`
    );
    // setResult(
    //   `Front Pose check: ${frontPose} | right Pose check: ${rightPose} | left Pose check: ${leftPose}`
    // );
    // setResult(`${JSON.stringify(right_eye)} : ${JSON.stringify(left_eye)}`);
    if (this.frontPose && this.leftPose && this.rightPose) {
      this.header = {
        header: (
          <div className="text-success">
            <i className="mdi mdi-check-decagram me-2 " />
            Selfie Verified
          </div>
        ),
        body: (
          <>
            <strong style={{ textAlign: "center" }}>Check</strong>
            <div>and</div>
            <strong>Click Done</strong>
          </>
        ),
      };
      this.handleOnVerify();
    }
    return {
      leftPose: this.leftPose,
      rightPose: this.rightPose,
      frontPose: this.frontPose,
      header: this.header,
    };
  }
}

const useLiveliness = ({ camera, checkSpoof, checkHeadRotation }) => {
  var frameCount = 0;
  const [capture, setCapture] = useState(false);
  const [liveliness, setLiveliness] = useState(false);
  const [frame, setFrame] = useState();
  const [result, setResult] = useState("");
  const [isFDReady, setIsFDReady] = useState(false);
  const [faceCentered, setFaceCentered] = useState(false);
  const [leftVerified, setLeftVerifield] = useState(false);
  const [rightVerified, setRightVerified] = useState(false);
  const [header, setHeader] = useState({
    header: (
      <div>
        <i className="mdi mdi-camera me-2" />
        Keep Face at Center
      </div>
    ),
    body: (
      <>
        <strong style={{ textAlign: "center" }} className="blink">
          <i className="mdi mdi-account-alert me-2" />
          Liveness Check Turned On
        </strong>
        <div>Keep face inside the lines</div>
        <strong>Press Capture</strong>
      </>
    ),
  });
  const startCaptureRef = useRef(false);
  const faceDetectionRef = useRef();
  const [verifyingIsSpoof, setVerifingIsSpoof] = useState(false);

  const handleOnVerify = () => {
    setRightVerified(true);
    setLeftVerifield(true);
    setLiveliness(true);
    setCapture(false);
    startCaptureRef.current = false;
  };

  const livelinessModel = useMemo(() => new CheckLiveness(handleOnVerify), []);

  useEffect(() => {
    if (!faceCentered) {
      setLeftVerifield(false);
      setRightVerified(false);
      //   rightPose = false;
      //   leftPose = false;
    }
  }, [faceCentered]);

  const [frames, setFrames] = useState([]);
  const handleOnCaptureFrame = () => {
    setCapture(true);
    startCaptureRef.current = true;
    // isCapture = true;
    const frame = camera.current.takePhoto();
    setFrame(frame);
    // when no head check, check spoof on capture
    if (checkSpoof && !checkHeadRotation) {
      setVerifingIsSpoof(true);
    }
    // setTimeout(() => handleOnStop(false), 20000);
  };

  const getCropCanvas = (sourceCanvas, left, top, width, height) => {
    let destCanvas = document.createElement("canvas");
    destCanvas.width = width;
    destCanvas.height = height;
    destCanvas.getContext("2d").drawImage(
      sourceCanvas,
      left,
      top,
      width,
      height, // source rect with content to crop
      0,
      0,
      width,
      height
    ); // newCanvas, same size as source rect
    return destCanvas;
  };

  useEffect(() => {
    const verify = async () => {
      if (verifyingIsSpoof) {
        faceDetectionRef.current.onResults((result) => {
          const bbox = result.detections[0].boundingBox;
          const imgCanvas = result.image;
          bbox.xCenter = bbox.xCenter * imgCanvas.width;
          bbox.yCenter = bbox.yCenter * imgCanvas.height;
          bbox.height = bbox.height * imgCanvas.height;
          bbox.width = bbox.width * imgCanvas.width;

          const left = bbox.xCenter - bbox.width / 2;
          const top = bbox.yCenter - bbox.height / 2;
          const croppedCanvas = getCropCanvas(
            imgCanvas,
            left,
            top,
            bbox.width,
            bbox.height
          );
          const d = croppedCanvas.toDataURL();
          //   console.log(d);
          new AntiSpoofModel((model) => {
            console.log(model);
            // model.isSpoof(frame);
            model.isSpoofCanvas(croppedCanvas);
            setRightVerified(false);
            setLeftVerifield(false);
            setLiveliness(true);
            setCapture(false);
            startCaptureRef.current = false;
          });
        });

        const image = new Image();
        image.src = frame;
        image.onload = () => {
          faceDetectionRef.current.send({ image: image });
        };
      }
    };
    verify();
  }, [verifyingIsSpoof]);

  const handleOnClose = () => {
    setRightVerified(false);
    setLeftVerifield(false);
    setLiveliness(false);
    setCapture(false);
    setFaceDetection();
    faceDetectionRef.current = null;
  };

  function onResults(result) {
    // console.log("/////////////////////results////////////////////////");
    var dets = result.detections;
    if (dets.length > 0 && dets[0]) {
      const xCenter = dets[0].boundingBox.xCenter;
      const yCenter = dets[0].boundingBox.yCenter;
      // console.log(xCenter, yCenter);

      if (xCenter > 0.45 && xCenter < 0.6 && yCenter > 0.5 && yCenter < 0.65) {
        // console.log("face aligned");
        setFaceCentered(true);

        if (startCaptureRef.current && checkHeadRotation) {
          const { frontPose, leftPose, rightPose, header } =
            livelinessModel.checkLiveness(dets[0].landmarks);
          setLeftVerifield(leftPose);
          setRightVerified(rightPose);
          setHeader(header);
        }
      } else if (!startCaptureRef.current) {
        setFaceCentered(false);
        console.log("no face");
      }

      // console.log(dets[0].boundingBox);
    } else if (!startCaptureRef.current) {
      setFaceCentered(false);
      console.log("no face");
    } else {
      console.log("no face but capture already started");
    }
  }

  const [faceDetection, setFaceDetection] = useState(null);
  useEffect(() => {
    const loadModal = async () => {
      const faceDetection = new FaceDetection({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@0.4/${file}`;
          // return `https://assets.fotoowl.ai/assets/models/${file}`;
        },
      });
      faceDetection.setOptions({
        selfieMode: true,
        model: "short",
        minDetectionConfidence: 0.5,
      });

      faceDetection.onResults((result) => onResults(result));
      await faceDetection.initialize();
      // send empty image to init
      var canvas = document.createElement("canvas");
      canvas.width = 128;
      canvas.height = 128;
      await faceDetection.send({ image: canvas });
      await faceDetection.send({ image: canvas });
      setIsFDReady(true);
      setFaceDetection(faceDetection);
      faceDetectionRef.current = faceDetection;
      console.log("done init fd");
    };
    loadModal();
    // return () => faceDetection.close();
  }, []);

  ///////////////////// start face detection
  //   const cameraReady = useRef();
  const [cameraReady, setCameraReady] = useState(false);
  const handleNumberOfCameras = (numCamera) => {};
  const frameInterval = useRef();
  useEffect(() => {
    var interval;
    if (isFDReady && camera.current) {
      const video = document.querySelector("#video");
      if (video.readyState === 4) {
        setCameraReady(true);
        interval = setInterval(() => onFrame(camera, video), 200);
        frameInterval.current = interval;
      } else {
        video.onloadeddata = () => {
          // cameraReady.current = true;
          setCameraReady(true);
          interval = setInterval(() => onFrame(camera, video), 200);
          frameInterval.current = interval;
        };
      }
    }
    if (verifyingIsSpoof && frameInterval.current) {
      clearInterval(frameInterval.current);
    }
    return () => clearInterval(interval);
  }, [isFDReady, verifyingIsSpoof]);

  async function onFrame(webcamref, video) {
    // if (capture && webcamref.current) {

    if (true) {
      //   frameCount += 1;
      //   if (frameCount === 1) {
      //     const photo = webcamref.current.takePhoto();
      //     setFrames(frames.push(photo));
      //   }
      await faceDetectionRef.current.send({ image: video });
      // const face = await tfFaceDetector.estimateFaces(
      //   webcamref.current.video
      // );
      // if (face.length > 0 && face[0]) checkLiveness(face[0].keypoints);

      // if (frameCount % 50 == 0) {
      //   await faceDetection.send({ image: video });
      //   // setFrames(frames.push(photo));
      // }
    }
  }

  const handleOnRetake = () => {
    setFrames([]);
    setLiveliness(false);
    setLeftVerifield(false);
    setRightVerified(false);
    setFaceCentered(false);
    setCapture(false);
    setFrame();
    setIsFDReady(false);
  };
  return {
    isFDReady,
    faceCentered,
    capture,
    handleOnClose,
    handleOnCaptureFrame,
    handleNumberOfCameras,
    header,
    leftVerified,
    rightVerified,
    liveliness,
    frame,
    cameraReady: cameraReady,
  };
};
export default useLiveliness;

import InquiryModal from "components/Modal/InquiryModal";
import { useContext, useState } from "react";
import { BrandLogo } from "./BrandLogo";
import { SocialNavColor } from "./SocialNav";
import GalleryContext from "store/galleryContext";
import { useTranslation } from "react-i18next";

const BrandFooter = ({
  eventId,
  name,
  img_url,
  contact_number,
  email_id,
  brandingStyle,
  ...social
}) => {
  const { t } = useTranslation();
  const [isOpen, setShowInquiryModal] = useState(false);
  const { showInquiry, showSocial, showLogo, showContact, showName, note } =
    brandingStyle.footer;

  return (
    <div className="brand-footer icon-color">
      <InquiryModal
        {...{
          isOpen,
          setShowInquiryModal,
          eventId,
          branding: { name, img_url, contact_number, email_id, ...social },
        }}
      />
      {name && (
        <>
          <div className="brand-subheading px-3">{note}</div>
          {showName && <div className="brand-name">{name}</div>}
          {showLogo && (
            <BrandLogo
              className={"brand-logo mt-2"}
              website={social.website}
              img_url={img_url}
            />
          )}

          {showInquiry && (
            <button
              className="btn btn-inquiry mt-2"
              onClick={() => setShowInquiryModal(true)}
            >
              <i className="mdi mdi-information-outline" /> {t("Inquiry")}
            </button>
          )}
          {showContact && (
            <div className="brand-contact">
              {contact_number && (
                <div>
                  {" "}
                  <i className="mdi mdi-phone me-2" /> {contact_number}
                </div>
              )}
              {email_id && (
                <div>
                  {" "}
                  <i className="mdi mdi-email me-2" /> {email_id}
                </div>
              )}
            </div>
          )}
          {showSocial && (
            <SocialNavColor
              className={"mt-2"}
              brandingData={{ ...social }}
              iconSize={25}
            />
          )}
        </>
      )}
    </div>
  );
};

export default BrandFooter;

import { useState, useEffect } from "react";

const getOrientation = () => window.screen.orientation.type;
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState();

  const updateOrientation = (event) => {
    const o = getOrientation();
    if (isMobile) {
      if (
        o === "landscape-primary" ||
        window.innerWidth > window.innerHeight ||
        o === "landscape-secondary"
      ) {
        setOrientation("mobile-landscape");
      } else {
        setOrientation();
      }
    } else {
      setOrientation();
    }
  };

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  return orientation;
};

export default useScreenOrientation;

import { useState, useCallback, useEffect } from "react";
import GalleryContent from "./gallery_content";
import LightBox, { useLightBox } from "./lightbox";
import { useHistory } from "react-router-dom";

const LikeGallery = ({
  eventId,
  collection,
  imageData,
  collectionData,
  eventKey,
  getImageParams,
  galleryParams,
  galleryHandlers,
  userData,
  checkImageLocked,
}) => {
  //////////////////////////////////////////// Photos ////////////////////////////////////////
  const {
    showLikeButton,
    liked,
    albumSelection,
    galleryProps,
    allow_guest_download,
    imageToolbarSubmitting,
  } = galleryParams;
  const { handleOnLike, handleOnImageDownload } = galleryHandlers;
  const { email, checkLogin } = userData;

  const totalImages = imageData ? imageData.data.length : 0;
  const history = useHistory();
  const page_size = 10;

  useEffect(() => {
    history.listen((location, action) => {
      console.log(action, location.pathname, location.hash);
      if (action === "POP") {
        handleSetIndex(-1);
        history.push(`${history.location.pathname}${history.location.search}`);
      }
    });
  }, []);

  const getFullCollection = useCallback(() => {
    const fullCollection = collectionData.find((x) => x.id === collection);
    return fullCollection;
  }, [collection]);

  const { index, handleSetIndex, photos } = useLightBox(
    imageData && [{ ...imageData, data: { image_list: imageData.data } }]
  );

  return (
    <>
      {imageData && (
        <GalleryContent
          images={imageData.data}
          eventId={eventId}
          page={0}
          {...galleryProps}
          onDownload={handleOnImageDownload}
          allowDownload={allow_guest_download}
          liked={liked}
          onLike={handleOnLike}
          // onComment={handleOnComment}
          onClick={(index) => {
            handleSetIndex(index);
          }}
          imageToolbarSubmitting={imageToolbarSubmitting}
          showLikeButton={showLikeButton}
          albumSelection={albumSelection}
          checkImageLocked={checkImageLocked}
        />
      )}
      <LightBox
        {...{
          eventId,
          eventKey,
          email,
          checkLogin,
          albumSelection,
          showLikeButton,
          index,
          handleSetIndex,
          photos,
          handleOnImageDownload,
          imageToolbarSubmitting,
          loadedImages: totalImages,
          liked,
          handleOnLike,
        }}
      />
    </>
  );
};

export default LikeGallery;

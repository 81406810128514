import { copyUrl } from "helpers/utils";
import { useContext, useEffect, useState } from "react";
import { Input, InputGroup } from "reactstrap";
import ShareOnSocial from "../Share";
import { ACTIVITY } from "helpers/enums";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";
import GalleryContext from "store/galleryContext";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";

const HashTagBig = ({
  handleOnCloseDownloadMessage,
  isOpenDownloadMessage,
  header,
  message,
  branding,
  className,
  photo,
  eventData,
}) => {
  const { handleActivity } = useContext(GalleryContext);
  useEffect(() => {
    if (isOpenDownloadMessage && message) {
      copyUrl("download-message");
    }
  }, [isOpenDownloadMessage && message]);

  const previewImg = photo?.thumbnail_url;
  const rawImg = photo?.path_dict?.raw_url;

  const { t } = useTranslation();
  if (isOpenDownloadMessage && (header || message)) {
    return (
      <div className={classNames("hashtrack download-basic card", className)}>
        <div className="container">
          <i
            className="mdi mdi-close position-absolute end-0 top-0 fs-4 p-1"
            onClick={() => {
              handleOnCloseDownloadMessage();
            }}
          />

          <div className="basic-text-container card-body">
            <h5 className="card-title">{header}</h5>
            <div className="card-text mt-3">
              {message && (
                <div className="d-flex flex-column">
                  <Input
                    type="textarea"
                    disabled={true}
                    id="download-message"
                    value={message}
                    className="mt-3"
                    style={{ height: "80px" }}
                  />
                  <CopyToClipboard
                    text={message}
                    onCopy={() => {
                      handleActivity(ACTIVITY.COPY_HASHTAG);
                    }}
                  >
                    <button className="btn btn-success waves-effect waves-light mt-4">
                      <i className="mdi mdi-content-copy" />{" "}
                      {t("Copy HashTags")}
                    </button>
                  </CopyToClipboard>
                </div>
              )}
            </div>
            <div className="icon-color mt-3">
              <ShareOnSocial
                brandingData={branding}
                className={"w-100 gap-3 justify-content-center mt-2"}
                iconSize={"23px"}
                photo={photo}
                eventData={eventData}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default HashTagBig;

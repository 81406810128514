import React from "react";

import { socialMediaData } from "../Events/Client/Branding/utils";

import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Fotoowl from "components/Fotoowl";

const PublicGalleryFooter = ({ branding }) => {
  return (
    <>
      <div>
        <Row className="p-3 gx-0">
          <Col md={12}>
            <div className="public-gallery-footer text-center d-flex justify-content-center justify-content-md-between  align-items-center">
              <div className="logo-img">
                {branding?.img_url ? (
                  <img
                    className="img-fluid logo"
                    src={branding?.img_url}
                    alt="brand_logo"
                  />
                ) : null}
              </div>
              <div className="text-center">
                <h2>{branding ? branding?.name : "Event Gallery"}</h2>
                <div className=" pe-md-2 pe-0 d-block d-md-none">
                  {branding?.[socialMediaData[8].apiName] ? (
                    <a
                      className="px-1"
                      href={branding?.website}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="nav-social-logo"
                        src={socialMediaData[8].iconWhiteFill}
                        alt="website-logo"
                      />
                    </a>
                  ) : null}
                  {branding?.[socialMediaData[2].apiName] ? (
                    <a
                      className="px-1"
                      href={branding?.insta_link}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="nav-social-logo"
                        src={socialMediaData[2].iconWhiteFill}
                        alt="insta-logo"
                      />
                    </a>
                  ) : null}
                  {branding?.[socialMediaData[1].apiName] ? (
                    <a
                      className="px-1"
                      href={branding?.fb_link}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="nav-social-logo"
                        src={socialMediaData[1].iconWhiteFill}
                        alt="fb-logo"
                      />
                    </a>
                  ) : null}
                  {branding?.[socialMediaData[4].apiName] ? (
                    <a
                      className="px-1"
                      href={branding?.whatsapp}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="nav-social-logo"
                        src={socialMediaData[4].iconWhiteFill}
                        alt="wahtsapp-logo"
                      />
                    </a>
                  ) : null}
                  {branding?.[socialMediaData[3].apiName] ? (
                    <a
                      className="px-1"
                      href={branding?.youtube_link}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="nav-social-logo"
                        src={socialMediaData[3].iconWhiteFill}
                        alt="youtube-logo"
                      />
                    </a>
                  ) : null}
                  {branding?.[socialMediaData[5].apiName] ? (
                    <a
                      className="px-1"
                      href={branding?.twitter}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="nav-social-logo"
                        src={socialMediaData[5].iconWhiteFill}
                        alt="twitter-logo"
                      />
                    </a>
                  ) : null}
                  {branding?.[socialMediaData[6].apiName] ? (
                    <a
                      className="px-1"
                      href={branding?.snapchat}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="nav-social-logo"
                        src={socialMediaData[6].iconWhiteFill}
                        alt="snapchat-logo"
                      />
                    </a>
                  ) : null}
                  {branding?.[socialMediaData[0].apiName] ? (
                    <a
                      className="px-1"
                      href={branding?.linkedin}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="nav-social-logo"
                        src={socialMediaData[0].iconWhiteFill}
                        alt="linkedin-logo"
                      />
                    </a>
                  ) : null}
                  {branding?.[socialMediaData[7].apiName] ? (
                    <a
                      className="px-1"
                      href={branding?.tiktok}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="nav-social-logo"
                        src={socialMediaData[7].iconWhiteFill}
                        alt="tiktok-logo"
                      />
                    </a>
                  ) : null}
                  <div></div>
                </div>

                {/* <div className="mt-4">
                  <Fotoowl />
                </div> */}
              </div>
              <button
                style={{ cursor: "pointer" }}
                className="btn btn-primary d-none d-md-block"
                onClick={() => {
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                }}
              >
                Top <i className="mdi mdi-arrow-up fs-5 fs-md-3"></i>
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PublicGalleryFooter;

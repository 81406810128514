const getCroppedImage = (coverPosition, image, isMobile) => {
  const { origin, pathname } = new URL(image);
  if (isMobile) {
    if (coverPosition.mobile) {
      const { height, width, x, y } = coverPosition.mobile.data;
      var newImageUrl = `${origin}/cdn-cgi/image/trim.height=${height},trim.width=${width},trim.left=${x},trim.top=${y}${pathname}`;
      return newImageUrl;
    } else {
      return image;
    }
  } else {
    if (coverPosition.laptop) {
      const { height, width, x, y } = coverPosition.laptop.data;
      var newImageUrl = `${origin}/cdn-cgi/image/trim.height=${height},trim.width=${width},trim.left=${x},trim.top=${y}${pathname}`;
      return newImageUrl;
    } else return image;
  }
};

const convertToAbsAndPositive = (cropData, imageData) => {
  const { height: imgHeight, width: imgWidth } = imageData;
  const { height, width, x, y } = cropData;

  return {
    height: Math.round((imgHeight * height) / 100),
    width: Math.round((imgWidth * width) / 100),
    x: Math.max(Math.round((imgWidth * x) / 100), 0),
    y: Math.max(Math.round((imgHeight * y) / 100), 0),
  };
};
const getCoverImages = (coverPosition, eventDetails) => {
  var mobileCover, laptopCover, cover, fourByThree, sixteenbySeven;

  const { cover_image_info } = eventDetails;
  {
    cover = cover_image_info.path_dict.high_url;
  }

  {
    const { cover_image_info_mobile } = eventDetails;
    const { origin, pathname } = new URL(
      cover_image_info_mobile.path_dict.img_url
    );
    const imageData = {
      height: cover_image_info_mobile.height,
      width: cover_image_info_mobile.width,
    };
    if (coverPosition?.mobile) {
      const { height, width, x, y } = convertToAbsAndPositive(
        coverPosition.mobile.data,
        imageData
      );
      mobileCover = `${origin}/cdn-cgi/image/trim.height=${height},trim.width=${width},trim.left=${x},trim.top=${y}${pathname}`;
    } else {
      mobileCover = cover_image_info_mobile.path_dict.high_url;
    }
  }

  {
    const { cover_image_info_laptop } = eventDetails;
    const { origin, pathname } = new URL(
      cover_image_info_laptop.path_dict.img_url
    );
    const imageData = {
      height: cover_image_info.height,
      width: cover_image_info.width,
    };
    if (coverPosition?.laptop) {
      const { height, width, x, y } = convertToAbsAndPositive(
        coverPosition.laptop.data,
        imageData
      );
      laptopCover = `${origin}/cdn-cgi/image/trim.height=${height},trim.width=${width},trim.left=${x},trim.top=${y}${pathname}`;
    } else {
      laptopCover = cover_image_info_laptop.path_dict.high_url;
    }
  }

  {
    const { cover_image_info_mobile } = eventDetails;
    const { origin, pathname } = new URL(
      cover_image_info_mobile.path_dict.img_url
    );
    const imageData = {
      height: cover_image_info_mobile.height,
      width: cover_image_info_mobile.width,
    };
    if (coverPosition?.mobile) {
      const { height, width, x, y } = convertToAbsAndPositive(
        coverPosition.mobile.data,
        imageData
      );

      const r = 4 / 3;
      let newWidth = width;
      let newX = x;
      if (height / width < r) {
        newWidth = height / r;
        newX = Math.max(x - (newWidth - width) / 2, 0);
      }
      fourByThree = `${origin}/cdn-cgi/image/trim.height=${height},trim.width=${newWidth},trim.left=${newX},trim.top=${y}${pathname}`;
    } else {
      fourByThree = cover_image_info_mobile.path_dict.high_url;
    }
  }

  {
    const { cover_image_info_laptop } = eventDetails;
    const { origin, pathname } = new URL(
      cover_image_info_laptop.path_dict.img_url
    );
    const imageData = {
      height: cover_image_info.height,
      width: cover_image_info.width,
    };
    if (coverPosition?.laptop) {
      const { height, width, x, y } = convertToAbsAndPositive(
        coverPosition.laptop.data,
        imageData
      );
      const r = 16 / 7;
      const newHeight = width / r;
      // const newY = y + (height - newHeight) / 2;
      sixteenbySeven = `${origin}/cdn-cgi/image/trim.height=${newHeight},trim.width=${width},trim.left=${x},trim.top=${y}${pathname}`;
    } else {
      sixteenbySeven = cover_image_info_laptop.path_dict.high_url;
    }
  }

  return { cover, mobileCover, laptopCover, fourByThree, sixteenbySeven };
};

export { getCroppedImage, getCoverImages };

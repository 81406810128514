import { Row } from "reactstrap";
import { Container } from "reactstrap";
import React, { useState, useEffect, useRef } from "react";
import Lightbox from "yet-another-react-lightbox";
import { GET_OPEN_IMAGE_COMMENTS } from "helpers/jiraya_url";
import { getCommentImageList } from "helpers/jiraya_helper";
import useSWR, { mutate } from "swr";
import { postOpenImageComment } from "helpers/jiraya_helper";
import { toast } from "react-toastify";
import loader from "assets/images/icons/loader.svg";
import no_comments from "assets/images/illustrations/no_comments.svg";
import { useTranslation } from "react-i18next";

export const CommentList = ({
  commentList,
  eventId,
  imageId,
  relodImages,
  pass_key,
  email_id,
  setScrollTo,
}) => {
  let showCommentList = commentList.slice(0, 1);
  const [showAll, setShowAll] = useState(false);
  const [commentText, setCommentText] = useState("");

  const onCLickShowAllComments = () => {
    setShowAll(!showAll);
  };

  showCommentList = showAll ? commentList : commentList.slice(0, 1);

  const postComment = () => {
    setCommentText("");
    postOpenImageComment({
      event_id: eventId,
      image_id: imageId,
      comment: commentText,
      key: pass_key,
      email_id: email_id,
    })
      .then((response) => {
        if (!response.ok) {
          toast("Failed to comment !! try after some time");
        } else {
          relodImages();
          setScrollTo(imageId);
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Failed to comment !! try after some time");
      });
  };

  const onInputCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      postComment();
    }
  };

  return (
    <div id={`comment-${imageId}`}>
      <div className="comment-list">
        {showCommentList.map((commentInfo) => (
          <div className="comment">
            <div className="profile-name avatar-sm comment-avatar">
              {commentInfo.email_id.slice(0, 2)}
            </div>
            <div className="comment-info-container">
              <div className="comment-top-info">
                <b>{commentInfo.email_id}</b>
                <div className="comment-time">{commentInfo.time}</div>
              </div>
              <div className="comment-text">{commentInfo.text}</div>
            </div>
          </div>
        ))}
        <div className="comment">
          <div className="comment-avatar"></div>
          {commentList.length <= 1 ? (
            <div></div>
          ) : (
            <div
              className="comment-show-all-less"
              onClick={onCLickShowAllComments}
            >
              {showAll
                ? "show less"
                : `show all ${commentList.length} comments`}
            </div>
          )}
        </div>
        <div className="add-comment">
          <div className="comment-avatar"></div>
          <input
            id={`comment-input-${imageId}`}
            className="add-comment-input"
            placeholder="Add a comment"
            value={commentText}
            onKeyDown={handleKeyDown}
            onChange={onInputCommentChange}
          />
          <button
            className="btn btn-primary"
            onClick={postComment}
            disabled={commentText.length == 0}
          >
            post
          </button>
        </div>
      </div>
    </div>
  );
};

const GaleryComment = ({
  eventId,
  pass_key,
  email_id,
  commentImgInfo,
  showRefresh,
  isAdmin,
}) => {
  const [openLightBox, setOpenLightBox] = React.useState(false);
  const [imageList, setImageList] = React.useState(null);
  const [scrollTo, setScrollTo] = React.useState(null);
  const [lightBoxSlides, setLightBoxSlides] = React.useState([]);
  const { data } = useSWR([GET_OPEN_IMAGE_COMMENTS, eventId], () =>
    getCommentImageList({
      params: { event_id: eventId, key: pass_key, page_size: 500 },
    })
  );
  const current_utc_min = Math.floor(Date.now() / (1000 * 60));
  const myRef = useRef(null);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const get_ago_time_str = (comment_time_min) => {
    let ago = current_utc_min - comment_time_min;
    if (ago == 0) return "now";
    if (ago < 60) return `${ago} min ago`;

    ago = Math.floor(ago / 60);
    if (ago < 24) return `${ago} hr ago`;

    ago = Math.floor(ago / 24);
    return `${ago} d ago`;
  };
  useEffect(() => {
    if (data) {
      const newImageList = [];
      data.data.forEach((element) => {
        const comments = [];
        element.comment_list.forEach((commentItem) => {
          comments.push({
            id: commentItem.id,
            text: commentItem.comment,
            email_id: commentItem.email_id ? commentItem.email_id : "anonymous",
            time: get_ago_time_str(commentItem.add_time_min),
          });
        });
        newImageList.push({
          image_url: element.path_dict.med_url,
          name: element.name,
          comments: comments,
          image_id: element.id,
        });
      });
      console.log(newImageList);
      if (commentImgInfo) {
        if (!isInList(commentImgInfo.image_id, newImageList)) {
          newImageList.unshift(commentImgInfo);
        }
        setScrollTo(commentImgInfo.image_id);
      }
      setImageList(newImageList);
    }
  }, [data]);

  const isInList = (imageId, imageList) => {
    let isPresent = false;
    imageList.forEach((imageInfo) => {
      if (imageInfo.image_id == imageId) {
        isPresent = true;
      }
    });
    return isPresent;
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (scrollTo) {
      var element = document.getElementById(`comment-${scrollTo}`);
      var inputElement = document.getElementById(`comment-input-${scrollTo}`);
      inputElement.focus();
      var headerOffset = windowSize.current[1] / 2;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  });

  const openImage = (event) => {
    setLightBoxSlides([{ src: event.target.src }]);
    setOpenLightBox(true);
  };

  const relodImages = () => {
    mutate([GET_OPEN_IMAGE_COMMENTS, eventId]);
  };

  return (
    <>
      {imageList == null ? (
        <div
          className="position-fixed d-flex justify-content-center align-items-center top-0 start-0"
          style={{
            height: "100vh",
            width: "100vw",
            zIndex: "2000",
            backgroundColor: "#0000009c",
          }}
        >
          <img src={loader} />
        </div>
      ) : imageList && imageList.length == 0 ? (
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5 col-11">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img className="px-5" src={no_comments} />
              <div className="helper-message text-center">
                <h4>{t("No Comments Yet")}</h4>
                {isAdmin && (
                  <small>
                    {t(
                      "Share gallery link with clients and they can comment on any image for some change"
                    )}
                  </small>
                )}
              </div>
              {isAdmin && (
                <>
                  <h4 className="mt-4">How it works?</h4>
                  <div className="text-left">
                    <ul>
                      <li>Create Event & Upload Photos</li>
                      <li>Publish Event</li>
                      <li>Share Photo Selection link with clients</li>
                      <li>
                        Ask clients to select the photos they like or comment on
                        image
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div>
                      Follow this video tutorial to <a href="#">learn more</a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          {showRefresh ? (
            <div className="gallery-nav-bar">
              <div className="d-flex flex-column gap-2 gallery-nav-bar-inner sticky-div pt-4">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1"></div>
                  <div style={{ borderRadius: "50%" }} className="ms-2">
                    <i className="fs-1 mdi mdi-refresh-circle  align-middle"></i>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <Container>
            {imageList.map((image) => (
              <Row id={image.image_id}>
                <div className="comment-gallery-row">
                  <img
                    className="comment-img"
                    src={image.image_url}
                    onClick={openImage}
                  ></img>
                  <div className="comment-img-name2">
                    <b>{image.name}</b>
                  </div>
                  <CommentList
                    commentList={image.comments}
                    eventId={eventId}
                    imageId={image.image_id}
                    relodImages={relodImages}
                    pass_key={pass_key}
                    email_id={email_id}
                    setScrollTo={setScrollTo}
                  />
                </div>
              </Row>
            ))}
          </Container>
          <Lightbox
            open={openLightBox}
            close={() => setOpenLightBox(false)}
            slides={lightBoxSlides}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        </>
      )}
    </>
  );
};

GaleryComment.defaultProps = {
  isAdmin: true,
};
export default GaleryComment;

import { Modal, ModalBody, Row, Col } from "reactstrap";
import logo from "assets/images/logo_full.png";
import downlaodLimitIllustration from "assets/images/illustrations/download-limit.png";
const FreePlanLimitModal = ({ isOpen, toggle, title }) => {
  return (
    <Modal
      className="free-plan-modal"
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="md"
    >
      <ModalBody>
        <div className="header">
          <div className="w-100 d-flex justify-content-between">
            <div>
              <div>
                <a href="https://fotoowl.ai" target={"_blank"}>
                  <img height={35} src={logo} alt="fotoowl" />
                </a>
              </div>

              <div
                style={{ letterSpacing: "3px" }}
                className="badge bg-secondary px-2 mb-2"
              >
                FREE PLAN
              </div>
            </div>
          </div>
        </div>
        <Row className="px-5">
          <img src={downlaodLimitIllustration} />
        </Row>
        <div className="mt-5 mb-3 d-flex flex-column align-items-center justify-content-center">
          <strong className="fs-5">⚠️ FREE Plan Limit Reached ⚠️</strong>
          <div>
            This Gallery is hosted on a FREE PLAN, please upgrade to continue
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FreePlanLimitModal;

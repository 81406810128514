const getTitleCustomisationData = (eventId) => {
  let title;
  let subtitle;
  switch (Number(eventId)) {
    case 44836:
      title =
        "Click a Selfie and Register yourself to receive the photos from the event with privacy and security.";
      subtitle = "";
      break;
    default:
      title = "Your beautiful photos await you";
      subtitle =
        "Click a Selfie and Register yourself to receive the photos from the event with privacy and security.";
  }
  return { title, subtitle };
};

export default getTitleCustomisationData;

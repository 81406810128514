// import Thumbnail from "components/Events/thumbnail";
import { randomIntFromInterval } from "helpers/utils";
import { pastelColors } from "helpers/utils";
import { useState } from "react";

export const Image = ({ containerProps, ...imageProps }) => {
  const [isImgLoading, setIsImgLoading] = useState(true);
  const color = pastelColors[randomIntFromInterval(0, pastelColors.length - 1)];
  const { className, style, ...otherContainerProps } = containerProps;
  return (
    <div
      {...otherContainerProps}
      style={style}
      className={`${className} position-relative`}
    >
      {isImgLoading && (
        <div
          className="placeholder-glow position-absolute top-0 start-0 w-100 h-100"
          style={{ zIndex: 100 }}
        >
          <div
            className="placeholder w-100 h-100"
            style={{ backgroundColor: color, opacity: 1 }}
          ></div>
        </div>
      )}
      <img
        {...imageProps}
        onLoad={() => {
          setIsImgLoading(false);
        }}
      />
    </div>
  );
};
export default function ImageStrip({
  photos,
  eventId,
  maxImages,
  height = 150,
  onClick,
  className,
}) {
  const gutter = 2;
  function scrollRight() {
    document.getElementById("container").scrollLeft += 100;
  }
  function scrollLeft() {
    document.getElementById("container").scrollLeft -= 100;
  }
  const style = {
    borderRadius: "5px",
    boxSizing: "border-box",
    display: "inline-block",
    margin: gutter,
    position: "relative",
  };

  var strip;
  if (photos.length > 0) {
    strip = photos.slice(0, maxImages).map((item, index) => {
      const width = item.width / (item.height / height);
      return (
        <Image
          className={className}
          key={index}
          style={{ borderRadius: "8px", cursor: "pointer" }}
          height={height}
          width={width}
          src={item.thumbnail_url}
          caption={item.caption}
          onClick={() => onClick(item.id)}
          containerProps={{
            style: { height: `${height}px`, width: `${width}px`, ...style },
          }}
        />
      );
    });
  } else {
    // const height = 400;
    console.log("placeholder only");
    const width = height * 2;
    strip = (
      <Image
        style={{ borderRadius: "8px" }}
        height={height}
        width={width}
        src={null}
        containerProps={{
          style: { height: `${height}px`, width: `${width}px`, ...style },
        }}
      />
    );
  }
  // strip.push(<div style={{height:"150px", width:"auto"}}>Load More</div>)
  return (
    <div>
      <div id="container" style={{}} className="image-strip position-relative">
        {/* <a
          className="image-strip-control-prev position-absolute"
          style={{ cursor: "pointer", zIndex: "100" }}
          onClick={scrollRight}
          href="#"
        >
          <span className="carousel-control-prev-icon"></span>
        </a> */}
        <div className="image-strip-content">{strip}</div>

        {/* <a
          className="image-strip-control-next position-absolute end-0"
          style={{ cursor: "pointer", zIndex: "100" }}
          onClick={scrollLeft}
          href="#"
        >
          <span className="carousel-control-next-icon"></span>
        </a> */}
      </div>
    </div>
  );
}

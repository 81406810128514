import { add_https } from "helpers/utils";
import { ACTIVITY } from "../enums";
import classNames from "classnames";
import { handleOpenLink, socialMediaData } from "./utils";

export const mainBranding = (options, branding, handleActivity, t) => {
  if (branding) {
    const mode = document.body.getAttribute("gallery-mode");
    let i = 0;
    socialMediaData.forEach((sm) => {
      const brand = branding[sm.apiName];
      if (brand && i < 3) {
        i += 1;
        options.push(
          <div
            onClick={() =>
              handleOpenLink(brand, sm.host, sm.activity, handleActivity)
            }
            className={classNames("gallery-light-box-menu-mobile-option")}
          >
            <div className="icon-container">
              <img
                src={mode === "dark" ? sm.iconWhiteFill : sm.iconBlackFill}
                height={20}
              />
            </div>

            <div className="d-xl-none">{t(sm.text)}</div>
          </div>
        );
      }
    });
  }
};

export const brandingOptions = (branding, handleActivity, t) => {
  const brandingOptionsList = [];
  if (branding) {
    const mode = document.body.getAttribute("gallery-mode");
    socialMediaData.forEach((sm) => {
      const brand = branding[sm.apiName];
      if (brand) {
        brandingOptionsList.push(
          <div
            onClick={() =>
              handleOpenLink(brand, sm.host, sm.activity, handleActivity)
            }
            className="branding-option-item"
          >
            <img src={sm.icon} height={22} className="me-2" />

            {t(sm.text)}
          </div>
        );
      }
    });
  }

  //   if (branding?.insta_link) {
  //     brandingOptionsList.push(
  //       <div
  //         id="call"
  //         onClick={() =>
  //           handleOpenLink(add_https(branding.insta_link), ACTIVITY.INSTA_VISIT)
  //         }
  //         className="branding-option-item"
  //       >
  //         <i className="mdi mdi-instagram" style={{ color: "#b90fa2" }} />
  //         Follow
  //       </div>
  //     );
  //   }
  //   if (branding?.youtube_link) {
  //     brandingOptionsList.push(
  //       <div
  //         id="call"
  //         onClick={() =>
  //           handleOpenLink(
  //             add_https(branding.youtube_link),
  //             ACTIVITY.YOUTUBE_VISIT
  //           )
  //         }
  //         className="branding-option-item"
  //       >
  //         <i className="mdi mdi-youtube" style={{ color: "red" }} />
  //         Subscribe
  //       </div>
  //     );
  //   }

  //   if (branding?.fb_link) {
  //     brandingOptionsList.push(
  //       <div
  //         id="call"
  //         onClick={() =>
  //           handleOpenLink(add_https(branding.fb_link), ACTIVITY.FB_VISIT)
  //         }
  //         className="branding-option-item"
  //       >
  //         <i className="mdi mdi-facebook" style={{ color: "#0676d4" }} />
  //         Follow
  //       </div>
  //     );
  //   }
  //   if (branding?.website) {
  //     brandingOptionsList.push(
  //       <div
  //         id="call"
  //         onClick={() =>
  //           handleOpenLink(add_https(branding.website), ACTIVITY.WEBSITE_VISIT)
  //         }
  //         className="branding-option-item"
  //       >
  //         <i className="mdi mdi-web" style={{ color: "#003869" }} />
  //         Visit
  //       </div>
  //     );
  //     if (branding?.contact_number) {
  //       brandingOptionsList.push(
  //         <div
  //           id="call"
  //           onClick={() => handleOpenLink(`tel:${branding.contact_number}`)}
  //           className="branding-option-item"
  //         >
  //           <i className="mdi mdi-cellphone" style={{ color: "#05a705" }} />
  //           Call
  //         </div>
  //       );
  //     }
  //     if (branding?.email_id) {
  //       brandingOptionsList.push(
  //         <div
  //           id="call"
  //           onClick={() => handleOpenLink(`mailto:${branding.email_id}`)}
  //           className="branding-option-item"
  //         >
  //           <i className="mdi mdi-mail" style={{ color: "#e6c800" }} />
  //           Mail
  //         </div>
  //       );
  //     }
  //   }
  return brandingOptionsList;
};

import loader from "assets/images/icons/loader.svg";
import classNames from "classnames";
const Loader = ({ className }) => {
  return (
    <div
      className={classNames(
        className,
        "d-flex flex-column align-items-center justify-content-center"
      )}
    >
      {/* <lottie-player
        src="https://assets7.lottiefiles.com/temp/lf20_EBA37z.json"
        background="transparent"
        speed="1"
        style={{ width: "auto", height: "100px" }}
        className="animation"
        rendererSettings={{ viewBoxSize: "700 0 2400 590" }}
        loop
        autoplay
      ></lottie-player> */}
      <img src={loader} alt={"loader"} />
      <div className="mt-4" style={{ color: "#b78925" }}>
        Loading...
      </div>
    </div>
  );
};

export default Loader;
